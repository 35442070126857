import React, { CSSProperties, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import ProfileModal from "./ProfileModal"; // Make sure this path is correct
import LoadingSpinner from "../LoadingSpinner";

const Profile: React.FC = () => {

  
  const providerDetails = useSelector(
    (state: RootState) => state.providerProfile.details
  );

  const loading = useSelector((state: RootState) => state.providerProfile.loading);


  const [password] = useState<string>("* ".repeat(6)); // Masked password

  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);


  if (loading) {
    return <LoadingSpinner/>; // Replace with a spinner or skeleton if desired
  }
  
  // If providerDetails is null (shouldn't happen with proper loading state, but for extra safety)
  if (!providerDetails) {
    return <div>No profile data available.</div>; // Optional: Handle cases where data might still be null
  }

  // Destructure the details you need from providerDetails
  const {
    profileImage: profilePicture,
    ownerName,
    businessName,
    emailAddress: email,
    phoneNumber,
  } = providerDetails;

 

  const handleInputClick = (fieldName: string) => {
    setSelectedField(fieldName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedField(null);
  };

 

  return (
  
    <div style={pageStyles.pageContainer}>
      <div style={pageStyles.modalContent}>
        <h2 style={pageStyles.modalTitle}>My Profile</h2>
        <div
          style={pageStyles.profilePictureContainer}
          onClick={() => handleInputClick("picture")}
        >
          <img
            src={profilePicture || "path/to/default/image.png"}
            alt="Profile"
            style={pageStyles.profileImage}
          />
          <div style={pageStyles.iconContainer}>
            <div style={pageStyles.smallIconCircle}>
              <FaPencilAlt size={20} color="white" />
            </div>
          </div>
        </div>

        <div style={pageStyles.formContainer}>
          <div style={pageStyles.formRow}>
            <div style={pageStyles.formField}>
              <label style={pageStyles.label}>Business Name</label>
              <div
                onClick={() => handleInputClick("Business Name")}
                style={pageStyles.inputButton}
              >
                <div
                  style={{
                    ...pageStyles.input,
                    display: "flex",
                    alignItems: "center",
                    color: "#666",
                  }}
                >
                  {businessName || "Date of Birth"}
                  <FaPencilAlt
                    size={16}
                    color="#007bff"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={pageStyles.formRow}>
            <div style={pageStyles.formField}>
              <label style={pageStyles.label}>Email</label>
              <div
                onClick={() => handleInputClick("Email")}
                style={pageStyles.inputButton}
              >
                <div
                  style={{
                    ...pageStyles.input,
                    alignItems: "center",
                    color: "#666",
                  }}
                >
                  {email || "Enter email"}
                  <FaPencilAlt
                    size={16}
                    color="#007bff"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
            </div>
            <div style={pageStyles.formField}>
              <label style={pageStyles.label}>Owner Name</label>
              <div
                onClick={() => handleInputClick("Owner Name")}
                style={{
                  ...pageStyles.input,
                  display: "flex",
                  alignItems: "center",
                  color: "#666",
                }}
              >
                {ownerName || "Enter full name"}
                <FaPencilAlt
                  size={16}
                  color="#007bff"
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>
          </div>
          <div style={pageStyles.formRow}>
            <div style={pageStyles.formField}>
              <label style={pageStyles.label}>Phone Number</label>
              <div
                onClick={() => handleInputClick("Phone Number")}
                style={pageStyles.inputButton}
              >
                <div
                  style={{
                    ...pageStyles.input,
                    alignItems: "center",
                    color: "#666",
                  }}
                >
                  {phoneNumber || "Enter phone number"}
                  <FaPencilAlt
                    size={16}
                    color="#007bff"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
            </div>
            <div style={pageStyles.formField}>
              <label style={pageStyles.label}>Password</label>
              <div
                onClick={() => handleInputClick("Password")}
                style={pageStyles.inputButton}
              >
                <div
                  style={{
                    ...pageStyles.input,
                    display: "flex",
                    alignItems: "center",
                    color: "#666",
                  }}
                >
                  {password}
                  <FaPencilAlt
                    size={16}
                    color="#007bff"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProfileModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        mode={selectedField || ""}
      />
    </div>
  );
};

const pageStyles: { [key: string]: CSSProperties } = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100vh",
    backgroundColor: "#f7f7f7",
    overflowX: "auto", // Prevent horizontal overflow
  },

  buttonBar: {
    position: "absolute", // Absolute within the pink container
    bottom: "0", // Stick to the bottom of the pink container
    left: "0", // Align to the left edge of the pink container
    width: "100%", // Make the bar take up the full width
    backgroundColor: "#f5f5f5",
    padding: "10px 20px", // Add padding for spacing
    display: "flex", // Use flexbox for layout
    justifyContent: "flex-start", // Align items to the left
    alignItems: "center", // Center align items vertically
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", // Add a shadow for visual separation
    zIndex: 1000, // Ensure it is above other elements
    borderBottomLeftRadius: "15px", // Add border radius to bottom-left corner
    borderBottomRightRadius: "15px", // Add border radius to bottom-right corner
  },

  backButton: {
    padding: "15px 30px",
    fontFamily: "Roboto-Bold",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "50px",
    cursor: "pointer",
    marginRight: "auto", // Align the button to the left by using margin auto on the right side
  },

  input: {
    display: "flex",
    justifyContent: "space-between", // Align items from start to end
    alignItems: "center",
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "white", // Ensures the background color is consistent
    cursor: "pointer", // Makes the entire input area clickable
  },

  inputButton: {
    background: "none",
    border: "none",
    padding: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  modalContent: {
    display: "flex",
    marginTop: "50px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "800px", // Ensure it doesn't exceed a certain width
    padding: "0 20px",
    boxSizing: "border-box",
    overflowX: "hidden",
  },
  modalTitle: {
    fontFamily: "PoppinsSemi",
    fontSize: "24px",
    color: "#333",
    marginBottom: "30px",
  },
  profilePictureContainer: {
    marginBottom: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    background: "linear-gradient(to bottom right, #bff0fe, #cbfde0)",
    position: "relative",
    cursor: "pointer",
  },
  profileImage: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid #007bff",
  },
  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    textAlign: "center",
    lineHeight: "25px",
    padding: 0,
  },
  iconContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  smallIconCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "#007bff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
    maxWidth: "1000px", // Adjust as necessary, don't use an excessively large maxWidth
    margin: "0 auto", // Add this to ensure it centers in its parent container
  },

  formRow: {
    display: "flex",
    flexDirection: "row",
    gap: "20px", // Adjust the gap if necessary
    justifyContent: "center", // This should center the content of each row
    width: "100%",
    marginBottom: "15px",
  },

  formField: {
    flex: "1 0 45%", // Allows items to shrink on smaller screens
    flexShrink: 1,
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
    marginBottom: "5px",
  },

  addButton: {
    backgroundColor: "#007bff",
    fontFamily: "Roboto-Bold",
    color: "white",
    border: "none",
    borderRadius: "25px",
    padding: "20px 20px",
    cursor: "pointer",
    marginTop: "20px",
    fontSize: "18px",
    width: "100%",
    maxWidth: "600px",
  },
};

export default Profile;
