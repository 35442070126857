// src/store/prospectsReducer.js
const initialState = {
  prospects: [],
};

function prospectsReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_PROSPECTS':
      return {
        ...state,
        prospects: action.payload,
      };
    default:
      return state;
  }
}

export default prospectsReducer;

// Action creators
export const setProspects = (prospects: any) => ({
  type: 'SET_PROSPECTS',
  payload: prospects,
});
