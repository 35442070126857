// components/LoginForm.tsx
import React, { useEffect, useState } from 'react';
import TopBar from '.././TopBar';
import './ContactForm.css';
import { db } from '../../App'; // Adjust the import path as per your project structure
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { SERVER_URL}  from ".././Functions"
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const ContactForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [area, setArea] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [clientIp, setClientIp] = useState('');

  const navigate = useNavigate();

  
  useEffect(() => {
    // Fetch client IP when component mounts
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setClientIp(data.ip))
      .catch(console.error);
  }, []);
  
  const handleLoginClick = () => {
    navigate('/login'); // Use the path for your ContactForm page
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // Stop form submission if validation fails
    }
    setIsSubmitting(true); 

    try {
      const response = await fetch(`${SERVER_URL}/api/saveContact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, name, area, clientIp }),
      });
  
      const responseData = await response.json(); // Parse the JSON response
  
      if (!response.ok) {
        setResponseMessage(responseData.message); // Update the response message with the specific error message
        throw new Error(responseData.message || 'Failed to save contact information');
      }
  
      // Handle success response
      setEmail('');
      setName('');
      setArea('');
      setResponseMessage(responseData.message); 
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error:', error);
      //@ts-ignore
      setResponseMessage(error.message); // Update the response message with the specific error message
      setIsSubmitting(false); // Stop submitting after successful operation
    }
  };
  
  const validateForm = () => {
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setResponseMessage('Please enter a valid email address.');
      return false;
    }
  
    // Validate name
    if (name.length < 3 ) {
      setResponseMessage('Name must be at least 3 characters long');
      return false;
    }
    if (/\d/.test(name)) {
      setResponseMessage('Name must not contain numbers.');
      return false;
    }
  
  
    // Validate area (zipcode)
    if (area.length < 3) {
      setResponseMessage('Area/ZipCode must be at least 3 characters long.');
      return false;
    }
  
    return true;
  };

  const handleHomeClick = () => {
    navigate('/'); // Use the path for your ContactForm page
  };


  return (
    <div className="home-container">
     <div className="top-bar">
        <div className="contact-info">
          <IoMdMail color='#06adee' style={{marginRight:'10'}}/>
          <span className="email">dripautocare@gmail.com</span>
          <FaPhoneAlt color='#06adee' style={{marginRight:'10'}}/>
          <span className="phone">(888) 010203-4567</span>
        </div>
        <div className="appointment-button">
        <button className="appointment-btn" >Subscribe to our Newletter</button>
        </div>
        
      </div>

      <div className="double">
      <div className="logo">
              <img src="https://dac.successpot.tech/application/views/front/img/dac2.png" alt="Logo" />         
            </div>

      <nav className="navbar">
        <ul className="nav-menu">
          <li onClick={handleHomeClick}>Home</li>
          <li><a href="https://dac.successpot.tech/about">About</a></li>
    <li><a href="https://dac.successpot.tech/service">Services</a></li>
    <li><a href="https://dac.successpot.tech/memberAccount">My Account</a></li>
    <li><a href="https://dac.successpot.tech/contact">Contact</a></li>
    <li><a href="https://dac.successpot.tech/faq">FAQ</a></li>
        </ul>
      </nav>
      </div>


      <div className='form-title'>
      <h1>Subscribe to our Newsletter </h1>
      </div>
      
            <div className="centered-container">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="name"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
           <input
          type="area"
          placeholder="Area/ZipCode"
          value={area}
          onChange={(e) => setArea(e.target.value)}
        />
        <p >{responseMessage}</p>


<p className="small-text">By signing up to our newsletter, you agree to receive periodic emails from us about updates, news, and special offers. You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy practices, please visit our website.</p>

<button 
  onClick={handleSubmit} 
  disabled={isSubmitting}
  className={isSubmitting ? "loading" : ""} 
>
  {isSubmitting ? <div className="spinner"></div> : 'Subscribe'}
</button>
        
        </div>

      <div className="footer">
  <div className="footer-section" id="about">
    <h3>About Us</h3>
    <p>Drip Auto Care is a mobile carwash company that offers subscription-based service. Our package includes two mini-details for $69.99 on a monthly basis. Subscription requires auto-draft payments that will be drafted on a monthly basis within a week prior to automatic renewal.</p>
  </div>
  <div className="footer-link" id="links">
  <h3>Quick Links</h3>
  <div className="link-item">
  <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Home</li>
          </div>
          <div className="link-item">

          <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Help Center</li>
          </div>

          <div className="link-item">

          <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>About</li>
          </div>

          <div className="link-item">

          <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Create Account</li>
          </div>

          <div className="link-item">

          <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Service And Help</li>
          </div>

          <div className="link-item">

          <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Contact</li>
          </div>

          </div>
  <div className="footer-link" id="links">
    
  <h3>Support Links</h3>
  <div className="link-item">
  <MdKeyboardDoubleArrowRight color='#06adee' />
  <li>Disclaimer</li>
  </div>

  <div className="link-item">

  <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Create Account</li>
          </div>

          <div className="link-item">

          <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Privacy & Policy</li>
          </div>

          <div className="link-item">

          <MdKeyboardDoubleArrowRight color='#06adee' />
          <li>Terms & Conditions</li>
          </div>

          </div>
  <div className="footer-section" id="account">
    <h3>Account</h3>
    <div className="buttons">
    <button onClick={handleLoginClick} className="read-more">Service Provider</button>
            <button onClick={handleLoginClick} className="read-more">Drip Members</button>
          </div>
          </div>
</div>

<div className="footer-bottom">
    <p>
      Copyright © 2021 - 2023 Drip-Auto Operating Company, LLC. All Rights Reserved.
    </p>
  </div>

    </div>
  );
};

export default ContactForm;
