// ProvidersGrid.tsx
import React, { useEffect, useState } from "react";
import ProviderModal from "./ProviderModal";
import { formatBase64Image, formatDateTimeFromTimestamp } from ".././Functions";
import {
  MdAccountCircle,
  MdEmail,
  MdLocationOn,
  MdOutlineEmail,
  MdOutlineLocationOn,
  MdOutlinePhone,
  MdPhone,
  MdPhoneEnabled,
} from "react-icons/md";
import { RiMailLine, RiMapPinLine, RiPhoneLine } from "react-icons/ri";
import { Provider } from "../store/actions/types";

interface ProvidersGridProps {
  providers: Provider[];
}

const ProvidersGrid: React.FC<ProvidersGridProps> = ({ providers }) => {
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleProviderClick = (provider: Provider) => {
    setSelectedProvider(provider);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const gridStyles: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
    gap: "30px",
    padding: "30px",
  };

  const gridItemStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Poppins",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "15px",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    overflow: "hidden",
    boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
  };

  const imageStyles: React.CSSProperties = {
    width: "65px",
    height: "65px",
    borderRadius: "25%",
    border: "3px solid #007ae4",
    objectFit: "cover",
    marginBottom: "40px",
    alignSelf: "center", // Center the image within its row
  };

  const textContainerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "0 30px",
    //maxWidth: "400px",
  };

  const iconStyle: React.CSSProperties = {
    marginRight: "6px",
    color: "#007ae4",
    verticalAlign: "middle",
  };

  const valueStyle: React.CSSProperties = {
    fontSize: 16,
    marginBottom: "5px", // Space between each item
    display: "flex",
    alignItems: "center", // This ensures that the icon and text align vertically
  };

  return (
    <div style={gridStyles}>
      {providers.map((provider) => (
        <div
          key={provider.uid}
          style={gridItemStyles}
          onClick={() => handleProviderClick(provider)}
        >
     
          {/* Wrapper for the image to take full row */}
          {provider.profileImage ? (
            <img
              src={formatBase64Image(provider.profileImage)}
              alt="Provider"
              style={imageStyles}
            />
          ) : (
            <MdAccountCircle
              size="100"
              style={{ ...imageStyles, color: "#007ae4" }}
            />
          )}
          <div style={textContainerStyles}>
            <div
              style={{
                ...valueStyle,
                fontFamily: "PoppinsMedium, Poppins, sans-serif",
                fontSize: 22,
              }}
            >
              {provider.businessName}
            </div>
            <div style={valueStyle}>
              <MdEmail size={18} style={iconStyle} />
              {provider.emailAddress}
            </div>
            <div style={valueStyle}>
              <MdPhone size={18} style={iconStyle} />
              {provider.phoneNumber}
            </div>
            <div style={valueStyle}>
              <MdLocationOn size={18} style={iconStyle} />
              {/*@ts-ignore make this zipcode for consistency*/}
              {provider.postcode}
            </div>
          </div>
        </div>
      ))}
      {isModalOpen && selectedProvider && (
        <ProviderModal
          providerInfo={selectedProvider}
          onClose={handleCloseModal}
          isOpen={isModalOpen}
        />
      )}
    </div>
  );
};

export default ProvidersGrid;
