// components/LoginForm.tsx
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import TopBar from "../TopBar";
import dripAutoImage from "../../assets/login.png";
import { AuthContext } from "../../Auth-Context";
import Modal from "react-modal";

import "./LoginForm.css"; // This is the correct way to import the CSS file

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState(" ");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [resetEmail, setResetEmail] = useState(""); // State for reset email input

  const navigate = useNavigate(); // Initialize useNavigate
  const auth = getAuth();

  const { userRole } = useContext(AuthContext); // Consume AuthContext
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  const isValidEmail = (email: any) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async () => {
    if (!isValidEmail(resetEmail)) {
      setModalMessage("Please enter a valid email address");
      return;
    }

    try {
      setIsSubmitting(true); // Start activity indicator
      await sendPasswordResetEmail(auth, resetEmail);
      setModalMessage("Password reset email sent! Please check your inbox.");
      setIsSubmitting(false); // Stop activity indicator
    } catch (error) {
      setModalMessage("Error sending reset email. Please try again.");
      setIsSubmitting(false); // Stop activity indicator
      console.error("Forgot Password error:", error);
    }
  };

  const handleLogin = async () => {
    if (!isValidEmail(email)) {
      setResponseMessage("Please enter a valid email address");
      setIsSubmitting(false);
      return;
    }

    if (password.length < 6) {
      setResponseMessage("Password must be at least 6 characters long");
      setIsSubmitting(false);
      return;
    }

    try {
      setIsSubmitting(true);
      setResponseMessage("");

      if (auth.currentUser) {
        await auth.signOut(); // Log out the current user
      }

      // Set session persistence for this authentication session
      await setPersistence(auth, browserSessionPersistence);

      //log in the user
      await signInWithEmailAndPassword(auth, email, password);

      setIsLoginSuccessful(true); // Set flag on successful login
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      //@ts-ignore
      setResponseMessage(error.message); // Display the error message to the user
      console.error("Login error:", error);
    }
  };

  /*
  useEffect(() => {
    // Check if the user is already authenticated and redirect to index page
    const auth = getAuth();
    const currentUser = auth.currentUser;
    console.log('this'+currentUser)
    if (currentUser) {
      navigate('/index');
    }
  }, [navigate]);  */

  useEffect(() => {
    if (isLoginSuccessful && userRole) {
      switch (userRole) {
        case "management":
          navigate("/index");
          break;
        case "provider":
          navigate("/indexProv");
          break;
        // other cases as needed
        default:
        //navigate("/"); // Default navigation if role is not matched
      }
    }
  }, [userRole, isLoginSuccessful, navigate]);

  const navigateToRegister = () => {
    navigate("/register"); // Change to your login route
  };

  const handleLoginClick = () => {
    navigate("/"); // Use the path for your ContactForm page
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "450px",
      padding: "50px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.leftContainer}>
        <img src={dripAutoImage} alt="Drip Auto" style={styles.dripAutoImage} />
        <div style={styles.centeredContent}>
          <div
            onClick={handleLoginClick}
            style={{ fontSize: 20, fontWeight: 600 }}
          >
            Go Home
          </div>
        </div>
        <p style={styles.copyrightText}>
          Copyright © 2019 - 2024 Drip-Auto Care inc. All Rights Reserved.
        </p>
      </div>
      <div style={styles.rightContainer}>
        <div style={styles.formContainer}>
          <h2 style={styles.loginTitle}>Welcome Back</h2>
          <p style={styles.leftAlign}>Lets Sign in to your Rinz account</p>
          <label htmlFor="email" style={styles.labelStyle}>
            Email
          </label>
          <input
            style={styles.inputButton}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="password" style={styles.labelStyle}>
            Password
          </label>
          <input
            style={styles.inputButton}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p style={styles.responseMessage}>{responseMessage}</p>

          <p style={styles.terms} onClick={() => setModalIsOpen(true)}>
            Forgot password?
          </p>

          <button
            style={{
              ...styles.inputButton,
              ...styles.button,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleLogin}
            disabled={isSubmitting}
            className={isSubmitting ? "loading" : ""}
          >
            {isSubmitting ? <div className="spinner-small"></div> : "Login"}
          </button>

          <p onClick={navigateToRegister} style={styles.centerText}>
            Don't have an account?{" "}
            <span style={{ color: "#007ae4", cursor: "pointer" }}>Sign Up</span>
          </p>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setModalMessage(""); // Clear the message when modal closes
          setIsSubmitting(false); // Reset the submitting state if needed
        }}
        style={modalStyles}
        contentLabel="Forgot Password"
      >
        <button
          onClick={() => setModalIsOpen(false)}
          style={styles.closeButton}
        >
          &times;
        </button>
        <h2>Forgot Password</h2>
        <p>
          {modalMessage ||
            "Enter your email address below and we'll send you a link to reset your password."}
        </p>
        {modalMessage && (
          <button
            style={{
              ...styles.inputButton,
              ...styles.button,
              backgroundColor: "grey",
            }}
            onClick={() => setModalMessage("")}
          >
            Close
          </button>
        )}

        {!modalMessage && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <input
                type="email"
                placeholder="Email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                style={styles.inputButton}
              />
            </div>
            <button
              style={{

                ...styles.inputButton,
                ...styles.button,
                display: "flex",  // Ensure the button uses flexbox
                justifyContent: "center",  // Center content horizontally
                alignItems: "center",  // Center content vertically
              }}
              onClick={handleForgotPassword}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div className="spinner-small"></div>
              ) : (
                "Send Reset Link"
              )}
            </button>
            <button
              style={{
                ...styles.inputButton,
                ...styles.button,
                backgroundColor: "grey",
              }}
              onClick={() => setModalIsOpen(false)}
            >
              Close
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  mainContainer: {
    display: "flex",
    flex: 1,
    height: "100vh",
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },

  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "24px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },

  labelStyle: {
    display: "block",
    marginBottom: "-15px",
    marginLeft: "5px",
    fontSize: "16px",
    fontWeight: 600,
  },
  rightContainer: {
    flex: 1,
    padding: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor: "#eeeee4",
    padding: "0px",
    position: "relative",
  },
  dripAutoImage: {
    //width: "90%", // Image occupies 90% of its parent container
    maxWidth: "70%", // Maximum width to prevent the image from being too large
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    margin: "0 auto 20px auto", // Center the image horizontally
  },
  centeredContent: {
    textAlign: "center",
    fontSize: "36px",
    cursor: "pointer",
    width: "100%",
    position: "relative",
    padding: "10px 0",
  },

  loginTitle: {
    alignSelf: "flex-start",
    fontSize: 36,
    fontWeight: 800,
  },
  leftAlign: {
    alignSelf: "flex-start",
    marginBottom: "50px",
    fontWeight: 500,
    color: "#9c9c9c",
  },
  centerText: {
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: 500,
    color: "#9c9c9c",
  },
  input: {
    margin: "25px 0",
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    fontSize: "16px",
  },
  terms: {
    alignSelf: "flex-start",
    textAlign: "right", // Aligns the text to the right side of its container
    fontWeight: 500,
    color: "#007AE4",
    cursor: "pointer", // This will change the cursor to a hand on hover
  },
  responseMessage: {
    color: "red",
    textAlign: "center",
  },
  inputButton: {
    margin: "25px 0",
    width: "100%",
    padding: "15px",
    fontFamily: "Roboto-Medium",
    border: "2px solid #e8edf3",
    backgroundColor: "#fafcfe",
    borderRadius: 10,
    //borderRadius: "10px",
    fontSize: "18px",
  },
  button: {
    backgroundColor: "#007ae4", // Custom blue color
    color: "white",
    cursor: "pointer",
    margin: "25px 0",
    width: "100%",
    padding: "15px",
    border: "0px solid #ccc",
    fontSize: "18px",
    fontWeight: 800,
    borderRadius: "40px", // Remove rounded corners
  },

  formContainer: {
    flex: 1,
    maxWidth: "500px",
  },
  copyrightText: {
    textAlign: "center",
    position: "absolute",
    bottom: "10px",
    width: "100%",
    fontWeight: 500,
  },
};

export default LoginForm;
