import React, { CSSProperties, useState } from "react";
import Modal from "react-modal";
import { IoPerson, IoCamera } from "react-icons/io5";

interface AddEmployeeModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleContainerClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    //@ts-ignore
    fileInput.onchange = handleFileChange;
    document.body.appendChild(fileInput);
    fileInput.click();
    fileInput.addEventListener("change", () => {
      document.body.removeChild(fileInput);
    });
  };

  const handleContinue = () => {
    // Implement your continue logic here
    onRequestClose();
  };

  const handleSkip = () => {
    const confirmSkip = window.confirm(
      "Are you sure you want to skip uploading a profile picture?"
    );
    if (confirmSkip) {
      setProfilePicture(null);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ content: modalStyles.content, overlay: modalStyles.overlay }} // Add this line
      contentLabel="Add New Employee"
    >
      <div style={modalStyles.modalContent}>
        <h2 style={modalStyles.modalTitle}>Add New Employee</h2>
        <div style={modalStyles.profilePictureContainer} onClick={handleContainerClick}>
          {profilePicture ? (
            <>
              <img
                src={profilePicture}
                alt="Profile"
                style={modalStyles.profileImage}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setProfilePicture(null);
                }}
                style={modalStyles.removeButton}
              >
                X
              </button>
            </>
          ) : (
            <>
              <IoPerson size={80} color="#65bae3" />
              <div style={modalStyles.iconContainer}>
                <div style={modalStyles.smallIconCircle}>
                  <IoCamera size={25} color="white" />
                </div>
              </div>
            </>
          )}
        </div>
        <div style={modalStyles.formContainer}>
          <div style={modalStyles.formRow}>
            <div style={modalStyles.formField}>
              <label style={modalStyles.label}>First Name</label>
              <input type="text" placeholder="Enter first name" style={modalStyles.input} />
            </div>
            <div style={modalStyles.formField}>
              <label style={modalStyles.label}>Last Name</label>
              <input type="text" placeholder="Enter last name" style={modalStyles.input} />
            </div>
          </div>
          <div style={modalStyles.formRow}>
            <div style={modalStyles.formField}>
              <label style={modalStyles.label}>Email</label>
              <input type="email" placeholder="Enter email" style={modalStyles.input} />
            </div>
            <div style={modalStyles.formField}>
              <label style={modalStyles.label}>Phone Number</label>
              <input type="tel" placeholder="Enter phone number" style={modalStyles.input} />
            </div>
          </div>
          <div style={modalStyles.formRow}>
            <div style={modalStyles.formField}>
              <label style={modalStyles.label}>Enter Issue</label>
              <input type="text" placeholder="Enter issue" style={modalStyles.input} />
            </div>
            <div style={modalStyles.formField}>
              <label style={modalStyles.label}>Title</label>
              <input type="text" placeholder="Enter title" style={modalStyles.input} />
            </div>
          </div>
        </div>
        <button onClick={handleContinue} style={modalStyles.addButton}>Add Employee</button>
      </div>
    </Modal>
  );
};

const modalStyles: { [key: string]: CSSProperties } = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // This line changes the overlay background to black with 75% opacity
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "25px",
    width: "650px", // Increase width slightly
    maxHeight: "95vh", // Increase height slightly
    overflow: "auto",
  },
  modalTitle: {
      fontFamily: "PoppinsSemi",
    fontSize: "24px",
    color: "#333",
    marginBottom: "30px", // Increase gap between title and profile picture container
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "15px", // Increase gap between text inputs
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  profilePictureContainer: {
    marginBottom: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    background: "linear-gradient(to bottom right, #bff0fe, #cbfde0)",
    position: "relative",
    cursor: "pointer",
  },
  profileImage: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid #007bff",
  },
  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    textAlign: "center",
    lineHeight: "25px",
    padding: 0,
  },
  iconContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  smallIconCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "#007bff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
    maxWidth: "600px",
  },

  formField: {
    flex: "1 0 45%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
    marginBottom: "5px",
  },
  input: {
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    maxWidth: "100%",
    boxSizing: "border-box",
  },
  addButton: {
    backgroundColor: "#007bff",
    fontFamily:'Roboto-Bold',
    color: "white",
    border: "none",
    borderRadius: "25px",
    padding: "20px 20px",
    cursor: "pointer",
    marginTop: "20px",
    fontSize: "18px",
    width: "100%",
    maxWidth: "600px",
  },
  skipButton: {
    backgroundColor: "grey",
    color: "white",
    border: "none",
    borderRadius: "25px",
    padding: "10px 20px",
    cursor: "pointer",
    marginTop: "10px",
    fontSize: "16px",
    width: "100%",
    maxWidth: "500px",
  },
};

export default AddEmployeeModal;
