const initialState = {
  coupons: [],
};


function couponsReducer(state = initialState, action:any) {
  switch (action.type) {
    case 'SET_COUPONS':
      return {
        ...state,
        coupons: action.payload,
      };
    default:
      return state;
  }
}

export default couponsReducer;

// Action creators
export const setCoupons = (coupons:any) => ({
  type: 'SET_COUPONS',
  payload: coupons,
});
