// src/store/ordersReducer.js
const initialState = {
  orders: [],
};

function ordersReducer(state = initialState, action:any) {
  switch (action.type) {
    case 'SET_ORDERS':
      return {
        ...state,
        orders: action.payload,
      };
    default:
      return state;
  }
}

export default ordersReducer;

// Action creators
export const setOrders = (orders:any) => ({
  type: 'SET_ORDERS',
  payload: orders,
});
