import React, { CSSProperties, useEffect, useState } from "react";
import Modal from "react-modal";
import { IoPerson, IoCamera } from "react-icons/io5";
import { FaPencilAlt, FaTimes } from "react-icons/fa";
import { firebase } from "../../App";
import {
  getAuth,
  updateEmail,
  updatePassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { db } from "../../App";
import { doc, updateDoc } from "firebase/firestore";
import { convertImageToBase64 } from "../Functions";

interface ProfileModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  mode: string; // "picture", "password", "Business Name", etc.
}

const ProfileModal: React.FC<ProfileModalProps> = ({
  isOpen,
  onRequestClose,
  mode,
}) => {
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [email, setEmail] = useState<string>(""); // Add this for email input
  const [businessName, setBusinessName] = useState<string>(""); // Add this for business name input
  const [ownerName, setOwnerName] = useState<string>(""); // Add this for owner name input
  const [phoneNumber, setPhoneNumber] = useState<string>(""); // Add this for phone number input
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false);
  const [successBannerMessage, setSuccessBannerMessage] = useState<string>("");
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");

  const auth = getAuth();

  useEffect(() => {
    if (!isOpen) {
      // Reset profilePicture to null when the modal closes
      setProfilePicture(null);
    }
  }, [isOpen]);
  
  const updateFirebaseProfileImage = async (base64Image: string) => {
    try {
      const userUid = auth.currentUser?.uid;
      if (userUid) {
        // Update the Firebase document directly with the base64 image
        await updateDoc(doc(db, 'Providers', userUid), {
          profileImage: base64Image,
        });
  
        console.log('Profile image updated successfully in Firebase');
      }
    } catch (error) {
      console.error('Error updating profile image in Firebase:', error);
    }
  };
  

  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const image = new Image();
      const src = URL.createObjectURL(file);
      image.onload = () => {
        const maxDimension = 500;
        const width = image.width;
        const height = image.height;
  
        // Calculate the scaling factor to resize the image while maintaining aspect ratio
        const scalingFactor = Math.min(maxDimension / width, maxDimension / height);
  
        // Calculate the new image dimensions
        const newWidth = width * scalingFactor;
        const newHeight = height * scalingFactor;
  
        // Create a canvas with the new dimensions
        const canvas = document.createElement("canvas");
        canvas.width = 500; // Fixed canvas size
        canvas.height = 500;
  
        // Draw the image onto the canvas with the fixed dimensions (distortion possible)
        const ctx = canvas.getContext("2d");
                //@ts-ignore
        ctx.drawImage(image, 0, 0, 500, 500);
  
        // Convert the canvas to a base64 string
        const base64 = canvas.toDataURL("image/png");
  
        // Set the base64 string to the profilePicture state
        setProfilePicture(base64);
  
        // Upload the base64 image to Firebase (optional)
        //updateFirebaseProfileImage(base64);
  
        // Clean up the URL object
        URL.revokeObjectURL(src);
      };
  
      image.onerror = (error) => {
        console.error("Error loading image:", error);
        setProfilePicture(null);
      };
  
      // Start the image loading process
      image.src = src;
    }
  };
  
  

  const handleContainerClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    //@ts-ignore
    fileInput.onchange = handleFileChange;
    document.body.appendChild(fileInput);
    fileInput.click();
    fileInput.addEventListener("change", () => {
      document.body.removeChild(fileInput);
    });
  };

  const authenticateUser = async () => {
    if (auth.currentUser?.email) {
      await signInWithEmailAndPassword(
        auth,
        auth.currentUser.email,
        oldPassword
      );
    } else {
      throw new Error("No user is signed in.");
    }
  };

  const changeEmail = async () => {
    setIsLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        await authenticateUser();

        await updateEmail(user, email);

        const userDoc = doc(db, "Providers", user.uid); // Use the imported db instance with updateDoc
        await updateDoc(userDoc, { emailAddress: email });

        setSuccessBannerMessage("Email Updated");
        setConfirmationMessage("Your email has been updated successfully.");
        setShowSuccessBanner(true);
      } else {
        setErrorMessage("No user is signed in to update the email.");
      }
    } catch (error) {
      //@ts-ignore
      if (error.code === "auth/email-already-in-use") {
        setErrorMessage(
          "The email address is already in use by another account."
        );
        //@ts-ignore
      } else if (error.code === "auth/invalid-email") {
        setErrorMessage("The email address is not valid.");
        //@ts-ignore
      } else if (error.code === "auth/requires-recent-login") {
        setErrorMessage("Please re-authenticate and try again.");
      } else {
        setErrorMessage("An issue occurred while updating the email.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const changePassword = async () => {
    setIsLoading(true);

    if (
      oldPassword.length < 6 ||
      newPassword.length < 6 ||
      confirmPassword.length < 6
    ) {
      setErrorMessage("All passwords must be at least 6 characters long.");
      setIsLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("New passwords do not match.");
      setIsLoading(false);
      return;
    }

    if (newPassword === oldPassword) {
      setErrorMessage("New password must be different from the old password.");
      setIsLoading(false);
      return;
    }

    try {
      const user = auth.currentUser;

      if (user) {
        await authenticateUser();
        await updatePassword(user, newPassword);

        setSuccessBannerMessage("Password Updated");
        setConfirmationMessage("Your password has been changed successfully.");
        setShowSuccessBanner(true);
      } else {
        setErrorMessage("No user is signed in to update the password.");
      }
    } catch (error: any) {
      if (error.code === "auth/weak-password") {
        setErrorMessage("The password is too weak.");
      } else {
        setErrorMessage("An issue occurred while updating the password.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const changeBusinessName = async () => {
    setIsLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        const userDoc = doc(db, "Providers", user.uid); // Use the imported db instance with updateDoc
        await updateDoc(userDoc, { businessName: businessName });

        setSuccessBannerMessage("Name Updated");
        setConfirmationMessage(
          "Your business name has been updated successfully."
        );
        setShowSuccessBanner(true);
      } else {
        setErrorMessage("No user is signed in to update the name.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating the name.");
    } finally {
      setIsLoading(false);
    }
  };

  const changeOwnerName = async () => {
    setIsLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        const userDoc = doc(db, "Providers", user.uid); // Use the imported db instance with updateDoc
        await updateDoc(userDoc, { ownerName: ownerName }); // Update the ownerName in the database

        setSuccessBannerMessage("Owner Name Updated");
        setConfirmationMessage(
          "Your owner's name has been updated successfully."
        );
        setShowSuccessBanner(true);
      } else {
        setErrorMessage("No user is signed in to update the owner's name.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating the owner's name.");
    } finally {
      setIsLoading(false);
    }
  };

  const changeNumber = async () => {
    setIsLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        const userDoc = doc(db, "Providers", user.uid); // Use the imported db instance with updateDoc
        await updateDoc(userDoc, { phoneNumber: phoneNumber });

        setSuccessBannerMessage("Phone Number Updated");
        setConfirmationMessage(
          "Your phone number has been updated successfully."
        );
        setShowSuccessBanner(true);
      } else {
        setErrorMessage("No user is signed in to update the phone number.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating the phone number.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    setIsLoading(true);
    try {
      switch (mode) {
        case "Email":
          await changeEmail();
          break;
        case "Password":
          await changePassword();
          break;
        case "Business Name":
          await changeBusinessName();
          break;
        case "Phone Number":
          await changeNumber();
          break;
        case "Owner Name":
          await changeOwnerName(); // Update owner's name
          break;
        case "picture":
          if (profilePicture) {
            await updateFirebaseProfileImage(profilePicture); // Update profile picture in Firebase
          }
          break;
        default:
          onRequestClose();
          return;
      }
      alert("Update successful!");
      onRequestClose();
    } catch (error) {
      alert("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  

  const getModalTitle = () => {
    switch (mode) {
      case "picture":
        return "Update Profile Picture";
      case "Business Name":
        return "Update Business Name";
      case "Owner Name":
        return "Update Owner Name";
      case "Phone Number":
        return "Update Phone Number";
      case "Email":
        return "Update Email";
      case "Password":
        return "Update Password";
      default:
        return "Update Profile";
    }
  };

  const getButtonText = () => {
    switch (mode) {
      case "picture":
        return "Update Profile Picture";
      case "Business Name":
        return "Update Business Name";
      case "Owner Name":
        return "Update Owner Name";
      case "Phone Number":
        return "Update Phone Number";
      case "Email":
        return "Update Email";
      case "Password":
        return "Update Password";
      default:
        return "Update Profile";
    }
  };

  const resetForm = () => {
    setProfilePicture(null);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setEmail("");
    setBusinessName("");
    setPhoneNumber("");
    setErrorMessage("");
    setShowSuccessBanner(false);
    setSuccessBannerMessage("");
    setConfirmationMessage("");
  };

  const handleCloseModal = () => {
    resetForm();
    onRequestClose();
  };
  
console.log(profilePicture)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{ content: modalStyles.content, overlay: modalStyles.overlay }}
      contentLabel={getModalTitle()}
    >
      <div style={modalStyles.modalContent}>
        <button onClick={onRequestClose} style={modalStyles.closeButton}>
          <FaTimes size={20} color="#333" />
        </button>
        <h2 style={modalStyles.modalTitle}>{getModalTitle()}</h2>

        {mode === "picture" && (
          <div
            style={modalStyles.profilePictureContainer}
            onClick={handleContainerClick}
          >
            {profilePicture ? (
              <>
                <img
                  src={profilePicture}
                  alt="Profile"
                  style={modalStyles.profileImage}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setProfilePicture(null);
                  }}
                  style={modalStyles.removeButton}
                >
                  X
                </button>
              </>
            ) : (
              <>
                <IoPerson size={80} color="#65bae3" />
                <div style={modalStyles.iconContainer}>
                  <div style={modalStyles.smallIconCircle}>
                    <IoCamera size={25} color="white" />
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {mode === "Email" && (
          <div style={modalStyles.formContainer}>
            <div style={modalStyles.formRow}>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>Old Password</label>
                <input
                  type="password"
                  placeholder="Enter old password"
                  style={modalStyles.input}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
            </div>
            <div style={modalStyles.formRow}>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>New Email</label>
                <input
                  type="email"
                  placeholder="Enter new email"
                  style={modalStyles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        {mode === "Owner Name" && (
          <div style={modalStyles.formContainer}>
            <div style={modalStyles.formRow}>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>New Owner Name</label>
                <input
                  type="text"
                  placeholder="Enter new owner name"
                  style={modalStyles.input}
                  value={businessName} // or use a separate state for owner's name if different
                  onChange={(e) => setBusinessName(e.target.value)} // or update the state accordingly
                />
              </div>
            </div>
          </div>
        )}

        {mode === "Password" && (
          <div style={modalStyles.formContainer}>
            <div style={modalStyles.formRow}>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>Old Password</label>
                <input
                  type="password"
                  placeholder="Enter old password"
                  style={modalStyles.input}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
            </div>
            <div style={modalStyles.formRow}>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>New Password</label>
                <input
                  type="password"
                  placeholder="Enter new password"
                  style={modalStyles.input}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>Confirm Password</label>
                <input
                  type="password"
                  placeholder="Confirm new password"
                  style={modalStyles.input}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        {mode === "Business Name" && (
          <div style={modalStyles.formContainer}>
            <div style={modalStyles.formRow}>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>New Business Name</label>
                <input
                  type="text"
                  placeholder="Enter new business name"
                  style={modalStyles.input}
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        {mode === "Phone Number" && (
          <div style={modalStyles.formContainer}>
            <div style={modalStyles.formRow}>
              <div style={modalStyles.formField}>
                <label style={modalStyles.label}>Phone Number</label>
                <input
                  type="text"
                  placeholder="Enter new phone number"
                  style={modalStyles.input}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        <button
          onClick={handleUpdateProfile}
          style={modalStyles.addButton}
          disabled={isLoading} // Disable the button while loading
        >
          {isLoading ? <div className="spinner-small"></div> : getButtonText()}
        </button>
      </div>
    </Modal>
  );
};

const modalStyles: { [key: string]: CSSProperties } = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "25px",
    width: "500px",
    minHeight: "350px", // Minimum height to ensure consistent size
    maxHeight: "95vh",
    overflow: "auto",
    position: "relative", // To position the close button inside the modal
    display: "flex",
    flexDirection: "column", // Flexbox layout to push the button to the bottom
  },
  modalTitle: {
    fontFamily: "PoppinsSemi",
    fontSize: "24px",
    color: "#333",
    marginBottom: "30px",
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "1",
    padding: "0 30px", // Add padding inside the modal content
  },

  profilePictureContainer: {
    marginBottom: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    background: "linear-gradient(to bottom right, #bff0fe, #cbfde0)",
    position: "relative",
    cursor: "pointer",
  },
  profileImage: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid #007bff",
  },
  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    width: "25px",
    height: "25px",
    textAlign: "center",
    lineHeight: "25px",
    padding: 0,
  },
  iconContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  smallIconCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "#007bff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
    maxWidth: "450px", // Reduce the width to create more space around
  },
  formField: {
    flex: "1 0 45%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
    marginBottom: "5px",
  },
  input: {
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    maxWidth: "100%",
    boxSizing: "border-box",
  },
  addButton: {
    backgroundColor: "#007bff",
    fontFamily: "Roboto-Bold",
    color: "white",
    border: "none",
    borderRadius: "25px",
    padding: "20px 50px",
    marginTop: "30px",
    marginBottom: "20px",
    cursor: "pointer",
    fontSize: "18px",
    width: "80%", // Ensures the button's width is as wide as its content
    maxWidth: "800px",
    alignSelf: "center", // Centers the button horizontally
    display: "flex", // Added for flexbox
    alignItems: "center", // Centers content vertically
    justifyContent: "center", // Centers content horizontally
    position: "relative", // Ensures the spinner is placed relative to the button
  },

  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
};

export default ProfileModal;
