import React, { useEffect, useState } from "react";
import HomeHeader from "./HomeHeader"; // Import the Header component
import Footer from "./HomeFooter"; // Import the Footer component

const HomeServices: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
    };

    window.addEventListener("resize", handleResize);

    // Call the handleResize function on mount to ensure it's set correctly
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        <>
          <ServicesMobile />
        </>
      ) : (
        <>
          <ServicesDesktop />
        </>
      )}
    </div>
  );
};

// ServicesMobile Component
const ServicesMobile: React.FC = () => {
  return (
    <div style={{ height: "100vh", position: "relative" }}>
      <HomeHeader isMobile={true} />
      <PromoVideoPlaceholder /> {/* Add the shared promo video component */}
      <Footer isMobile={true} />
    </div>
  );
};

// ServicesDesktop Component
const ServicesDesktop: React.FC = () => {
  return (
    <div
      style={{
        minHeight: "100vh", // Ensures the component takes up at least the full viewport height
        display: "flex",
        flexDirection: "column", // Ensures the content is stacked vertically
        justifyContent: "space-between", // Makes sure the footer is pushed to the bottom
        position: "relative", 
      }}
    >
      <HomeHeader isMobile={false} />
      <PromoVideoPlaceholder /> {/* Add the shared promo video component */}
      <Footer isMobile={false} />
    </div>
  );
};

const PromoVideoPlaceholder: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh", // Adjust as necessary
    margin: "auto",
  };

  const rectangleStyle: React.CSSProperties = {
    width: "300px",
    height: "200px",
    backgroundColor: "#e0e0e0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "2px dashed #333", // Dashed border to indicate placeholder
  };

  const textStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
  };

  return (
    <div style={containerStyle}>
      <div style={rectangleStyle}>
        <span style={textStyle}>Promo Video Here</span>
      </div>
    </div>
  );
};


export default HomeServices;
