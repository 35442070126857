// Dashboard.tsx
import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
//@ts-ignore
import geoJsonData from "../../assets/CALI-O.json"; // Import the GeoJSON data

interface ZoneData {
  id: string; // Optional in case of new entries where ID isn't known yet
  ZoneName: string;
  RegionalFactor: number;
  ZipCodes: string[];
  Circles: CircleData[];
  AssignedProviders: ProviderData[];
}

interface ProviderData {
  UID: string;
  emailAddress: string;
  rate: number;
  priority: number;
}

interface CircleData {
  center: { lat: number; lng: number };
  radius: number;
  polygon: { lat: number; lng: number }[];
}

interface RegionData {
  id: string;
  RegionName: string;
  Circles: CircleData[]; // Assuming it's the same CircleData defined earlier
}

interface MapProps {
  defaultProps: {
    center: { lat: number; lng: number };
    zoom: number;
  };

  

  height?: string; // Optional prop
  renderMarkersAndPolygons: (
    map: any,
    maps: any,
    geoJsonData: any,
    selectedArea: ZoneData | RegionData | null // Change this to selectedArea
  ) => void;

  selectedArea: ZoneData | RegionData | null; // Change this to selectedArea

  //todos: any[];
  //dashboardView: string;
  //handleDashboardViewChange: (view: string) => void;
  //setPinCoordinates?: ({ lat, lng }: { lat: number; lng: number }) => void; // Optional prop to pass the coordinates back
}

const MapComponent: React.FC<MapProps> = ({
  defaultProps,
  height = "100vh", // Default height set to 100vh if height prop is not provided
  renderMarkersAndPolygons,
  selectedArea, // Add this line
  //todos,
  //dashboardView,
  //handleDashboardViewChange,
  //setPinCoordinates = () => {}, // Provide a default no-op function
}) => {
  //console.log("Selected Zone in MapComponent:", selectedZone);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [renderedShapes, setRenderedShapes] = useState<any[]>([]);

  useEffect(() => {
    if (map && maps && selectedArea) {
      // Clear previously rendered shapes
      renderedShapes.forEach((shape) => {
        shape.setMap(null);
      });

      // Render new shapes and store them
      const newShapes = renderMarkersAndPolygons(
        map,
        maps,
        geoJsonData,
        selectedArea
      );
      //@ts-ignore
      setRenderedShapes(newShapes);
    }
  }, [map, maps, selectedArea, renderMarkersAndPolygons]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleApiLoaded = ({ map, maps }: any) => {
    // Create a marker at the center of the map

    /*
    const marker = new maps.Marker({
      position: defaultProps.center,
      map: map,
      draggable: true,
    });
  
    // Add listener for marker's dragend event
    maps.event.addListener(marker, "dragend", function() {
      const position = marker.getPosition();
      setPinCoordinates({
        lat: position.lat(),
        lng: position.lng(),
      });
    });
    */
    // Initial set of coordinates (center of the map)
    //setPinCoordinates(defaultProps.center);
    setMap(map);
    setMaps(maps);

    //renderMarkersAndPolygons(map, maps, geoJsonData, selectedZone); // Pass selectedZone to renderMarkersAndPolygons
  };
  return (
    <div>
      <div style={{ height: height, width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs", // Replace with your API key
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded} // Make sure this is called
        />
      </div>
    </div>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  dashboardTabs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  scrollableListContainer: {
    maxHeight: "97vh", // Adjust the height as necessary
    overflowY: "auto", // Enable vertical scrolling
  },

  tabButton: {
    border: "none",
    fontSize: "20px",
    margin: "0 auto",
    display: "block",
    padding: "5px 30px",
    color: "white", // Text color
    backgroundColor: "black", // Background color
    //borderRadius: '25px', // Make it round
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
  },
};

export default MapComponent;
