import React, { useEffect, useState } from "react";
import {
  MdAccountCircle,
  MdEdit,
  MdLocationOn,
  MdOutlineCalendarMonth,
} from "react-icons/md";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TitleBar from "./TitleBar";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import NoRecords from "../NoRecords";
import FilterComponent from "./FilterComponent";
import { Properties } from "csstype";

const Tabs = ({
  activeTab,
  onTabClick,
}: {
  activeTab: string;
  onTabClick: (tab: string) => void;
}) => {
  return (
    <div style={styles.tabs}>
      {["Account", "Pricing", "Tags", "Advanced"].map((tab) => (
        <div
          key={tab}
          style={activeTab === tab ? styles.activeTab : styles.tab}
          onClick={() => onTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

const Clients = () => {
  const [activeTab, setActiveTab] = useState<string>("Account");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const auth = getAuth();

  const tags = [
    "Handicap Zones",
    "Parked on grass",
    "Commercial Vehicles",
    "Permit Parking",
    "On curb/Sidewalk",
    "Double Parked",
    "Public Safety Threat",
    "Fire lane/Tow away zones",
    "Head in Parking",
    "Blocking Entrance/Exit",
    "Unauthorized Vehicle",
    "Abandoned Vehicle",
    "Reserved Parking",
    "Stickered Vehicle",
    "PPI-Patrol",
    "PPI-Call First",
    "PPI-Call Out",
    "PPI-Fast Tow",
    "Spotter Required",
    "No After Hours Parking",
    "No Boats Allowed",
    "No Trailers Allowed",
    "No Motor Homes Allowed",
    "No Campers Allowed",
    "Wrecked/Inoperable",
    "Blocking Dumpster",
    "For Sale Vehicle",
    "Flat Tire",
    "Management Request",
    "No Parking At All",
    "Blocking Garage",
    "On Blocks/Jacks",
    "No Street Parking",
    "Leaking Fluids",
    "Double Parked Min. 6-8 inches over",
    "No parking in front of Office after hours",
    "Assigned Space/When Called by Resident",
    "Assigned Space/Patrol for No Permit",
    "Double Parked behind Vehicle",
    "Tow When Security Calls",
    "Assigned Space When Called by Management",
  ];

  const advancedOptions = [
    "Set all calls to High Priority",
    "Alert (text) all managers when a call is created for this account.",
    "Always hide charges when printing or emailing the receipt",
    "Always hide discounts when printing or emailing the receipt",
    "Include copies of invoices as a part of statements",
    "Always hide photos when printing or emailing the receipt",
    "Allow users that login to this account to view files attached to calls",
  ];

  const jobProgress = [
    "Automatically invite the main contact and send job progress alerts that are enabled",
    "Automatically send a job completion text with a survey link when the call is completed",
  ];

  const mileageOptions = [
    "When submitting invoices, set unloaded mileage to 1 if it's missing or the total is 0",
    "Include Deadhead Mileage on calls for this account",
  ];

  const requiredFields = [
    "Customer Name",
    "Customer Phone",
    "Purchase Order #",
    "Invoice Number",
  ];

  return (
    <div style={stylesApp.app}>
      <TitleBar title="Client Management" />
      <Tabs activeTab={activeTab} onTabClick={handleTabClick} />
      {activeTab === "Account" && (
        <div style={formStyles.formContainer}>
          <div style={formStyles.formRow}>
            <div style={formStyles.formField}>
              <label style={formStyles.label}>Type</label>
              <select style={formStyles.input}>
                <option value="">Select type</option>
                <option value="type1">Type 1</option>
                <option value="type2">Type 2</option>
                <option value="type3">Type 3</option>
              </select>
            </div>
            <div style={formStyles.formField}>
              <label style={formStyles.label}>Account Name</label>
              <input
                type="text"
                placeholder="Enter account name"
                style={formStyles.input}
              />
            </div>
          </div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formField}>
              <label style={formStyles.label}>Phone</label>
              <input
                type="tel"
                placeholder="Enter phone number"
                style={formStyles.input}
              />
            </div>
            <div style={formStyles.formField}>
              <label style={formStyles.label}>Contact Name</label>
              <input
                type="text"
                placeholder="Enter contact name"
                style={formStyles.input}
              />
            </div>
            <div style={formStyles.formField}>
              <label style={formStyles.label}>Email</label>
              <input
                type="email"
                placeholder="Enter email"
                style={formStyles.input}
              />
            </div>
          </div>
          <div style={formStyles.formRowCheckbox}>
            <div style={formStyles.formFieldCheckbox}>
              <label style={formStyles.checkboxLabel}>
                <input type="checkbox" style={formStyles.checkbox} />
                Tax Exempt
              </label>
              <label style={formStyles.checkboxLabel}>
                <input type="checkbox" style={formStyles.checkbox} />
                Disable Account
              </label>
            </div>
          </div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formFieldFull}>
              <label style={formStyles.label}>Physical Address</label>
              <input
                type="text"
                placeholder="Enter physical address"
                style={formStyles.input}
              />
            </div>
          </div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formFieldFull}>
              <label style={formStyles.label}>Business Address</label>
              <input
                type="text"
                placeholder="Enter business address"
                style={formStyles.input}
              />
            </div>
          </div>
        </div>
      )}
      {activeTab === "Tags" && (
        <div style={tagStyles.tagContainer}>
          {tags.map((tag, index) => (
            <div key={index} style={tagStyles.tagItem}>
              <input type="checkbox" id={`tag-${index}`} />
              <label htmlFor={`tag-${index}`} style={tagStyles.tagLabel}>
                {tag}
              </label>
            </div>
          ))}
        </div>
      )}
      {activeTab === "Advanced" && (
        <div style={advancedStyles.advancedContainer}>
          <div style={advancedStyles.fieldRow}>
            <label style={advancedStyles.label}>Default PO #</label>
            <input
              type="text"
              placeholder="Default PO #"
              style={advancedStyles.input}
            />
          </div>
          <div style={advancedStyles.fieldRow}>
            <label style={advancedStyles.label}>Default Storage</label>
            <select style={advancedStyles.input}>
              <option value="">Select storage</option>
              <option value="storage1">Storage 1</option>
              <option value="storage2">Storage 2</option>
              <option value="storage3">Storage 3</option>
            </select>
          </div>
          <div style={advancedStyles.fieldRow}>
            <label style={advancedStyles.label}>Default Asset Body Type</label>
            <select style={advancedStyles.input}>
              <option value="">Select body type</option>
              <option value="type1">Type 1</option>
              <option value="type2">Type 2</option>
              <option value="type3">Type 3</option>
            </select>
          </div>
          <div style={advancedStyles.fieldRow}>
            <label style={advancedStyles.label}>Default Bill To Account</label>
            <select style={advancedStyles.input}>
              <option value="">Select account</option>
              <option value="account1">Account 1</option>
              <option value="account2">Account 2</option>
              <option value="account3">Account 3</option>
            </select>
          </div>
          <div style={advancedStyles.section}>
            <h3 style={advancedStyles.sectionTitle}>Advanced Options</h3>
            {advancedOptions.map((option, index) => (
              <div key={index} style={advancedStyles.optionRow}>
                <input type="checkbox" id={`advanced-${index}`} />
                <label
                  htmlFor={`advanced-${index}`}
                  style={advancedStyles.optionLabel}
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
          <div style={advancedStyles.section}>
            <h3 style={advancedStyles.sectionTitle}>Job Progress and Surveys</h3>
            {jobProgress.map((option, index) => (
              <div key={index} style={advancedStyles.optionRow}>
                <input type="checkbox" id={`job-${index}`} />
                <label
                  htmlFor={`job-${index}`}
                  style={advancedStyles.optionLabel}
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
          <div style={advancedStyles.section}>
            <h3 style={advancedStyles.sectionTitle}>Mileage</h3>
            {mileageOptions.map((option, index) => (
              <div key={index} style={advancedStyles.optionRow}>
                <input type="checkbox" id={`mileage-${index}`} />
                <label
                  htmlFor={`mileage-${index}`}
                  style={advancedStyles.optionLabel}
                >
                  {option}
                </label>
              </div>
            ))}
            <div style={advancedStyles.fieldRow}>
              <label style={advancedStyles.label}>
                Options for calculating mileage for your calls
              </label>
              <select style={advancedStyles.input}>
                <option value="">Select option</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
            </div>
            <div style={advancedStyles.fieldRow}>
              <label style={advancedStyles.label}>
                Automatically fill in invoice mileage quantities
              </label>
              <select style={advancedStyles.input}>
                <option value="">Select option</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
            </div>
          </div>
          <div style={advancedStyles.section}>
            <h3 style={advancedStyles.sectionTitle}>Required Fields</h3>
            <div style={advancedStyles.optionRow}>
              <label style={advancedStyles.subSectionTitle}>
                Require for call completion by Driver
              </label>
            </div>
            {requiredFields.map((field, index) => (
              <div key={index} style={advancedStyles.optionRow}>
                <input type="checkbox" id={`required-${index}`} />
                <label
                  htmlFor={`required-${index}`}
                  style={advancedStyles.optionLabel}
                >
                  {field}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const styles: { [key: string]: Properties<string | number, string> } = {
  tabs: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    fontSize: "18px",
    justifyContent: "center",
    padding: "10px",
    marginLeft: "17px",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    marginBottom: "30px",
    maxWidth: 470,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  tab: {
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "10px",
    margin: "0 5px",
  },
  activeTab: {
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "10px",
    margin: "0 5px",
    backgroundColor: "#007bff",
    color: "white",
  },
  invoiceHeaderRow: {
    display: "grid",
    gridTemplateColumns: "16% 11% 11% 11% 11% 11% 12% 12% 5%",
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ddd",
    borderTop: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  invoiceDetailRow: {
    display: "grid",
    gridTemplateColumns: "16% 11% 11% 11% 11% 11% 12% 12% 5%",
    borderBottom: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    alignItems: "center",
    cursor: "pointer",
  },
  column: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
  },
  columnHeader: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
    borderBottom: "1px solid #ddd",
    borderTop: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  editIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sendButton: {
    padding: "3px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "600",
  },
};

const stylesApp: { [key: string]: Properties<string | number, string> } = {
  app: {
    textAlign: "center",
    padding: "20px",
  },
};

const formStyles: { [key: string]: Properties<string | number, string> } = {
  formContainer: {
    width: "100%",
    maxWidth: "600px",
    //margin: "0 0",
    padding: "30px",
    textAlign: "left",
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "35px",
  },
  formRowCheckbox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    justifyContent: "flex-start",
    marginBottom: "15px",
  },
  formField: {
    flex: "1 0 30%",
    display: "flex",
    flexDirection: "column",
  },
  formFieldFull: {
    flex: "1 0 100%",
    display: "flex",
    flexDirection: "column",
  },
  formFieldCheckbox: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  label: {
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
    marginBottom: "5px",
    alignSelf: "flex-start",
  },
  input: {
    padding: "10px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    maxWidth: "100%",
    boxSizing: "border-box",
  },
  checkboxLabel: {
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  checkbox: {
    width: "16px",
    height: "16px",
  },
};

const tagStyles: { [key: string]: Properties<string | number, string> } = {
  tagContainer: {
    textAlign: "left",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "600px",
  },
  tagItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  tagLabel: {
    marginLeft: "10px",
  },
};

const advancedStyles: { [key: string]: Properties<string | number, string> } = {
  advancedContainer: {
    textAlign: "left",
    padding: "20px",
  },
  fieldRow: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
  },
  label: {
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    maxWidth: "100%",
    boxSizing: "border-box",
  },
  section: {
    marginBottom: "20px",
  },
  sectionTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: "18px",
    color: "black",
    marginBottom: "10px",
  },
  subSectionTitle: {
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
    marginBottom: "5px",
  },
  optionRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  optionLabel: {
    marginLeft: "10px",
    fontFamily: "Roboto-Medium",
    fontSize: "16px",
    color: "black",
  },
};

export default Clients;
