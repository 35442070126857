// Dashboard.tsx
import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
//@ts-ignore
import geoJsonData from "../assets/CALI-O.json"; // Import the GeoJSON data

interface MapProps {
  defaultProps: {
    center: { lat: number; lng: number };
    zoom: number;
  };
  height?: string; // Optional prop
  renderMarkersAndPolygons: (map: any, maps: any, geoJsonData: any) => void;
  setPinCoordinates?: ({ lat, lng }: { lat: number; lng: number }) => void; // Optional prop to pass the coordinates back
//todos: any[];
  //dashboardView: string;
  //handleDashboardViewChange: (view: string) => void;
}

const MapComponent: React.FC<MapProps> = ({
  defaultProps,
  height = "100vh", // Default height set to 100vh if height prop is not provided
  renderMarkersAndPolygons,
  setPinCoordinates = () => {}, // Provide a default no-op function
    //todos,
  //dashboardView,
  //handleDashboardViewChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleApiLoaded = ({ map, maps }: any) => {
    // Create a marker at the center of the map
    const marker = new maps.Marker({
      position: defaultProps.center,
      map: map,
      draggable: true,
    });
  
    // Add listener for marker's dragend event
    maps.event.addListener(marker, "dragend", function() {
      const position = marker.getPosition();
      setPinCoordinates({
        lat: position.lat(),
        lng: position.lng(),
      });
    });
  
    // Initial set of coordinates (center of the map)
    setPinCoordinates(defaultProps.center);  

    renderMarkersAndPolygons(map, maps, geoJsonData);
  };
  return (
    <div>
      <div style={{ height: height, width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs", // Replace with your API key
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded} // Make sure this is called

    
        />
      </div>
    </div>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  dashboardTabs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  scrollableListContainer: {
    maxHeight: "97vh", // Adjust the height as necessary
    overflowY: "auto", // Enable vertical scrolling
  },

  tabButton: {
    border: "none",
    fontSize: "20px",
    margin: "0 auto",
    display: "block",
    padding: "5px 30px",
    color: "white", // Text color
    backgroundColor: "black", // Background color
    //borderRadius: '25px', // Make it round
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
  },
};

export default MapComponent;