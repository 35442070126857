import React from 'react';
import './Dashboard.css';

const Dashboard: React.FC = () => {
  // Fictional data for the number of orders over the last week
  const ordersData = [5, 8, 12, 15, 10, 7, 9, 12, 14, 9, 11, 17, 10, 11, 13, 14, 14, 19];

  // Calculate the maximum value for scaling the graph
  const maxOrders = Math.max(...ordersData);

  // Create bars for the graph based on the data
  const bars = ordersData.map((orders, index) => (
    <div
      key={index}
      className="bar"
      style={{ height: `${(orders / maxOrders) * 100}%` }}
    >
      {orders}
    </div>
  ));

  const zipCodeData = [
    { zipCode: '92618', orders: 25 },
    { zipCode: '92801', orders: 32 },
    { zipCode: '92701', orders: 15 },
    { zipCode: '92647', orders: 20 },
    { zipCode: '92620', orders: 18 },
    { zipCode: '92602', orders: 12 },
    { zipCode: '92603', orders: 30 },
    { zipCode: '92604', orders: 22 },
    { zipCode: '92606', orders: 28 },
    { zipCode: '92612', orders: 40 },
    { zipCode: '92705', orders: 36 },
    { zipCode: '92780', orders: 45 },
    { zipCode: '92626', orders: 24 },


  
  ];

  // Sort the data by order counts in descending order
  zipCodeData.sort((a, b) => b.orders - a.orders);

  // Calculate the maximum order count for scaling the graph
  const maxZipCodeOrders = zipCodeData[0].orders;

// Create bars for the zip code graph based on the data
const zipCodeBars = zipCodeData.map((item, index) => (
  <div key={index} className="zipcode-bar-container">
    <div className="zipcode-text">{item.zipCode}</div>
    <div
      className="zipcode-bar"
      style={{ width: `${(item.orders / maxZipCodeOrders) * 130}px` }}
    ></div>
  </div>
));


  return (
    <div className="outer-container">
      <div className="dashboard-container">
        <div className="top-rectangle">
          <div className="square yellow">
            <div style={{marginLeft:20}}>Order Completion Rate</div>
            <div className="circle">
              <div className="number">75%</div>
            </div>
          </div>
          <div className="square purple">
          <div style={{marginLeft:20}}>Order Completion Rate</div>
            <div className="circle">
              <div className="number">90%</div>
            </div>
          </div>
          <div className="square pink">
          <div style={{marginLeft:20}}>Order Completion Rate</div>
            <div className="circle">
              <div className="number">25%</div>
            </div>
          </div>
        </div>

        <div className="main-grid">
          <div className="center-content">
            <div className="graph">{bars}</div>
          </div>
          <div className="right-rectangle">
            <div className="zip-code-graph">
              {zipCodeBars}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
