import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers"; // Assuming this is where your Redux state is
import { SERVER_URL } from "../Functions";
import TitleBar from "./TitleBar";
import SavedCards from "./SavedCards";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoadingSpinner from "../LoadingSpinner";

const RateAgreement = () => {
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [isCardSelectionVisible, setIsCardSelectionVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const providerDetails = useSelector(
    (state: RootState) => state.providerProfile.details
  );


  const zoneData = useSelector((state: RootState) => state.zoneData); // Assuming zoneData contains both zones and regions

  const loading = useSelector((state: RootState) => state.providerProfile.loading);

  const [providerUid, setProviderUid] = useState<string | null>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setProviderUid(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const [responseMessage, setResponseMessage] = useState<string | null>(null);

  useEffect(() => {
    // Check if provider is subscribed
    if (providerDetails?.subscription) {
      setIsSubscribed(true);
    }
  }, [providerDetails]);

  const handleCreateCustomerProfile = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${SERVER_URL}/create-authorizenet-customer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: providerDetails.email,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setResponseMessage(
          `Customer Profile Created: ${result.customerProfileId}`
        );
      } else {
        setResponseMessage(`Error: ${result}`);
      }
    } catch (error) {
      //@ts-ignore
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = async () => {
    if (!selectedCard) {
      window.alert("Please select a card.");
      return;
    }

    setIsLoading(true);
    try {
      const subscriptionResponse = await fetch(
        `${SERVER_URL}/create_subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            authNetId: providerDetails.authNetId,
            paymentProfileId: selectedCard.paymentProfileId,
            amount: 25.0, // Updated fixed price for provider subscription
            billingAddress: selectedCard.billingAddress,
            userUid: providerUid,
            isProvider: true, // Include the isProvider flag
          }),
        }
      );

      if (!subscriptionResponse.ok) {
        const errorText = await subscriptionResponse.text();
        throw new Error(errorText);
      }

      window.alert("You have successfully subscribed.");
      setIsSubscribed(true);
      setIsCardSelectionVisible(false);
    } catch (error) {
      console.error("Error creating subscription:", error);
      //@ts-ignore
      window.alert(`Subscription Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsubscribe = async () => {
    const isConfirmed = window.confirm("Are you sure you want to unsubscribe?");
    if (!isConfirmed) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/cancel_subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authNetId: providerDetails.authNetId,
          userUid: providerUid,
          isProvider: true, // Include the isProvider flag
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      window.alert("Your subscription has been cancelled.");
      setIsSubscribed(false);
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      //@ts-ignore
      window.alert(`Unsubscribe Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinueToCardSelection = () => {
    setIsCardSelectionVisible(true);
  };

  const getUserPriorityAndRate = (
    assignedProviders: any[],
    userUid: string | null
  ) => {
    const userProvider = assignedProviders.find(
      (provider: any) => provider.UID === userUid
    );
    const priority =
      assignedProviders.findIndex((provider: any) => provider.UID === userUid) +
      1;

    if (userProvider) {
      return { priority, rate: userProvider.rate };
    }
    return null;
  };

  const renderSubscriptionDetails = () => {
    return (
      <div style={styles.card}>
        <div style={styles.cardRow}>
          <div>
            <h2 style={styles.cardTitle}>Subscription Details</h2>
            <p style={styles.cardText}>Your current subscription is active.</p>
            <p style={styles.cardText}>Subscription Price: $25/month</p>
            <p style={styles.cardText}>Next renewal date: 11 October 2024</p>
          </div>
          <button
            onClick={handleUnsubscribe}
            disabled={isLoading}
            style={styles.button}
          >
            {isLoading ? "Unsubscribing..." : "Unsubscribe"}
          </button>
        </div>
      </div>
    );
  };
  

  const renderSubscriptionOptions = () => {
    return (
      <div style={styles.card}>
        <div style={styles.cardRow}>
          <div>
            <h2 style={styles.cardTitle}>Subscribe to Our Service</h2>
            <p style={styles.cardText}>Subscription Price: $20/month</p>
          </div>
          <button onClick={handleContinueToCardSelection} style={styles.button}>
            Subscribe
          </button>
        </div>
      </div>
    );
  };

  const renderCardSelection = () => {
    return (
      <div style={styles.card}>
        <h2 style={styles.cardTitle}>Select Payment Method</h2>
        <SavedCards
          mode="alternative"
          onCardSelect={(card: any) => setSelectedCard(card)}
        />
        <div style={styles.buttonContainer}>
          <button
            onClick={handleSubscribe}
            disabled={isLoading || !selectedCard}
            style={styles.button}
          >
            {isLoading ? "Subscribing..." : "Subscribe with Selected Card"}
          </button>
        </div>
      </div>
    );
  };

  const renderZones = (zones: any[]) => {
    return (
      <div style={styles.grid}>
        {zones.map((zone: any) => {
          const userPriorityRate = getUserPriorityAndRate(
            zone.AssignedProviders,
            providerUid
          );

          return (
            <div key={zone.id} style={styles.gridCard}>
              <h3 style={styles.cardSubtitle}>{zone.ZoneName}</h3>
              {zone.ZipCodes.length > 0 && (
                <div style={styles.cardText}>
                  <strong>Zip Codes:</strong>{" "}
                  {zone.ZipCodes.map((zip: any) => zip.zipCode).join(", ")}
                </div>
              )}
              <div style={styles.cardText}>
                <strong>Regional Factor:</strong> {zone.RegionalFactor}
              </div>
              {userPriorityRate && (
                <div style={styles.cardText}>
                  <strong>Your Priority:</strong> {userPriorityRate.priority}{" "}
                  <br />
                  <strong>Your Rate:</strong> {userPriorityRate.rate}%
                </div>
              )}
              <div style={styles.cardText}>
              <strong>Region ID:</strong> {zone.regionId}
            </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderRegions = (regions: any[], zones: any[]) => {
    return (
      <div>
        {regions.map((region: any) => (
          <div key={region.id} style={styles.regionCard}>
            <h3 style={styles.cardSubtitle}>{region.RegionName}</h3>
            <div style={styles.cardText}>
              <strong>Working Days:</strong>{" "}
              {Object.keys(region.selectedDays)
                .filter((day) => region.selectedDays[day])
                .join(", ")}
            </div>
            <div style={styles.cardText}>
              <strong>Working Hours:</strong>
              {Object.keys(region.workingHours).map((day) => (
                <div key={day}>
                  {day}: {region.workingHours[day].startTime} - {region.workingHours[day].endTime}
                </div>
              ))}
            </div>
            <h4 style={styles.zoneTitle}>Zones in this Region:</h4>
            <div style={styles.zoneGrid}>
              {zones
                .filter((zone: any) => zone.regionId === region.id)
                .map((zone: any) => {
                  const userPriorityRate = getUserPriorityAndRate(zone.AssignedProviders, providerUid);
                  return (
                    <div key={zone.id} style={styles.zoneCard}>
                      <h5 style={styles.zoneSubtitle}>{zone.ZoneName}</h5>
                      {zone.ZipCodes.length > 0 && (
                        <div style={styles.cardText}>
                          <strong>Zip Codes:</strong>{" "}
                          {zone.ZipCodes.map((zip: any) => zip.zipCode).join(", ")}
                        </div>
                      )}
                      <div style={styles.cardText}>
                        <strong>Regional Factor:</strong> {zone.RegionalFactor}
                      </div>
                      {userPriorityRate && (
                        <div style={styles.cardText}>
                          <strong>Your Priority:</strong> {userPriorityRate.priority} <br />
                          <strong>Your Rate:</strong> {userPriorityRate.rate}%
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <TitleBar title="Rate Agreement and Subscription" />
      <div style={styles.content}>
        {loading ? (
          <div style={styles.loadingContainer}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {isCardSelectionVisible
              ? renderCardSelection()
              : isSubscribed
              ? renderSubscriptionDetails()
              : renderSubscriptionOptions()}

            {!isCardSelectionVisible && (
              <>
                <h2 style={styles.sectionTitle}>Regions</h2>
                {renderRegions(zoneData.regions, zoneData.zones)}

              {/*
                <h2 style={styles.sectionTitle}>Zones</h2>
                {renderZones(zoneData.zones)}
                */}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    //backgroundColor: "#f0f0f0",
    minHeight: "100vh",
    padding: "20px",
  },
  content: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
  },
  card: {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    marginBottom: "20px",
  },
  cardRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", // Align the text and button vertically
  },

  gridCard: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "15px",
    marginBottom: "15px",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#333",
  },
  cardSubtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#444",
  },
  cardText: {
    fontSize: "14px",
    marginBottom: "8px",
    color: "#666",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "24px",
    padding: "12px 22px",
    fontSize: "20px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  outlineButton: {
    backgroundColor: "transparent",
    color: "#007bff",
    border: "1px solid #007bff",
    borderRadius: "4px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "all 0.3s",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "20px",
  },

  regionCard: {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    marginBottom: "20px",
  },
  zoneTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "15px",
    marginBottom: "10px",
    color: "#333",
  },
  zoneGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: "15px",
  },
  zoneCard: {
    backgroundColor: "white",
    borderRadius: "6px",
    padding: "10px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  },
  zoneSubtitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "8px",
    color: "#444",
  },
};

export default RateAgreement;
