//@ts-nocheck
import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { DocumentData, collection, documentId, getDocs, getFirestore, query, where } from "firebase/firestore";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { useSelector } from "react-redux"
import { RootState } from "./store/reducers/index"; // Adjust the import path as necessary
import ReactDOMServer from "react-dom/server";
import { FaWarehouse } from "react-icons/fa6";

interface Provider {
  UID: string;
  rate: number;
}

interface Circle {
  center: { lng: number; lat: number };
  radius: number;
  polygon: Array<{ lng: number; lat: number }>;
}

interface Region {
  id: string;
  RegionName: string;
  ZipCodes?: Array<{
    zipCode: string;
    polygon: Array<{ lng: number; lat: number }>;
  }>;
  Circles?: Circle[];
}

interface Zone {
  id: string;
  ZoneName: string;
  RegionalFactor: number;
  regionId: string;
  Priority: number;
  AssignedProviders: Provider[];
  ZipCodes: Array<{
    zipCode: string;
    polygon: Array<{ lng: number; lat: number }>;
  }>;
  Circles: Circle[];
}

interface UserAssignments {
  zones: Zone[];
  regions: Region[];
}

const FloatingInfo = ({ title, items }: { title: string; items: string[] }) => (
  <div
    style={{
      margin: "10px",
      padding: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      width: "250px",
      zIndex: 1,
    }}
  >
    <h3>{title}</h3>
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const Dashboard = () => {
  const defaultProps = {
    center: {
      lat: 33.7175,
      lng: -117.8311,
    },
    zoom: 9,
  };

  const userData = useSelector((state: RootState) => state.zoneData); // Assuming zoneData contains both zones and regions
  const [mapInstance, setMapInstance] = useState<any>(null);
  const [mapsInstance, setMapsInstance] = useState<any>(null);


  
  const providerDetails = useSelector((state: RootState) => state.provider?.details);

   // Log orders from Redux whenever they change
   useEffect(() => {
    //console.log('Component Rendered, Current PROVIDER:', providerDetails.locationCoordinates);
  });


  const renderProviderLocation = (locationCoordinates: { lat: number; lng: number }) => {
    if (!mapsInstance) {
      return;
    }
  
    // Create a custom marker element
    const markerIcon = {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
        ReactDOMServer.renderToString(<FaWarehouse color="#007ae4" size={24} />)
      )}`,
      scaledSize: new mapsInstance.Size(14, 14),
      anchor: new mapsInstance.Point(7, 7), // Center the anchor point
    };
  
    // Add the marker to the map
    const marker = new mapsInstance.Marker({
      position: locationCoordinates,
      map: mapInstance,
      title: "Provider Location",
      icon: markerIcon,
    });
  
    // Add a circle around the marker
    new mapsInstance.Circle({
      strokeColor: "#007ae4",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#007ae4",
      fillOpacity: 0.35,
      map: mapInstance,
      center: locationCoordinates,
      radius: 3000, // Set the radius to 200 meters
    });
  };
  
  useEffect(() => {
    if (providerDetails && providerDetails.locationCoordinates && mapsInstance) {
      renderProviderLocation(providerDetails.locationCoordinates);
    }
  }, [providerDetails, mapInstance, mapsInstance]);
  
  
  



  /*
  useEffect(() => {
    const fetchData = async (uid: string) => {
      const db = getFirestore();
      try {
        const zonesSnapshot = await getDocs(collection(db, "Zones"));
        const userAssignments: UserAssignments = {
          zones: [],
          regions: [],
        };
    
        let regionIds = new Set(); // Collecting regionIds from assigned zones
    
        zonesSnapshot.forEach((doc) => {
          const zoneData = doc.data() as DocumentData & Zone;
          if (
            zoneData.AssignedProviders &&
            zoneData.AssignedProviders.some(provider => provider.UID === uid)
          ) {
            userAssignments.zones.push({...zoneData, id: doc.id});
            regionIds.add(zoneData.regionId);
          }
        });
    
        if (regionIds.size > 0) {
          const regionQuerySnapshot = await getDocs(query(collection(db, "Regions"), where(documentId(), "in", Array.from(regionIds))));
          regionQuerySnapshot.forEach(doc => {
            const regionData = doc.data() as DocumentData & Region;
            userAssignments.regions.push({...regionData, id: doc.id});
          });
        }
    
        setUserData(userAssignments);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    
    const auth = getAuth();
    onAuthStateChanged(auth, (user: User | null) => {
      if (user) {
        fetchData(user.uid);
      } else {
        console.error("No user is signed in.");
      }
    });
  }, []);
*/


  const calculateCenter = (points: { lat: number; lng: number }[]) => {
    const bounds = new mapsInstance.LatLngBounds();
    points.forEach((point) => {
      bounds.extend(point);
    });
    const center = bounds.getCenter();
    return { lat: center.lat(), lng: center.lng() };
  };

  const renderPolygons = () => {
    if (mapInstance && mapsInstance && userData) {
      //console.log("Rendering polygons with data:", userData); // Debugging log
  
      // Clear existing polygons
      mapInstance.data.forEach((feature: any) => {
        mapInstance.data.remove(feature);
      });
  
      // Add polygons and circles for each region
      userData.regions.forEach((region) => {
        const allPoints: { lat: number; lng: number }[] = [];
  
        if (region.ZipCodes) {
          region.ZipCodes.forEach((zipCode) => {
            const polygon = new mapsInstance.Polygon({
              paths: zipCode.polygon.map(coord => ({ lat: coord.lat, lng: coord.lng })),
              strokeColor: "#2596be",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#7fe0fe",
              fillOpacity: 0.3,
            });
            polygon.setMap(mapInstance);
  
            allPoints.push(...zipCode.polygon.map(coord => ({ lat: coord.lat, lng: coord.lng })));
          });
        }
        if (region.Circles) {
          region.Circles.forEach((circle) => {
            const circlePolygon = new mapsInstance.Circle({
              strokeColor: "#2596be",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#7fe0fe",
              fillOpacity: 0.3,
              center: circle.center,
              radius: circle.radius,
            });
            circlePolygon.setMap(mapInstance);
  
            allPoints.push(circle.center);
          });
        }
  
        if (allPoints.length > 0) {
          const center = calculateCenter(allPoints);
          const infoWindow = new mapsInstance.InfoWindow({
            content: `<div style="color: #7fe0fe; font-family: 'Roboto-Medium'; font-size: 150%;">${region.RegionName}</div>`,
            position: center,
          });
          infoWindow.open(mapInstance);
        }
      });
  
      // Add polygons and circles for each zone
      userData.zones.forEach((zone) => {
        const allPoints: { lat: number; lng: number }[] = [];
  
        if (zone.ZipCodes) {
          zone.ZipCodes.forEach((zipCode) => {
            const polygon = new mapsInstance.Polygon({
              paths: zipCode.polygon.map(coord => ({ lat: coord.lat, lng: coord.lng })),
              strokeColor: "#2596be",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "green",
              fillOpacity: 0.2,
            });
            polygon.setMap(mapInstance);
  
            allPoints.push(...zipCode.polygon.map(coord => ({ lat: coord.lat, lng: coord.lng })));
          });
        }
        if (zone.Circles) {
          zone.Circles.forEach((circle) => {
            const circlePolygon = new mapsInstance.Circle({
              strokeColor: "#2596be",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "green",
              fillOpacity: 0.2,
              center: circle.center,
              radius: circle.radius,
            });
            circlePolygon.setMap(mapInstance);
  
            allPoints.push(circle.center);
          });
        }
  
        if (allPoints.length > 0) {
          const center = calculateCenter(allPoints);
          const infoWindow = new mapsInstance.InfoWindow({
            content: `<div style="color: green; font-family: 'Roboto-Medium';">${zone.ZoneName}</div>`,
            position: center,
          });
          infoWindow.open(mapInstance);
        }
      });
    }
  };

  useEffect(() => {
    renderPolygons();
  }, [mapInstance, mapsInstance, userData]);

  const handleApiLoaded = ({ map, maps }: { map: any; maps: any }) => {
    console.log("Google Maps API loaded"); // Debugging log
    setMapInstance(map);
    setMapsInstance(maps);
  };

  return (
    <div>
      <div
        style={{
          height: "100vh",
          width: "auto",
          position: "relative",
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs", // Replace with your API key
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded} // Make sure this is called
        />
   {userData && (
  <div style={{ 
    position: "absolute", 
    top: "50%", 
    transform: "translateY(-50%)", 
    left: 0,
    display: "flex", 
    flexDirection: "column" 
  }}>
    <FloatingInfo
      title="Regions"
      items={userData.regions.map((region) => region.RegionName)}
    />
    <FloatingInfo
      title="Zones"
      items={userData.zones.map((zone) => zone.ZoneName)}
    />
  </div>
)}
      </div>

    </div>
  );
};

export default Dashboard;
