import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { FETCH_CUSTOMERS, ADD_CUSTOMERS, UPDATE_CUSTOMER, SET_LOADING_CUSTOMERS } from './types';
import { db } from './../../../App';
import { collection, doc, getDoc } from 'firebase/firestore';
import { RootState } from '../reducers';
import { Customer } from './types'; // Import the Customer type
import { onSnapshot } from 'firebase/firestore';

export const fetchCustomers = (userIds: string[]): ThunkAction<void, RootState, unknown, AnyAction> => 
  async (dispatch, getState) => {
    dispatch({ type: SET_LOADING_CUSTOMERS , payload: true });

    const state = getState();
    const existingCustomerIds = state.customers.customers.map((customer: Customer) => customer.uid);
    const userIdsToFetch = userIds.filter(uid => !existingCustomerIds.includes(uid));

    //console.log("User IDs to Fetch:", userIdsToFetch); // Log the user IDs to fetch

    if (userIdsToFetch.length > 0) {
      const q = userIdsToFetch.map(uid => doc(db, 'Users', uid));
      const querySnapshot = await Promise.all(q.map(getDoc));

      //console.log("Query Snapshot Size:", querySnapshot.length); // Log the query snapshot size

      const customers: Customer[] = [];
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document Data:", docSnap.data()); // Log document data
          const data = docSnap.data();
          const customer: Customer = {
            uid: docSnap.id,
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress,
            phoneNumber: data.phoneNumber,
            profileImage: data.profileImage,
            dateOfBirth: data.dateOfBirth,
            createdAt: data.createdAt,
            gender: data.gender,
            stripeCustomerId: data.stripeCustomerId,
            verified: data.verified,
            addresses: data.addresses,
            vehicles: data.vehicles
          };
          customers.push(customer);
          //console.log("Fetched Customer:", customer); // Log fetched customer
        }
      });
      dispatch({ type: ADD_CUSTOMERS, payload: customers });

      // Set up real-time updates
      userIdsToFetch.forEach(uid => {
        const userDoc = doc(db, 'Users', uid);
        onSnapshot(userDoc, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            const updatedCustomer: Customer = {
              uid: doc.id,
              firstName: data.firstName,
              lastName: data.lastName,
              emailAddress: data.emailAddress,
              phoneNumber: data.phoneNumber,
              profileImage: data.profileImage,
              dateOfBirth: data.dateOfBirth,
              createdAt: data.createdAt,
              gender: data.gender,
              stripeCustomerId: data.stripeCustomerId,
              verified: data.verified,
              addresses: data.addresses,
              vehicles: data.vehicles
            };
            dispatch({ type: UPDATE_CUSTOMER, payload: updatedCustomer });
          }
        });
      });
    }

    dispatch({ type: SET_LOADING_CUSTOMERS , payload: false });
  };
