import React from "react";
import { BiGridAlt, BiListUl } from "react-icons/bi"; // Import icons for toggle

interface TitleBarProps {
  title: string;
  displayMode?: string; // Make displayMode optional
  setDisplayMode?: React.Dispatch<React.SetStateAction<string>>; // Make setDisplayMode optional
}

const TitleBar: React.FC<TitleBarProps> = ({
  title,
  displayMode,
  setDisplayMode,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // Ensures the title and button are on opposite ends
        color: "black",
        fontFamily: "Poppins",
        marginBottom: 10,
        paddingLeft: 20,
        paddingRight: 40, // Added padding right for layout balance
        //borderRadius: 10,
        //backgroundColor: 'white',
      }}
    >
      <div
        style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
      >
        <h1 style={{ fontSize: 35 }}>{title}</h1>
        <span className="live-text">LIVE</span>
      </div>
      {displayMode && setDisplayMode && (
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() =>
            setDisplayMode(displayMode === "list" ? "grid" : "list")
          }
        >
          {displayMode === "list" ? (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontSize: 15, paddingRight:10 }}>Grid View</h1>
              <BiGridAlt size={24} />
            </div>
          ) : (
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontSize: 15,paddingRight:10 }}>List View</h1>
              <BiListUl size={24} />
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default TitleBar;
