// src/store/notificationsReducer.js
const initialState = {
  notifications: [],
};

function notificationsReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
}

export default notificationsReducer;

// Action creators
export const setNotifications = (notifications: any) => ({
  type: 'SET_NOTIFICATIONS',
  payload: notifications,
});
