// components/TopBar.tsx
import React, { useState } from "react";
import { getAuth } from "firebase/auth"; // Make sure to adjust the path
import { CSSProperties } from "react"; // Import CSSProperties
import { FaAngleDown } from "react-icons/fa";

const TopBar: React.FC = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <div style={styles.topBar}>
      {/*<div style={styles.logo}>   
        <img
          style={{ width: "80px", height: "auto" }} // Adjust the width and height here
          src="https://dac.successpot.tech/application/views/front/img/dac2.png"
          alt="Logo"
        /> 
      </div>*/}

      <div style={styles.logo}>RINZ</div>

      <div onClick={toggleMenu} style={styles.userInfo}>
        <span style={styles.userEmail}>{currentUser?.email}</span>

        <FaAngleDown style={{ color: "#7B7B7B" }} />
      </div>
      {menuOpen && (
        <div style={styles.dropdown}>
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>
  );
};

const styles: {
  topBar: CSSProperties; // Apply CSSProperties type
  userInfo: CSSProperties; // Apply CSSProperties type
  dropdownIcon: CSSProperties; // Apply CSSProperties type
  dropdown: CSSProperties; // Apply CSSProperties type
  icon: CSSProperties; // Add icon to the styles object
  logo: CSSProperties; // Add logo to the styles object
  userEmail: CSSProperties; // Add logo to the styles object
} = {
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    //borderBottom: '1px solid #ccc',
    //backgroundColor:'#7fe0fe',
    background: "linear-gradient(to right,  white, #E8e8e8)", // Linear gradient from left to right
  },

  logo: {
    fontWeight: "bold", // Make the text bold
    fontSize: "40px", // Apply the desired font size
    color: "#7DC1FF",
  },
  icon: {
    fontSize: 20, // Set the desired icon size
    marginRight: 5,
  },
  userEmail: {
    color: "#7B7B7B",
    fontFamily: "Roboto-Medium",
    // Add any other styling as needed
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dropdownIcon: {
    marginLeft: "5px",
    width: "20px",
    height: "20px",
  },
  dropdown: {
    position: "absolute",
    top: "50px", // Adjust as needed
    right: "20px",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "10px",
  },
};

export default TopBar;
