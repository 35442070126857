// ZipCodeSelector.tsx
import React, { useState, useEffect } from 'react';
import NewZipCodeModal from './NewZipCodeModal';

import './ZipCodeSelector.css';
import AssignZipCode from './AssignZipCode';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../App'; 
import { UserData, ZipCode } from '../store/actions/types';

interface ZipCodeSelectorProps {
    onClose: () => void;
    userData: UserData; 
    updateAssignments: (user: UserData) => void;
    closeSelector: () => void;
  }

const ZipCodeSelector: React.FC<ZipCodeSelectorProps> = ({ onClose,userData,updateAssignments,closeSelector }) => {
    const [zipCodes, setZipCodes] = useState<ZipCode[]>([]); // Specify the type here
    const [isNewZipCodeModalOpen, setIsNewZipCodeModalOpen] = useState(false);
    const [selectedZipCode, setSelectedZipCode] = useState<string | null>(null); // Track the selected ZipCode
    const [isLoadingS, setIsLoadingS] = useState(false); 
    //const [isAssigned, setIsAssigned] = useState(false); // Initialize isAssigned as false


    const fetchZipCodes = async () => {
      try {
        const zipCodesCollection = collection(db, 'ZipCodes');
        const zipCodeQuery = query(zipCodesCollection);
        const querySnapshot = await getDocs(zipCodeQuery);
  
        const fetchedZipCodes = querySnapshot.docs.map((docSnap) => {
          const data = docSnap.data();
          return {
            id: docSnap.id,
            zipCode: data.ZipCode,
            // Include any other relevant fields you need from the document
          };
        });
        //console.log(fetchedZipCodes)
        //@ts-ignore
        setZipCodes(fetchedZipCodes);
      } catch (error) {
        console.error('Error fetching zip codes:', error);
        // Handle the error appropriately
      }
    };
  
  
    useEffect(() => {

        fetchZipCodes();
      }, []);
      

      const closeSelectorAndGoBack = () => {
        closeSelector(); // Call the closeSelector callback
        // You can also add any additional logic you need before closing
      };

  const openNewZipCodeModal = () => {
    updateAssignments(userData);
    setIsNewZipCodeModalOpen(true);
  };

  const closeNewZipCodeModal = () => {
    updateAssignments(userData);
    setIsNewZipCodeModalOpen(false);
    //fetchZipCodes();
  };
  const selectZipCode = (zipCode: string) => {
    setIsLoadingS(true);
    setSelectedZipCode(zipCode);
    fetchZipCodes();
    updateAssignments(userData);
    setIsLoadingS(false);
  };

  const isOpen = true;
  return (
    <div >
      {isNewZipCodeModalOpen ? (
        // Render NewZipCodeModal when it's open
        <NewZipCodeModal updateAssignments={updateAssignments} isOpen={isNewZipCodeModalOpen} onClose={closeNewZipCodeModal} />
      ) : selectedZipCode ? ( // If a ZipCode is selected, render AssignZipCode component
        <AssignZipCode fetchZipCodes={fetchZipCodes} updateAssignments={updateAssignments} userData={userData} zipCode={selectedZipCode} onClose={() => setSelectedZipCode(null)} />
      ) : (
        // Render existing ZipCodes when NewZipCodeModal is closed
        <div className={`dispatch-modal ${isOpen ? 'open' : ''}`}>
          <div className={`modal-overlay ${isOpen ? 'open-overlay' : ''}`} onClick={onClose}></div>
          <div style={{backgroundColor:'white',minWidth:520}} className={`modal-content ${isOpen ? 'open-content' : ''}`}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
              <button   style={{ ...styles.buttonSmall, marginTop: '25px' }}  onClick={closeSelectorAndGoBack}>Back</button>
            </div>
            <div style={{textAlign:'center',padding:30}}>
          <h3 style={{marginTop:35,color:'black'}}>Existing ZipCodes</h3>
          
        <div>
          {zipCodes.map((zipCode) => {
            //@ts-ignore
            const isAssigned = userData.assignments.some(
              (assignment) => assignment.ZipCode === zipCode.zipCode
            );

            return (
              <div style={{color:'black',marginLeft:40,marginRight:40,backgroundColor:'#f5f5f5', borderRadius: '25px',padding:20,marginBottom:20}} key={zipCode.id}>
                {zipCode.zipCode}{'   '}
                {isAssigned ? (
                  <button style={{borderWidth:1,    padding: '7px 8px', // Increases padding to make the button larger
                  borderRadius: 25}} disabled>Assigned</button>
                ) : (
                  <button style={styles.buttonSmall} onClick={() => selectZipCode(zipCode.zipCode)}>Assign</button>
                )}
              </div>
            );
          })}
        </div>
        </div>
        {/*
        <div style={{textAlign:'center',paddingTop:20,paddingBottom:40}}>
            <button  style={styles.button} onClick={openNewZipCodeModal}>Add New ZipCode</button>
            </div>
        */}

          </div>
        </div>
      )}
      
    </div>
  );
};

export default ZipCodeSelector;

interface Styles {
  [key: string]: React.CSSProperties;
}
const styles: Styles = {
  button: {
    padding: '10px 20px', // Increases padding to make the button larger
    fontSize: '20px', // Increases the font size
    //margin: '10px 10px', // Adds some margin above the button
    cursor: 'pointer', // Changes the cursor on hover
    color: 'white',
    //width:100,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: 'black',
  },
  buttonSmall: {
    padding: '10px 16px', // Increases padding to make the button larger
    fontSize: '12px', // Increases the font size
    cursor: 'pointer', // Changes the cursor on hover
    marginTop:10,
    marginLeft:10,
    color: 'white',
    //width:100,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: 'black',
  },
};