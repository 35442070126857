import React from "react";
import { activeColor, navy } from "../../styles";
import { BiSort } from "react-icons/bi";

interface RowHeaderProps {
  titles: {
    [key: string]: string;
  };
  percentages: {
    [key: string]: string;
  };
}

const RowHeader: React.FC<RowHeaderProps> = ({ titles, percentages }) => {
  const headerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "space-between",
    backgroundColor: 'black',
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "15px",
    fontFamily: "Poppins",

    paddingLeft:10,
  };

  return (
    <div style={headerStyles}>
      {Object.entries(titles).map(([key, title]) => (
        <>
          <span key={key} style={{ flexBasis: percentages[key] }}>
            <span> {title}</span>
            
            <BiSort style={{marginLeft:5,color:'white'}}/>
          
          </span>
        </>
      ))}
    </div>
  );
};

export default RowHeader;
