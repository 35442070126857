import React, { useEffect, useState } from "react";
import HomeHeader from "./HomeHeader"; // Import the Header component
import Footer from "./HomeFooter"; // Import the Footer component

const GetApp: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
    };

    window.addEventListener("resize", handleResize);

    // Call the handleResize function on mount to ensure it's set correctly
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh", // Adjust as necessary
    margin: "auto",
  };

  const rectangleStyle: React.CSSProperties = {
    width: "300px",
    height: "200px",
    backgroundColor: "#e0e0e0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "2px dashed #333", // Dashed border to indicate placeholder
  };

  const textStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
  };


  return (
    <div style={{ height: "100vh", position: "relative" }}>
    <HomeHeader isMobile={true} />
    <div style={containerStyle}>
      <div style={rectangleStyle}>
        <span style={textStyle}>Link To App Store</span>
      </div>
    </div>
    <Footer isMobile={true} />
  </div>
  );
};






export default GetApp;
