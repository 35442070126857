import React from "react";
import GoogleMapReact from "google-map-react";

interface MapComponentProps {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  onMapLoad: (map: google.maps.Map, maps: typeof google.maps) => void;
}

const MapComponent: React.FC<MapComponentProps> = ({
  center,
  zoom,
  onMapLoad,
}) => {
  const handleApiLoaded = (map: google.maps.Map, maps: typeof google.maps) => {
    onMapLoad(map, maps);
  };

  return (
      <div style={{ height:'100%', width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs", // Replace with your API key
          }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      </div>
  );
};

export default MapComponent;
