import React, { CSSProperties, useState } from "react";
import { db } from "../../App"; // Import your db instance
import { collection, addDoc } from "firebase/firestore"; // Import Firestore collection and addDoc

interface CouponModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CouponModal: React.FC<CouponModalProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");
  const [value, setValue] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Create the coupon object
    const coupon = {
      Name: name,
      Code: code,
      Amount: parseFloat(amount),
      Value: parseFloat(value),
    };
    try {
      // Add a new document in collection "Coupons"
      await addDoc(collection(db, "Coupons"), coupon);
      console.log("Coupon added successfully");
      onClose(); // Close the modal
      // Optionally, reset the form fields here
      setName("");
      setCode("");
      //@ts-ignore
      setAmount(0);
      //@ts-ignore
      setValue(0);
    } catch (error) {
      console.error("Error adding coupon: ", error);
    }
  };

  // Modal and form code remains the same

  // Do not render if not open
  if (!isOpen) return null;

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h2>Add Coupon</h2>
        <form onSubmit={handleSubmit}>
          <div style={modalStyles.formGroup}>
            <label htmlFor="couponName">Name:</label>
            <input
              id="couponName"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={modalStyles.input}
              required
            />
          </div>
          <div style={modalStyles.formGroup}>
            <label htmlFor="couponCode">Code:</label>
            <input
              id="couponCode"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              style={modalStyles.input}
              required
            />
          </div>
          <div style={modalStyles.formGroup}>
            <label htmlFor="couponAmount">Amount:</label>
            <input
              id="couponAmount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={modalStyles.input}
              required
            />
          </div>
          <div style={modalStyles.formGroup}>
            <label htmlFor="couponValue">Value:</label>
            <input
              id="couponValue"
              type="number"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={modalStyles.input}
              required
            />
          </div>
          <div style={modalStyles.actions}>
            <button type="submit" style={modalStyles.button}>
              Add
            </button>
            <button type="button" onClick={onClose} style={modalStyles.button}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const modalStyles: { [key: string]: CSSProperties } = {
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1050,
  },
  container: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "8px",
    margin: "5px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  button: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

// Apply specific styles to buttons if needed
modalStyles.button = {
  ...modalStyles.button,
  backgroundColor: "#007bff",
  color: "white",
};

export default CouponModal;
