//when i hover over a tab when its collapsed make it show tag of text

import React, { useState } from "react";
import {
  RiMoneyDollarCircleFill,
  RiShoppingBasketFill,
  RiFileList3Fill,
  RiServiceFill,
  RiGroupFill,
  RiBroadcastFill,
  RiTeamFill,
} from "react-icons/ri";
import { BsFillGridFill } from "react-icons/bs";
import { getAuth } from "firebase/auth"; // Make sure to adjust the path
import logoutSVG from "../../assets/logout.svg"; // Adjust the path to where your SVG is saved
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaTruckField } from "react-icons/fa6";

interface SidebarProps {
  onTabSelect: (tabName: string) => void;
  selectedTab: string;
}

function Sidebar({ onTabSelect, selectedTab }: SidebarProps) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const auth = getAuth();

  const iconSize = 25;
  const tabs = [
    { icon: <RiShoppingBasketFill size={iconSize} />, label: "Orders" },
    { icon: <FaTruckField size={iconSize} />, label: "Dispatch" },
    { icon: <FaMapMarkedAlt size={iconSize} />, label: "Map" },
    { icon: <RiMoneyDollarCircleFill size={iconSize} />, label: "Pricing" },
    //{ icon: <RiFileList3Fill size={iconSize} />, label: "Assignments" },
    { icon: <RiServiceFill size={iconSize} />, label: "Providers" },
    { icon: <RiGroupFill size={iconSize} />, label: "Customers" },
  
    { icon: <RiTeamFill size={iconSize} />, label: "Marketing" },

    /*
    { icon: <RiDashboard3Line size={iconSize} />, label: "Dashbourd" },
    { icon: <HiOutlineDocumentReport size={iconSize}/>, label: "Reports" },
    { icon: <IoAnalyticsOutline size={iconSize}/>, label: "Anaylytics" },
    { icon: <BiSupport size={iconSize} />, label: "Support" },

      {
      icon: <RiBroadcastFill size={iconSize} />,
      label: "Prospects Registry",
    },
    */
  ];

  const sidebarStyles: React.CSSProperties = {
    //backgroundColor: "white",
    boxShadow: '10px 0 30px -2px rgba(0,0.5,0.3,0.1)', // This will


    width: sidebarCollapsed ? "57px" : "17%",
    minWidth: sidebarCollapsed ? "57px" : "250px",
    transition: "width 0.3s ease",
    display: "flex", // Add this line to enable flexbox
    flexDirection: "column", // Add this line to stack elements vertically
    height: "100vh", // Add this line to make the sidebar take up the full viewport height
  };

  const toggleButtonContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-end", // Align the button to the right
    paddingRight: "6px", // Add some spacing to the right
  };
  const logoutContainer: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 45, // Adjust the bottom margin as needed
  };

  const handleLogout = async () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");

    if (confirmLogout) {
      try {
        await auth.signOut();
      } catch (error) {
        console.error("Logout error:", error);
      }
    }
  };

  return (
    <div style={sidebarStyles}>
      <div style={toggleButtonContainerStyles}>
        {/*
        <button
          style={toggleButtonStyles}
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        >
          {sidebarCollapsed ? (
            <BsArrowBarRight color="black" />
          ) : (
            <BsArrowBarLeft color="black" />
          )}
        </button>
        */}
      </div>
      <div style={sidebarCollapsed ? { ...logo, display: "none" } : logo}>
        <div
          style={{
            fontSize: 35,
            marginTop: "15%",
            marginBottom: "10%",
            fontFamily: "Poppins",
          }}
        >
          RINZ
        </div>
      </div>

      <ul style={tabsContainer}>
        {!sidebarCollapsed && (
          <li
            style={{
              ...listItemStyles,
              color: "black",
              backgroundColor: "white",
              marginBottom: 10,
              cursor: "default", // Prevent the icon from being clickable
              pointerEvents: "none", // Disables click events on this element
            }}
          >
            <BsFillGridFill
              size={iconSize}
              style={{ margin: "12px", marginLeft: "20px" }}
            />
            <span style={dashboardText}>Dashboard</span>
          </li>
        )}
        {tabs.map((tab, index) => (
          <li
            key={index}
            style={{
              ...listItemStyles,
              color: tab.label === selectedTab ? "white" : "black",
              backgroundColor: tab.label === selectedTab ? "black" : "#f5f5f5",
              marginBottom: tab.label === "Marketing" ? "25px" : "20px",
              flexGrow: 1, // Add this line to make the tabs equally take up the remaining height
            }}
            onClick={() => onTabSelect(tab.label)}
          >
            <div
              style={{
                ...iconCircle,
                color: tab.label === selectedTab ? "white" : "#c4c4c4",
              }}
            >
              {tab.icon}
            </div>
            <span
              style={{
                fontFamily:
                  tab.label === selectedTab ? "PoppinsSemi" : "PoppinsMedium",
              }}
            >
              {tab.label}
            </span>
          </li>
        ))}
      </ul>

      <div
        style={
          sidebarCollapsed
            ? { ...logoutContainer, display: "none" }
            : logoutContainer
        }
      >
        <button style={logout} onClick={handleLogout}>
          <img
            src={logoutSVG}
            alt="Logout"
            style={{ height: "120%", width: "auto" }}
          />
        </button>
      </div>
    </div>
  );
}

const dashboardTextContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  marginTop: 20,
  marginBottom: 20,
  width: "100%",
};

const dashboardIcon: React.CSSProperties = {
  fontSize: 24,
  position: "absolute",
  left: "30%",
  transform: "translateX(-100%)",
};

const dashboardText: React.CSSProperties = {
  fontSize: 18,
  fontFamily:'Poppins',
  fontWeight: "bold",
  color: "black",
};

const toggleButtonStyles: React.CSSProperties = {
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  display: "flex",
  fontSize: "22px",
  color: "white",
  //justifyContent: 'flex-end',
  //alignItems: 'center',
  padding: "8px",
  marginBottom: "10px",
};

const logout: React.CSSProperties = {
  cursor: "pointer", // Changes the cursor on hover
  border: "0px", // Sets your custom border
  backgroundColor: "white",
};

const logo: React.CSSProperties = {
  fontWeight: "bold",
  fontSize: "38px",
  color: "black",
  textAlign: "center",
  marginTop: 20, // Adjust the top margin as needed
  marginBottom: 20,
};

const tabsContainer: React.CSSProperties = {
  listStyle: "none",
  padding: 0,
  margin: 0,
  //backgroundColor:'white',
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexGrow: 1, // Add this line to make the tabs container take up the remaining height
  overflow: "auto", // Add this line to enable scrolling if the tabs exceed the container height
};

const listItemStyles: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  color: "white",
  borderRadius: 23,
  marginLeft: 6,
  //marginRight:4,
  cursor: "pointer", // Add this line
  width: "75%", // Add this line to make the tab take up the entire horizontal space
};

const iconCircle: React.CSSProperties = {
  //backgroundColor: "silver",
  //borderRadius: "50%",
  minWidth: "30px",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "12px",
  marginLeft: "20px",
  cursor: "pointer", // Add this line
};

export default Sidebar;
