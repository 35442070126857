import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "./Authentication.css?v=1";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import VerifyProviderModal from "./VerifyProviderModal";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../App";
import TitleBar from ".././TitleBar";
import RowHeader from "./RowHeaderNew";
import CustomerRow from "./CustomerRow";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { SERVER_URL } from ".././Functions";
import GradientContainer from "./GradientContainer"; // Import the new component
import CustomersGrid from "./CustomerGrid";
import { UserData } from "../store/actions/types";

//loading is scuffed when modal closes and it refreshes

const Customers: React.FC = () => {
  const auth = getAuth();

  const customers = useSelector(
    (state: RootState) => state.customers.customers
  );

  
  console.log('here '+customers)
  

  const [displayMode, setDisplayMode] = useState("list"); // 'list' or 'grid'

  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<UserData[]>([]); // State for storing fetched users

  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [selectedUid, setSelectedUid] = useState<string | null>(null); // Track the selected UID

  const [deletingStatus, setDeletingStatus] = useState<{
    [uid: string]: boolean;
  }>({});

  useEffect(() => {
    // Fetch user data when the component mounts
    //fetchUsers();
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Listen for changes in the Providers collection
    const unsubscribe = onSnapshot(
      collection(db, "Users"),
      (snapshot: any) => {
        // Check if there are changes
        if (!snapshot.empty) {
          fetchCustomers(); // Fetch updated users if there's a change
        }
      }
    );

    // Cleanup listener when component unmounts
    return () => unsubscribe();
  }, []);

  const openModal = (userData: UserData) => {
    setSelectedUid(userData.uid); // Set the selected UID when the button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = async () => {
    setIsModalOpen(false);
    await fetchCustomers(); // Fetch the data again to refresh
  };

  const deleteCustomer = async (uid: any) => {
    setDeletingStatus((prev) => ({ ...prev, [uid]: true })); // Set loading state for this UID

    try {
      // Send a DELETE request to your server API endpoint for customers
      const response = await fetch(`${SERVER_URL}/api/delete-customer/${uid}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response from the server
      const result = await response.json();

      console.log(result.message); // Handle the success message

      // Refresh the customer list after deletion
      await fetchCustomers();
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
    setDeletingStatus((prev) => ({ ...prev, [uid]: false })); // Unset loading state for this UID
  };

  const fetchCustomers = async () => {
    //console.log("test");
    setIsLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/api/customers`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setUsers(data.customers); // Update the state with the fetched customers
      //console.log(data.customers)

    } catch (error) {
      console.error("Error fetching customers:", error);
    }
    setIsLoading(false);
  };

  /*
  useEffect(() => {
    fetchCustomers();
  }, []);
    */


  /*

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      setUsers(data.unverifiedProviders); // Update the state with the fetched user data
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching user data:', error);
      throw error; // Handle the error as needed in your component
    }
  };   */

  const titles = {
    image: "",
    email: "CUSTOMER EMAIL",
    name: "FULL NAME",
    created: "MEMBER SINCE",
    details: "DETAILS",
  };

  const percentages = {
    image: "5%",
    email: "25%",
    name: "25%",
    created: "25%",
    details: "20%",
  };

  return (
    <div style={{ padding: 10 }}>
      <TitleBar
        title="Customers"
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
      />

      {displayMode === "list" ? (
        <CustomersGrid customers={customers} />
      ) : (
        <>
          <RowHeader titles={titles} percentages={percentages} />
          {customers.map((customer, index) => (
            <CustomerRow key={index} customer={customer} />
          ))}
        </>
      )}
    </div>
  );
};

const animatedGradientContainer: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 50,
  height: "100%",

  background: "linear-gradient(-45deg, #98fbc1, #7fe0fe, #98fbc1, #7fe0fe)",
  backgroundSize: "400% 400%",
  animation: "gradientAnimation 15s ease infinite",
};

const columnStyle: React.CSSProperties = {
  flex: 1,
  padding: 20,
};

const rowStyles: React.CSSProperties = {
  //borderRadius: '10px',
  padding: "40px",
  border: "1px solid #000000",
  margin: "10px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  color: "black",
  fontSize: "20px",
};

export default Customers;
