import { doc, getDoc } from "firebase/firestore";
import { db } from "../App";
import { Customer } from "./store/actions/types";

//THIS THE IP OF OUR CURRENT LINUX SERVER HOSTING THE NODE INSTANCE ON AND LISTENING ON THIS PORT
//export const SERVER_URL = 'http://164.92.198.165:3000';
export const SERVER_URL = 'https://www.dripautocare.com/server';


//SWAP TO THIS ONE FOR LOCAL SERVER
//export const SERVER_URL = "http://localhost:3000";


// Function to fetch provider document
export const fetchProvider = async (providerId: any) => {
  try {
    // Create a reference to the "providers" collection document with the given providerId
    const docRef = doc(db, "Providers", providerId);

    // Fetch the document
    const docSnap = await getDoc(docRef);

    // Check if the document exists
    if (docSnap.exists()) {
      // Include the document ID as UID in the returned object
      return {
        uid: docSnap.id,
        ...docSnap.data(),
      };
    } else {
      // Document not found
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    // Handle the error
    console.error("Error fetching document:", error);
    throw error;
  }
};

export const fetchCustomer = async (customerId: string): Promise<Customer | null> => {
  try {
    const docRef = doc(db, "Users", customerId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      // Validate that all required fields are present
      if (
        data.firstName &&
        data.lastName &&
        data.emailAddress &&
        data.phoneNumber &&
        data.profileImage &&
        data.dateOfBirth &&
        data.createdAt &&
        data.gender &&
        data.stripeCustomerId &&
        typeof data.verified === "boolean" &&
        Array.isArray(data.addresses) &&
        Array.isArray(data.vehicles)
      ) {
        return {
          uid: docSnap.id,
          firstName: data.firstName,
          lastName: data.lastName,
          emailAddress: data.emailAddress,
          phoneNumber: data.phoneNumber,
          profileImage: data.profileImage,
          dateOfBirth: data.dateOfBirth,
          createdAt: data.createdAt,
          gender: data.gender,
          stripeCustomerId: data.stripeCustomerId,
          verified: data.verified,
          addresses: data.addresses.map((address: any) => ({
            location: {
              latitude: address.location.latitude,
              longitude: address.location.longitude,
              latitudeDelta: address.location.latitudeDelta,
              longitudeDelta: address.location.longitudeDelta,
            },
            stateAddress: address.stateAddress,
            cityAddress: address.cityAddress,
            streetAddress: address.streetAddress,
            zipCodeAddress: address.zipCodeAddress,
          })),
          vehicles: data.vehicles.map((vehicle: any) => ({
            model: vehicle.model,
            color: vehicle.color,
            make: vehicle.make,
            imageSource: vehicle.imageSource,
            vinNumber: vehicle.vinNumber,
            regNumber: vehicle.regNumber,
          })),
        };
      } else {
        console.warn("Customer data is missing required fields.");
        return null;
      }
    } else {
      console.log("No such customer document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching customer document:", error);
    throw error;
  }
};

export const fetchProcessHistory = async (orderId: string) => {
  const docRef = doc(db, "ProcessHistory", orderId);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log('Process history data:', docSnap.data());
      const data = docSnap.data();
      // Assuming history is stored in an array format in the document
      const history = data.history || [];
      return history.map((entry: any) => ({
        ...entry,
        timestamp: entry.timestamp
          ? formatEpochDate(entry.timestamp)
          : "Unknown date",
      }));
    } else {
      console.log("No process history document found for:", orderId);
      return [];
    }
  } catch (error) {
    console.error("Error fetching process history:", error);
    throw error; // Or handle error appropriately
  }
};

export const formatEpochDate = (milliseconds: any) => {
  // Create a new Date object with the milliseconds value
  const date = new Date(milliseconds);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract date components
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Return the formatted date string (e.g., "Jul 11, 2023")
  return `${month} ${day}, ${year}`;
};

export const formatEpochTime = (milliseconds: any) => {
  // Create a new Date object with the milliseconds value
  const date = new Date(milliseconds);

  // Extract time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Return the formatted time string (e.g., "08:24")
  return `${hours}:${minutes}`;
};

export const convertImageToBase64 = (file:any) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No file provided");
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 500;
        canvas.height = 500;
        //@ts-ignore
        ctx.drawImage(img, 0, 0, 500, 500);
        const dataUrl = canvas.toDataURL("image/jpeg");
        const base64String = dataUrl.split(",")[1]; // Extracts base64 part of the data URL
        resolve(base64String);
      };
      img.onerror = reject;
      //@ts-ignore
      img.src = e.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const formatBase64Image = (base64: string) => {
  if (!base64.startsWith("data:image")) {
    return `data:image/jpeg;base64,${base64}`; // Assuming it's a JPEG. Change if necessary.
  }
  return base64;
};

export function formatDateFromTimestamp(timestamp: any) {
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  return date.toISOString().split("T")[0]; // Format to YYYY-MM-DD
}

export function formatDateTimeFromTimestamp(timestamp: any) {
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  return date.toISOString().replace("T", " ").split(".")[0]; // Format to YYYY-MM-DD HH:MM:SS
}

export const formatDate = (dateString: any) => {
  const date = new Date(
    dateString.replace(
      /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/,
      "$2/$1/$3 $4:$5:$6"
    )
  );
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatTime = (dateTimeString: string) => {
  const date = new Date(
    dateTimeString.replace(
      /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/,
      "$2/$1/$3 $4:$5:$6"
    )
  );
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Set to true for 12-hour format, false for 24-hour format
  });
};
