// actions/zoneActions.js
//@ts-nocheck
import { getFirestore, collection, getDocs, query, where, documentId } from 'firebase/firestore';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../reducers';
import { FETCH_ZONES, FETCH_REGIONS } from './types';

// Action to set zones data
export const fetchZonesSuccess = (zones): AnyAction => ({
  type: FETCH_ZONES,
  payload: zones,
});

// Action to set regions data
export const fetchRegionsSuccess = (regions): AnyAction => ({
  type: FETCH_REGIONS,
  payload: regions,
});

// Thunk action to fetch zones and regions
export const fetchZoneData = (uid: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  const db = getFirestore();
  try {
    // Fetch zones
    const zonesSnapshot = await getDocs(collection(db, "Zones"));
    let zones = [];
    let regionIds = new Set();

    zonesSnapshot.forEach((doc) => {
      const zoneData = doc.data();
      if (zoneData.AssignedProviders && zoneData.AssignedProviders.some(provider => provider.UID === uid)) {
        zones.push({...zoneData, id: doc.id});
        regionIds.add(zoneData.regionId);
      }
    });

    // Dispatch zones data
    dispatch(fetchZonesSuccess(zones));

    // Fetch regions if there are any region IDs collected
    if (regionIds.size > 0) {
      const regionsSnapshot = await getDocs(query(collection(db, "Regions"), where(documentId(), "in", Array.from(regionIds))));
      let regions = [];
      regionsSnapshot.forEach(doc => {
        regions.push({...doc.data(), id: doc.id});
      });

      // Dispatch regions data
      dispatch(fetchRegionsSuccess(regions));
    }
  } catch (error) {
    console.error("Error fetching zones and regions data:", error);
    // Optionally handle errors, perhaps dispatch an error action
  }
};
