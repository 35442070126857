import React, { useEffect, useState } from 'react';
import './SendNotificationModal.css';
import { doc, getDoc, deleteDoc, updateDoc, setDoc, doc as firebaseDoc } from 'firebase/firestore';
import { db } from '../../App';
import bIcon from '../../assets/not_icon.jpg'; 
import { SERVER_URL}  from ".././Functions"


import { TbBellRingingFilled } from "react-icons/tb";
import { FaApple } from "react-icons/fa";
import { MdAndroid } from "react-icons/md";


interface VerifyModalProps {
  isOpen: boolean;
  onClose: () => void;
}


const SendNotificationModal: React.FC<VerifyModalProps> = ({ isOpen, onClose,  }) => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [previewTitle, setPreviewTitle] = useState('Drip Auto Care');
  const [previewBody, setPreviewBody] = useState('50% off limited Offer');
  
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [blockSelection, setBlockSelection] = useState('all');

  const sendNotification = async () => {
    try {
      setIsSending(true);
      const response = await fetch(`${SERVER_URL}/api/sendNotifications?title=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}&recipient=${encodeURIComponent(blockSelection)}`);
      const data = await response.json();
  
      // Check if the message indicates success
      if (response.ok && data.message.includes('Notifications sent successfully')) {
        onClose(); // Close the modal on success
      } else {
        // Handle cases where no FCM tokens are found or other messages
        setError(data.message || 'Unknown error occurred.');
      }
    } catch (error) {
      console.error('Error sending notification:', error);
      setError('Failed to send notification.');
    } finally {
      setIsSending(false);
    }
  };

  
  
  const handleSendClick = () => {
    if (title.length < 4 || body.length < 4) {
      setError('Title and body must be at least 4 characters.');
      return;
    }
    setError('');
    sendNotification();
  };
  const handleBlockSelectionChange = (event:any) => {
    setBlockSelection(event.target.value);
  };
  
  const handleNextClick = () => {
    setShowForm(true);
  };
  const handlePreviewClick = () => {
    if (title.length >= 4 && body.length >= 4) {
      setPreviewTitle(title);
      setPreviewBody(body);
    } else {
      setError('Title and body must be at least 4 characters for preview.');
    }
  };
  
  return (
    <div className={`dispatch-modalM ${isOpen ? 'open' : ''}`}>
      <div className={`modal-overlayM ${isOpen ? 'open-overlay' : ''}`} onClick={onClose}></div>
      <div className={`modal-contentM ${isOpen ? 'open-content' : ''}`}>
      <h2 style={{ fontSize: '34px', marginLeft: '40px',color:'black' }}>Notification Form</h2>

      <button 
          onClick={onClose} 
          style={{ position: 'absolute',color:'black', top: '10px', right: '10px', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
        >
          X
        </button>

        {showForm ? (
      
      
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: '20px' }}>


<div 
  style={{ 
    width: '400px', // Custom width
    height: '300px', // Custom height
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Centers items horizontally
    justifyContent: 'center', // Centers items vertically
    gap: '20px' // Adds space between items
  }}
>   
<input
  type="text"
  placeholder="Title"
  value={title}
  onChange={(e) => setTitle(e.target.value)}
  style={{
    padding: '10px', // Increases the padding inside the inputs
    margin: '5px 0', // Adds some margin between the inputs
    width: '80%', // Sets the width relative to the parent container
    fontSize: '16px', // Increases the font size
    // Additional styling
  }}
/>
<input
  type="text"
  placeholder="Body"
  value={body}
  onChange={(e) => setBody(e.target.value)}
  style={{
    padding: '10px',
    margin: '5px 0',
    width: '80%',
    fontSize: '16px',
    // Additional styling
  }}
/>
<button 
  onClick={handleSendClick} disabled={isSending}
  style={{
    padding: '10px 20px', // Increases padding to make the button larger
    fontSize: '20px', // Increases the font size
    margin: '10px 0', // Adds some margin above the button
    cursor: 'pointer', // Changes the cursor on hover
    color:'white',
    borderWidth:0,
    borderRadius:25,
    backgroundColor:'black'  }}
>
  Send
</button>
{error && <p style={{ color: 'black' }}>{error}</p>}

       
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  {/* Container for text and send buttons */}
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
    flex: 1 // Takes up half the width of the parent container
  }}>
    {/* Existing inputs and send button */}
  </div>
  
  {/* Container for previews */}
  <div className="notification-preview">
<MdAndroid fontSize={40} color='black'/>
    {/* Android preview */}
<div className="android-notification">
  <div className="android-notification-header">
    <img src={bIcon} alt="App Icon"/>
    <div className="android-notification-app-name">DRIP</div>
    <span className="android-notification-dot">•</span>
    <TbBellRingingFilled className="android-notification-bell-icon"/>
  </div>
  <div className="android-notification-content">
  <div className="android-notification-title">{previewTitle}</div>
      <div className="android-notification-body">{previewBody}</div>
  </div>
</div>



    
   {/* iOS preview */}
   <FaApple fontSize={40} color='black'/>
   <div className="ios-notification">
    <img src={bIcon} alt="Notification Icon" />
    <div>
    <div className="ios-notification-title">{previewTitle}</div>
      <div className="ios-notification-body">{previewBody}</div>
    </div>
  </div>

  <button onClick={handlePreviewClick} style={{    padding: '10px 20px', // Increases padding to make the button larger
 margin:20, fontSize:20,  color:'white',
    borderWidth:0,
    borderRadius:25,
    backgroundColor:'black' }}>
    Preview
  </button>
  </div>
  
</div>



          </div>
  ) : (

    <div  style={{ 
      width: '400px', // Custom width
      height: '300px', // Custom height
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Centers items horizontally
      justifyContent: 'center', // Centers items vertically
      gap: '20px' // Adds space between items
    }}>
      <p style={{ 
    fontSize: '30px', // Set the font size for the label
    color:'black'
  }}>
    Please choose recipients:
  </p>
<select value={blockSelection} onChange={handleBlockSelectionChange}   style={{ 
      fontSize: '20px', // Larger font size
      padding: '10px 20px', // More padding for bigger appearance
      width: '250px', // Wider dropdown
      cursor: 'pointer', // Cursor style
      // Additional styling as needed
    }}>
    <option value="all">All Recipients</option>
    {/*Array.from({length: 26}, (_, i) => String.fromCharCode('A'.charCodeAt(0) + i)).map(letter => (
      <option key={letter} value={`block ${letter}`}>{`Block ${letter}`}</option>
    ))*/}
  </select>
      <button onClick={handleNextClick} style={{
    padding: '12px 25px', // Increases padding to make the button larger
    fontSize: '20px', // Increases the font size
    margin: '10px 0', // Adds some margin above the button
    cursor: 'pointer', // Changes the cursor on hover
    color:'white',
    borderWidth:0,
    borderRadius:25,
    backgroundColor:'black'
  }}>
              Next
            </button>
      </div>
    )}


      </div>
      
    </div>
  );
};

export default SendNotificationModal;
