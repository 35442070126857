import React, { useState, useEffect, useRef } from "react";
import { MdSearch, MdDateRange } from "react-icons/md"; // Import date range icon
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { CSSProperties } from 'react';
import moment from 'moment';

const FilterComponent = ({
  onFilterChange,
}: {
  onFilterChange: (filters: { account: string; dateFrom: string; dateTo: string }) => void;
}) => {
  const [account, setAccount] = useState("All Accounts");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const startDatePickerRef = useRef<HTMLDivElement>(null);
  const endDatePickerRef = useRef<HTMLDivElement>(null);

  const handleFilterChange = () => {
    const dateFrom = startDate ? moment(startDate).toISOString() : "";
    const dateTo = endDate ? moment(endDate).toISOString() : "";
    onFilterChange({ account, dateFrom, dateTo });
  };

  const handleStartDateSelect = (date: Date | undefined) => {
    setStartDate(date);
    setShowStartDatePicker(false);
  };

  const handleEndDateSelect = (date: Date | undefined) => {
    setEndDate(date);
    setShowEndDatePicker(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (startDatePickerRef.current && !startDatePickerRef.current.contains(event.target as Node)) {
      setShowStartDatePicker(false);
    }
    if (endDatePickerRef.current && !endDatePickerRef.current.contains(event.target as Node)) {
      setShowEndDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={filterStyles.container}>
      <div style={filterStyles.leftContainer}>
        <select
          value={account}
          onChange={(e) => {
            setAccount(e.target.value);
            handleFilterChange();
          }}
          style={filterStyles.select}
        >
          <option value="All Accounts">All Accounts</option>
          <option value="Account 1">Account 1</option>
          <option value="Account 2">Account 2</option>
          {/* Add more accounts as needed */}
        </select>
        <div style={filterStyles.dateInputWrapper} ref={startDatePickerRef}>
          <div style={filterStyles.dateInput} onClick={() => setShowStartDatePicker(!showStartDatePicker)}>
            <span style={{ color: startDate ? "black" : "#c3c3c3" }}>
              {startDate ? moment(startDate).format("YYYY/MM/DD") : "Date from"}
            </span>
            <MdDateRange size={20} style={filterStyles.icon} />
          </div>
          {showStartDatePicker && (
            <div style={filterStyles.datePicker}>
              <DayPicker
                mode="single"
                selected={startDate || new Date()} // Ensure a Date object is always passed
                onSelect={handleStartDateSelect}
                required // Add the required property here
              />
            </div>
          )}
        </div>
        <div style={filterStyles.dateInputWrapper} ref={endDatePickerRef}>
          <div style={filterStyles.dateInput} onClick={() => setShowEndDatePicker(!showEndDatePicker)}>
            <span style={{ color: endDate ? "black" : "#c3c3c3" }}>
              {endDate ? moment(endDate).format("YYYY/MM/DD") : "Date to"}
            </span>
            <MdDateRange size={20} style={filterStyles.icon} />
          </div>
          {showEndDatePicker && (
            <div style={filterStyles.datePicker}>
              <DayPicker
                mode="single"
                selected={endDate || new Date()} // Ensure a Date object is always passed
                onSelect={handleEndDateSelect}
                required // Add the required property here
              />
            </div>
          )}
        </div>
      </div>
      <div style={filterStyles.rightContainer}>
        <button onClick={handleFilterChange} style={filterStyles.button}>
          <MdSearch style={filterStyles.searchIcon} />
        </button>
        <div style={filterStyles.pagination}>
          <button style={filterStyles.paginationButton}>&lt;</button>
          <span style={filterStyles.pageIndicator}>1 of 1</span>
          <button style={filterStyles.paginationButton}>&gt;</button>
        </div>
      </div>
    </div>
  );
};

const commonStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  height: "40px", // Ensuring consistent height
  padding: "0 10px",
  border: "1px solid #e0e0e0",
  borderRadius: "5px",
  background: "white",
  color: "#c3c3c3", // Default color for text
};

const filterStyles: { [key: string]: CSSProperties } = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '20px',
    padding: '0 0px', 
    boxSizing: 'border-box',
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  select: {
    ...commonStyles,
    marginRight: "10px",
    fontSize: '15px',
    width:'180px'
  },
  dateInputWrapper: {
    position: "relative",
    marginRight: "10px",
  },
  dateInput: {
    ...commonStyles,
    justifyContent: "space-between",
    cursor: "pointer",
    width: '130px', // Adjust the width as needed
    textAlign: "start",
    fontSize: '15px',
  },
  icon: {
    color: "#007bff",
  },
  datePicker: {
    position: "absolute",
    top: "40px",
    zIndex: 1,
    background: "white",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "10px", // Add padding to the day picker
  },
  button: {
    ...commonStyles,
    padding: "0 5px",
    marginRight: "10px",
  },
  searchIcon: {
    color: "#007bff",
  },
  pagination: {
    ...commonStyles,
    whiteSpace: "nowrap",
  },
  paginationButton: {
    padding: "0 10px",
    border: "none",
    background: "none",
    cursor: "pointer",
    color: "#007bff",
  },
  pageIndicator: {
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    fontSize: '15px',
  },
};

export default FilterComponent;
