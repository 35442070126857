import React, { CSSProperties, useEffect, useRef, useState } from "react";
//@ts-ignore
import geoJsonData from "../../assets/CALI-O.json"; // Import the GeoJSON data
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../App";
import MapComponent from "./MapPicker";
import { FaCircle, FaMapMarkerAlt } from "react-icons/fa";
import { MdShareLocation } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";

interface AddZipCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  existingZipCodes: string[]; // Add this line
  context: "region" | "zone"; // 'context' used to determine modal behavior
  selectedRegionId?: any; 
}

interface ZipEntry {
  zipCode: string;
}

const Mode = {
  DEFAULT: "default",
  ZIPCODE: "zipcode",
  AREA: "area",
};

const AddZipCodeModal: React.FC<AddZipCodeModalProps> = ({
  isOpen,
  onClose,
  existingZipCodes, // Include this in the destructuring
  context,
  selectedRegionId,
}) => {
  const [availableZipCodes, setAvailableZipCodes] = useState<string[]>([]);
  const [zipEntries, setZipEntries] = useState<ZipEntry[]>([]);
  const [zipCodeColors, setZipCodeColors] = useState({});
  const [selectedZipCode, setSelectedZipCode] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [regionalFactor, setRegionalFactor] = useState("");

  const [mode, setMode] = useState(Mode.DEFAULT);


  const [regionName, setRegionName] = useState("");  // New variable for region name


  const handleSaveToRegions = async () => {
    if (!regionName.trim()) {
      alert("Please enter a valid region name.");
      return;
    }
  
    // Collect data for circles
    const circlesData = circleInfo.map(circle => ({
      center: { lat: circle.center.lat(), lng: circle.center.lng() },
      radius: circle.radius,
      polygon: calculateCirclePerimeter(
        { lat: circle.center.lat(), lng: circle.center.lng() },
        circle.radius,
        36 // Assuming 36 points to define the perimeter
      ),
    }));
  
    const regionData = {
      RegionName: regionName,
      Circles: circlesData, // Including circle data with region information
    };
  
    try {
      await addDoc(collection(db, "Regions"), regionData);
      console.log("Region added successfully.");
      onClose();
    } catch (e) {
      console.error("Error adding region:", e);
    }
  };
  


  const handleSwitchToZipCodeMode = () => {
    setMode(Mode.ZIPCODE);
  };

  const handleSwitchToAreaMode = () => {
    setMode(Mode.AREA);
  };

  const handleBackToDefaultMode = () => {
    setMode(Mode.DEFAULT);
  };

  useEffect(() => {
    if (isOpen) {
      const zipCodes = geoJsonData.features.reduce((acc: any, feature: any) => {
        if (feature && feature.properties && feature.properties.ZCTA5CE10) {
          acc.push(feature.properties.ZCTA5CE10);
        }
        return acc;
      }, []);

      const filteredZipCodes = zipCodes.filter(
        (zipCode: any) =>
          !existingZipCodes.includes(zipCode) &&
          !zipEntries.some((entry) => entry.zipCode === zipCode)
      );
      setAvailableZipCodes(filteredZipCodes);
    }
  }, [isOpen, existingZipCodes, zipEntries]);

  const renderMarkersAndPolygons = (map: any, maps: any, geoJsonData: any) => {
    //@ts-ignore Clear all existing features from the map data layer
    map.data.forEach((feature) => {
      map.data.remove(feature);
    });

    // Filter the GeoJSON data to include only the selected ZIP codes
    const filteredGeoJsonData = {
      ...geoJsonData,
      features: geoJsonData.features.filter(
        (feature: any) =>
          feature &&
          feature.properties &&
          feature.properties.ZCTA5CE10 &&
          zipEntries.some(
            (entry) => entry.zipCode === feature.properties.ZCTA5CE10
          )
      ),
    };

    // Load the filtered GeoJSON ZIP code areas into the map data layer
    map.data.addGeoJson(filteredGeoJsonData);

    // Apply pink styles to the ZIP code areas
    map.data.setStyle((feature: any) => ({
      strokeColor: "#2596be",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#7fe0fe",
      fillOpacity: 0.5,
    }));
  };



  const handleAddZipCode = (zipCode: any) => {
    if (!zipEntries.some((entry) => entry.zipCode === zipCode)) {
      const newColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      setZipCodeColors((prevColors) => ({
        ...prevColors,
        [zipCode]: newColor,
      }));

      setZipEntries((prevEntries) => [...prevEntries, { zipCode }]);
    }
  };

  const handleSaveToZones = async () => {
    if (
      !zoneName.trim() ||
      isNaN(Number(regionalFactor)) ||
      Number(regionalFactor) < 0 ||
      Number(regionalFactor) > 100
    ) {
      alert(
        "Please enter a valid zone name and ensure the Regional Factor is between 0 and 100."
      );
      return;
    }
  
    // Fetch only zones that belong to the selected region
    const zoneQuery = query(
      collection(db, "Zones"),
      where("regionId", "==", selectedRegionId)
    );
    
    const zonesSnapshot = await getDocs(zoneQuery);
    const zoneCount = zonesSnapshot.docs.length; // This will give the number of zones in the specific region
  
    const zoneData = {
      ZoneName: zoneName,
      regionId: selectedRegionId,
      RegionalFactor: Number(regionalFactor),
      Priority: zoneCount, // Assign the next priority index based on the count of existing zones in this region
      Circles: circleInfo.map((circle) => ({
        center: { lat: circle.center.lat(), lng: circle.center.lng() },
        radius: circle.radius,
        polygon: calculateCirclePerimeter(
          { lat: circle.center.lat(), lng: circle.center.lng() },
          circle.radius,
          36
        ),
      })),
      ZipCodes: zipEntries.map((entry) => {
        const feature = geoJsonData.features.find(
          (feature:any) => feature.properties.ZCTA5CE10 === entry.zipCode
        );
        return {
          zipCode: entry.zipCode,
          //@ts-ignore
          polygon: feature.geometry.coordinates[0].map((coord:any) => ({
            lat: coord[1],
            lng: coord[0],
          })),
        };
      }),
      AssignedProviders: [],
    };
  
    try {
      await addDoc(collection(db, "Zones"), zoneData);
      console.log("Zone added successfully with priority " + zoneCount);
      onClose(); // Optionally close the modal on success
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  



  function calculateCirclePerimeter(
    center: any,
    radius: any,
    numberOfPoints = 36
  ) {
    const points = [];
    const slice = (2 * Math.PI) / numberOfPoints;
    for (let i = 0; i < numberOfPoints; i++) {
      const angle = slice * i;
      const latitude = center.lat + (radius * Math.cos(angle)) / 110574; // degrees per meter latitude
      const longitude =
        center.lng +
        (radius * Math.sin(angle)) /
          (111320 * Math.cos((center.lat * Math.PI) / 180)); // degrees per meter longitude
      points.push({ lat: latitude, lng: longitude });
    }
    return points;
  }

  const defaultProps = {
    center: {
      lat: 33.7175,
      lng: -117.8311,
    },
    zoom: 10,
  };

  const [circleInfo, setCircleInfo] = useState<
    { center: google.maps.LatLng; radius: number }[]
  >([]);

  const handleCircleInfoUpdate = (
    newCircleInfo: { center: google.maps.LatLng; radius: number }[]
  ) => {
    setCircleInfo(newCircleInfo);
  };

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container2}>
        <h2 style={{ fontFamily: "PoppinsSemi", fontSize: 28 }}>
          {context === "region" ? "Add New Region" : "Add New Zone"}
        </h2>

        <div style={modalStyles.container}>
          <MapComponent
            renderMarkersAndPolygons={renderMarkersAndPolygons}
            height={"75vh"}
            handleAddZipCode={handleAddZipCode} // Pass this function as a prop
            zipEntries={zipEntries}
            defaultProps={defaultProps}
            mode={mode}
            zipCodeColors={zipCodeColors}
            onBackToDefaultMode={handleBackToDefaultMode}
            onCircleInfoUpdate={handleCircleInfoUpdate}
          />

          {mode === Mode.DEFAULT && (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                //maxHeight: "80vh",

                justifyContent: "space-between",
                // height: "100%",
                paddingLeft: 15,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {context === "zone" && (
                  <button
                    onClick={handleSwitchToZipCodeMode}
                    style={{
                      ...modalStyles.closeButton,
                      marginBottom: "10px",
                    }}
                  >
                    Add ZIP Code
                  </button>
                )}
                <button
                  onClick={handleSwitchToAreaMode}
                  style={{
                    ...modalStyles.closeButton,
                    marginBottom: "20px",
                  }}
                >
                  Add Custom Area
                </button>
              </div>

              <div style={sideStyles.scrollableContent}>
                {context === "zone" && <h3>Zip Codes:</h3>}

                {zipEntries.map((entry: any, index: any) => (
                  <div key={index} style={sideStyles.container}>
                    <div style={sideStyles.icon}>
                      <MdShareLocation size={34} color="#007bff" />
                    </div>
                    <span style={sideStyles.labelSemi}>{entry.zipCode}</span>
                  </div>
                ))}
                <h3>Circle Info:</h3>
                {circleInfo.map((info: any, index: any) => (
                  <div key={index} style={sideStyles.container}>
                    <div style={sideStyles.icon}>
                      <GrMapLocation size={34} color="#28a745" />
                    </div>
                    <div style={sideStyles.details}>
                      <div style={sideStyles.labelSemi}>
                        Circle {index + 1}:
                      </div>
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{ ...sideStyles.label, marginLeft: "8px" }}
                          >
                            Radius:
                          </div>
                          <div
                            style={{ ...sideStyles.labelLight, margin: "0" }}
                          >
                            {(info.radius / 1609.34).toFixed(2)}
                          </div>
                          <div
                            style={{
                              ...sideStyles.labelLight,
                              marginLeft: "4px",
                            }}
                          >
                            {" "}
                            miles
                          </div>
                        </div>
                      </div>
                      <div style={sideStyles.label}>Center:</div>
                      <div style={sideStyles.labelLight}>
                        {info.center.lat().toFixed(4)},{" "}
                        {info.center.lng().toFixed(4)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                {context === "zone" ? (
                  <>
                    <input
                      type="text"
                      placeholder="Zone Name"
                      value={zoneName}
                      onChange={(e) => setZoneName(e.target.value)}
                      style={modalStyles.inputStyles}
                    />
                    <input
                      type="text"
                      placeholder="Regional Factor"
                      value={regionalFactor}
                      onChange={(e) => setRegionalFactor(e.target.value)}
                      style={modalStyles.inputStyles}
                    />
                    <button
                      onClick={handleSaveToZones}
                      style={{
                        ...modalStyles.closeButton,
                        marginBottom: "10px",
                      }}
                    >
                      Save Zone
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      placeholder="Region Name"
                      value={regionName}
                      onChange={(e) => setRegionName(e.target.value)}
                      style={modalStyles.inputStyles}
                    />

                    <button
                      onClick={handleSaveToRegions}
                      style={{
                        ...modalStyles.closeButton,
                        marginBottom: "10px",
                      }}
                    >
                      Save Region
                    </button>
                  </>
                )}

                <button onClick={onClose} style={modalStyles.closeButton}>
                  Close
                </button>
              </div>
            </div>
          )}

          {mode === Mode.ZIPCODE && (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                //height: "100%",
                paddingLeft: 15,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <select
                  value={selectedZipCode}
                  onChange={(e) => setSelectedZipCode(e.target.value)}
                  style={modalStyles.inputStyles}
                >
                  <option value="">Select ZIP Code</option>
                  {availableZipCodes.map((zipCode) => (
                    <option key={zipCode} value={zipCode}>
                      {zipCode}
                    </option>
                  ))}
                </select>
                <button
                  onClick={() =>
                    selectedZipCode && handleAddZipCode(selectedZipCode)
                  }
                  style={modalStyles.closeButton}
                >
                  Add ZIP Code
                </button>
              </div>
              <div style={sideStyles.scrollableContent}>
                <h3>Zip Codes:</h3>
                {zipEntries.map((entry: any, index: any) => (
                  <div key={index} style={sideStyles.container}>
                    <div style={sideStyles.icon}>
                      <MdShareLocation size={34} color="#007bff" />
                    </div>
                    <span style={sideStyles.labelSemi}>{entry.zipCode}</span>
                  </div>
                ))}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <button
                  onClick={handleBackToDefaultMode}
                  style={modalStyles.closeButton}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const sideStyles = {
  scrollableContent: {
    overflowY: "auto", // Enables scrolling on this element
    flex: 1, // Takes up only available space after accounting for other element
    //maxHeight: "20%",
    //maxHeight: maxScrollableHeight, // Sets a maximum height based on a percentage of the viewport height
  } as CSSProperties,
  container: {
    display: "flex",
    alignItems: "center",
    //maxWidth: "100px", // Match the button's max width

    marginBottom: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "14px",
    backgroundColor: "#fff",
  },
  labelSemi: {
    margin: "0 8px",
    fontFamily: "PoppinsSemi",
    color: "#000",
    fontWeight: "600",
  },
  label: {
    margin: "0 8px",
    fontFamily: "PoppinsMedium",
    color: "#000",
  },
  labelLight: {
    margin: "0 8px",
    fontFamily: "PoppinsLight",
    color: "#000",
  },
  icon: {
    marginRight: "8px",
    display: "flex",
  },

  details: {
    //flexGrow: 1,
    margin: "0 8px",
    color: "#000",
  },
};

const modalStyles = {
  inputStyles: {
    fontFamily: "Poppins",
    width: "100%",
    marginTop: 5,
    marginBottom: 15,
    padding: "10px",
    fontSize: "16px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    //maxWidth: "700px", // Match the button's max width
    boxSizing: "border-box", // Ensure padding is included in the width
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "30%", // Circular shape
    objectFit: "cover",
    paddingLeft: 20,
    paddingRight: 20,
  } as CSSProperties,
  profileIcon: {
    width: "100px", // Set both width and height to the same value for a square shape
    height: "100px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "20%", // Circular shape
    marginBottom: "10px",
    objectFit: "cover",
  } as CSSProperties,

  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
    // Add any additional styling you need for each detail row
  } as CSSProperties,

  barContainer: {
    //width: '60%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: 40,
    border: "1px solid #000000",
    borderRadius: 10,
    //backgroundColor:'pink'
  } as CSSProperties,

  closeButton: {
    fontFamily: "PoppinsLight",
    fontSize: "20px",
    color: "white",
    fontWeight: "500",
    backgroundColor: "black",
    borderRadius: "10px",
    padding: "10px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    width: 250,
  } as CSSProperties,

  background: {
    position: "fixed" as const, // Use 'as const' to narrow down the string literal type
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties
  container2: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    //maxHeight: "85vh", // This should be the maximum height it can take
    //overflowY: "hidden", // Prevents the container itself from scrolling
    zIndex: 1001,
  } as CSSProperties,
  container: {
    //backgroundColor: "white",
    display: "flex",
    //flexDirection: "row",
    //padding: "20px",
    //borderRadius: "8px",
    //boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxHeight: "75vh", // This should be the maximum height it can take
    overflowY: "hidden", // Prevents the container itself from scrolling
    zIndex: 1001,
  } as CSSProperties,

  contentStyles: {
    // Define your styles here
    marginBottom: "10px",
  } as CSSProperties,
};

export default AddZipCodeModal;
