//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import OrderRow from "./OrderRow";
//@ts-ignore
import geoJsonData from "../../assets/CALI-O.json"; // Import the GeoJSON data
import { GrMapLocation } from "react-icons/gr";

interface ZipEntry {
  zipCode: string;
}

interface MapProps {
  //todos: any[];
  //dashboardView: string;
  //handleDashboardViewChange: (view: string) => void;
  renderMarkersAndPolygons: (map: any, maps: any, geoJsonData: any) => void;
  zipEntries: ZipEntry[]; // Accept zipEntries as prop
  height?: string; // Optional prop
  setPinCoordinates?: ({ lat, lng }: { lat: number; lng: number }) => void; // Optional prop to pass the coordinates back
  defaultProps: {
    center: { lat: number; lng: number };
    zoom: number;
  };
  mode?: any;
  onBackToDefaultMode: () => void;
  onCircleInfoUpdate: (
    newCircleInfo: { center: google.maps.LatLng; radius: number }[]
  ) => void;
  handleAddZipCode?: any;
  zipCodeColors?: any;
}

const MapComponent: React.FC<MapProps> = ({
  //todos,
  //dashboardView,
  //handleDashboardViewChange,
  renderMarkersAndPolygons,
  defaultProps,
  setPinCoordinates = () => {}, // Provide a default no-op function
  height = "100vh", // Default height set to 100vh if height prop is not provided
  zipEntries,
  mode,
  onBackToDefaultMode,
  onCircleInfoUpdate,
  handleAddZipCode,
  zipCodeColors,
}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [circle, setCircle] = useState<google.maps.Circle>();
  const [circleCenter, setCircleCenter] = useState<google.maps.LatLng>();
  const [circleRadius, setCircleRadius] = useState<number>(3218.68);
  const [inputRadius, setInputRadius] = useState<number | string>("2");

  const [circles, setCircles] = useState<google.maps.Circle[]>([]);
  const [circleInfo, setCircleInfo] = useState<
    { center: google.maps.LatLng; radius: number }[]
  >([]);

  const [polygons, setPolygons] = useState([]);

  const Mode = {
    DEFAULT: "default",
    ZIPCODE: "zipcode",
    AREA: "area",
  };

  useEffect(() => {
    if (mode === Mode.ZIPCODE) {
      renderAllZipCodes(map, google.maps, geoJsonData);
    } else {
      // Remove all polygons from the map
      polygons.forEach((polygon) => polygon.setMap(null));
      setPolygons([]); // Clear the polygons state
    }

    if (mode === Mode.AREA) {
      handleRenderMarker();
    } else {
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }
      if (circle) {
        circle.setMap(null);
        setCircle(null);
      }
    }
  }, [mode, map]);

  useEffect(() => {
    if (map) {
      renderMarkersAndPolygons(map, google.maps, geoJsonData);
    }
  }, [zipEntries, map]);

  const handleApiLoaded = ({ map, maps }: any) => {
    setMap(map);

    /*
    // Create a marker at the center of the map
    const marker = new maps.Marker({
      position: defaultProps.center,
      map: map,
      draggable: true,
    });

    // Add listener for marker's dragend event
    maps.event.addListener(marker, "dragend", function () {
      const position = marker.getPosition();
      setPinCoordinates({
        lat: position.lat(),
        lng: position.lng(),
      });
    });
    */

    // Initial set of coordinates (center of the map)
    setPinCoordinates(defaultProps.center);

    renderMarkersAndPolygons(map, maps, geoJsonData);
  };

  useEffect(() => {
    renderAllZipCodes(map, google.maps, geoJsonData);
  }, [zipEntries]); 
  
  const renderAllZipCodes = (map, maps, geoJsonData) => {
    // Clear existing polygons from the map
    polygons.forEach(polygon => polygon.setMap(null));
    setPolygons([]);
  

    // Only create polygons for unselected ZIP codes
    const newPolygons = geoJsonData.features
      .filter(feature => {
        const zipCode = feature.properties.ZCTA5CE10;
        return !zipEntries.some(entry => entry.zipCode === zipCode); // Exclude already selected ZIP codes
      })
      .map((feature) => {
        const zipCode = feature.properties.ZCTA5CE10;
  
        const polygon = new maps.Polygon({
            paths: feature.geometry.coordinates[0].map((coord) => ({
                lat: coord[1],
                lng: coord[0],
            })),
            strokeColor: "#34c771",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#98fbc1", // Use a light color to indicate availability for selection
            fillOpacity: 0.5,
            map: map,
        });
  
        // Add an event listener to handle adding new ZIP codes
        polygon.addListener("click", () => {
            handleAddZipCode && handleAddZipCode(zipCode);
        });
  
        return polygon;
    });
  
    setPolygons(newPolygons);
  };
  



  /*
  const handleAddZipCode = (zipCode) => {
    if (!zipEntries.some(entry => entry.zipCode === zipCode)) {
      setZipEntries([...zipEntries, { zipCode }]);
    }
  };*/

  const handleRenderMarker = () => {
    if (!map) {
      console.error("Map is not initialized.");
      return;
    }

    if (!marker) {
      const newMarker = new google.maps.Marker({
        position: defaultProps.center,
        map: map,
        draggable: true,
      });
      setMarker(newMarker);
      setCircleCenter(newMarker.getPosition());

      const tempCircle = new google.maps.Circle({
        map,
        center: newMarker.getPosition(),
        radius: circleRadius, // Use initial radius
        strokeColor: "#34c771",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#98fbc1",
        fillOpacity: 0.5,
        clickable: false,
      });
      setCircle(tempCircle); // Save the circle instance

      newMarker.addListener("dragend", function () {
        const position = newMarker.getPosition();
        setCircleCenter(position);
        tempCircle.setCenter(position);
        setPinCoordinates({
          lat: position.lat(),
          lng: position.lng(),
        });
      });
    }
  };
  const handleDrawCircle = () => {
    if (!map || !marker)
      return console.error("Map or marker is not initialized.");
    const position = marker.getPosition();
    const numericRadius = parseFloat(inputRadius) * 1609.34; // Convert miles to meters
    if (isNaN(numericRadius)) {
      console.error("Invalid radius input");
      return;
    }
    const newCircle = new google.maps.Circle({
      map,
      center: position,
      radius: numericRadius,
      strokeColor: "#2596be",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#7fe0fe",
      fillOpacity: 0.5,
    });
    setCircles([...circles, newCircle]);
    const updatedCircleInfo = [
      ...circleInfo,
      { center: position, radius: numericRadius },
    ];
    setCircleInfo(updatedCircleInfo);
    onCircleInfoUpdate(updatedCircleInfo);
  };

  //const secondaryColor = '#98fbc1';
  //const primaryColor = '#7fe0fe';

  const handleSaveCircle = () => {
    console.log(
      "Circles saved:",
      circles.map((circle) => ({
        center: circle.getCenter(),
        radius: circle.getRadius(),
      }))
    );
  };

  const handleRadiusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputRadius(event.target.value);
    const numericRadius = parseFloat(event.target.value) * 1609.34; // Convert miles to meters
    if (!isNaN(numericRadius)) {
      setCircleRadius(numericRadius);
      if (circle) {
        circle.setRadius(numericRadius); // Update the radius of the existing circle
      }
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", fontFamily: "Poppins" }}
    >
      <div style={{ height: height, width: "100vw", maxWidth: "50vw" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs", // Replace with your API key
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        />
      </div>
      {mode === Mode.AREA && (
        <div
          style={{
            paddingLeft: 15,
            flexDirection: "column",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Area Radius (Miles)</span>

            <input
              style={inputStyles}
              type="text"
              value={inputRadius}
              onChange={handleRadiusChange}
              placeholder="Enter radius in miles"
            />

            <button style={styles.button} onClick={() => handleDrawCircle()}>
              Add Circle
            </button>
          </div>

          <div style={sideStyles.scrollableContent}>
            <h3>Circle Info:</h3>
            {circleInfo.map((info: any, index: any) => (
              <div key={index} style={sideStyles.container}>
                <div style={sideStyles.icon}>
                  <GrMapLocation size={34} color="#28a745" />
                </div>
                <div style={sideStyles.details}>
                  <div style={sideStyles.labelSemi}>Circle {index + 1}:</div>
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ ...sideStyles.label, marginLeft: "8px" }}>
                        Radius:
                      </div>
                      <div style={{ ...sideStyles.labelLight, margin: "0" }}>
                        {(info.radius / 1609.34).toFixed(2)}
                      </div>
                      <div
                        style={{ ...sideStyles.labelLight, marginLeft: "4px" }}
                      >
                        {" "}
                        miles
                      </div>
                    </div>
                  </div>
                  <div style={sideStyles.label}>Center:</div>
                  <div style={sideStyles.labelLight}>
                    {info.center.lat().toFixed(4)},{" "}
                    {info.center.lng().toFixed(4)}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button style={styles.button} onClick={onBackToDefaultMode}>
            Back
          </button>
        </div>
      )}
    </div>
  );
};

const sideStyles = {
  scrollableContent: {
    overflowY: "auto", // Enables scrolling on this element
    flex: 1, // Takes up only available space after accounting for other element
    //maxHeight: "20%",
    //maxHeight: maxScrollableHeight, // Sets a maximum height based on a percentage of the viewport height
  } as CSSProperties,

  container: {
    display: "flex",
    alignItems: "center",
    //maxWidth: "100px", // Match the button's max width

    marginBottom: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "14px",
    backgroundColor: "#fff",
  },
  labelSemi: {
    margin: "0 8px",
    fontFamily: "PoppinsSemi",
    color: "#000",
    fontWeight: "600",
  },
  label: {
    margin: "0 8px",
    fontFamily: "PoppinsMedium",
    color: "#000",
  },
  labelLight: {
    margin: "0 8px",
    fontFamily: "PoppinsLight",
    color: "#000",
  },
  icon: {
    marginRight: "8px",
    display: "flex",
  },

  details: {
    //flexGrow: 1,
    margin: "0 8px",
    color: "#000",
  },
};

const inputStyles: React.CSSProperties = {
  width: "100%",
  fontFamily: "Poppins",
  marginTop: 5,
  marginBottom: 15,
  padding: "10px",
  fontSize: "16px",
  borderRadius: "8px",
  border: "1px solid #ccc",
  maxWidth: "500px", // Match the button's max width
  boxSizing: "border-box", // Ensure padding is included in the width
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  button: {
    fontFamily: "PoppinsLight",
    fontSize: "20px",
    color: "white",
    fontWeight: "500",
    backgroundColor: "black",
    borderRadius: "10px",
    padding: "10px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    width: 250,
  } as CSSProperties,

  dashboardTabs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  scrollableListContainer: {
    maxHeight: "97vh", // Adjust the height as necessary
    overflowY: "auto", // Enable vertical scrolling
  },

  tabButton: {
    border: "none",
    fontSize: "20px",
    margin: "0 auto",
    display: "block",
    padding: "5px 30px",
    color: "white", // Text color
    backgroundColor: "black", // Background color
    //borderRadius: '25px', // Make it round
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
  },
};

export default MapComponent;
