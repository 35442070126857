import React, { useEffect, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  MotionValue,
} from "framer-motion";
import "./HomeNew.css";
import HomePin from "../Home/HomePin"; // Adjust the import path as necessary
import Footer from "./HomeFooter";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/driplogo.png";
import iphoneFrame from "../../assets/iphoneFrame.png";

import Home1 from "../../assets/home1.png";
import Home2 from "../../assets/home2.png";
import Home3 from "../../assets/home3.png";
import Home4 from "../../assets/home4.png";
import Home5 from "../../assets/home5.png";

import Home10 from "../../assets/home10.png";
import Home11 from "../../assets/home11.png";
import Home12 from "../../assets/home12.png";
import Home13 from "../../assets/home13.png";

import HandWaxing from "../../assets/handwaxing.png";
import ExteriorHand from "../../assets/exteriorhand.png";
import Services from "../../assets/services.png";
import Services2 from "../../assets/services2.png";
import Interior from "../../assets/interior.png";
import Scroll2 from "../../assets/scroll2.png";

import cleanWheel from "../../assets/cleanwheel.jpeg";
import handWashing from "../../assets/handwashing.jpg";
import sedanWash from "../../assets/sedanwash.jpg";
import truckWash from "../../assets/truckwash.jpg";
import HomeHeader from "./HomeHeader";

interface Product {
  title: string;
  link: string;
  thumbnail: string;
}

const products = [
  { title: "Product 1", link: "#", thumbnail: ExteriorHand },
  { title: "Product 2", link: "#", thumbnail: truckWash },
  { title: "Product 3", link: "#", thumbnail: Services }, // Services image

  { title: "Product 4", link: "#", thumbnail: Home11 }, // Scroll2 image

  { title: "Product 5", link: "#", thumbnail: Interior },
  {
    title: "Product 6",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+6",
  },
  {
    title: "Product 7",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+7",
  },
  {
    title: "Product 8",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+8",
  },
  {
    title: "Product 9",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+9",
  },

  { title: "Product 10", link: "#", thumbnail: Home12 },

  { title: "Product 11", link: "#", thumbnail: Services2 }, // Services2 image

  { title: "Product 12", link: "#", thumbnail: Home10 },
  { title: "Product 13", link: "#", thumbnail: sedanWash },
  { title: "Product 14", link: "#", thumbnail: Home13 },

  { title: "Product 15", link: "#", thumbnail: Scroll2 },
  {
    title: "Product 16",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+16",
  },
  { title: "Product 17", link: "#", thumbnail: Home1 },

  { title: "Product 18", link: "#", thumbnail: ExteriorHand },
  { title: "Product 19", link: "#", thumbnail: handWashing },
  { title: "Product 20", link: "#", thumbnail: HandWaxing },
  { title: "Product 21", link: "#", thumbnail: Scroll2 },
  { title: "Product 22", link: "#", thumbnail: Home5 },
  {
    title: "Product 23",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+23",
  },
  {
    title: "Product 24",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+24",
  },
  {
    title: "Product 25",
    link: "#",
    thumbnail: "https://via.placeholder.com/600?text=Product+25",
  },
];

const HomeNew: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      console.log("isMobile:", mobile); // Confirm if mobile is detected
      setIsMobile(mobile);
    };

    window.addEventListener("resize", handleResize);

    // Call the handleResize function on mount to ensure it's set correctly
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        <>
          <HomeMobile />
        </>
      ) : (
        <>
          <DesktopView />
        </>
      )}
    </div>
  );
};

interface ProductCardProps {
  product: Product;
  translate: MotionValue<number>;
  overlayOpacity: MotionValue<number>;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  translate,
  overlayOpacity,
}) => {
  return (
    <motion.div
      style={{ x: translate }}
      whileHover={{ y: -20 }}
      className="product-card"
    >
      <a href={product.link} className="product-link">
        <img
          src={product.thumbnail}
          className="product-image"
          alt={product.title}
        />
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            opacity: overlayOpacity, // Bind the opacity to the overlay
          }}
        />
      </a>
      <div className="product-overlay"></div>
      <h2 className="product-title">{product.title}</h2>
    </motion.div>
  );
};

const HappyCustomersSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle: React.CSSProperties = {
    backgroundColor: "#f7fafc", // Tailwind bg-gray-100
    padding: "40px 16px", // Tailwind py-10 px-4
  };

  const gridStyle: React.CSSProperties = {
    maxWidth: "1200px", // Setting maximum width for the grid
    margin: "0 auto", // Centering the grid
    display: "grid",
    gridTemplateColumns: windowWidth >= 768 ? "repeat(2, 1fr)" : "1fr", // Responsive grid
    gap: "40px", // Tailwind gap-10
    alignItems: "center",
  };

  const textStyle: React.CSSProperties = {
    textAlign: windowWidth >= 768 ? "left" : "center", // Responsive text alignment
  };

  const headingStyle: React.CSSProperties = {
    fontSize: "48px", // Tailwind text-5xl
    //fontWeight: "bold",
    fontFamily: "NeuePlakExtendedSemiBold",
    marginBottom: "24px", // Tailwind mb-6
    //color: "#06adee",
  };

  const paragraphStyle: React.CSSProperties = {
    fontSize: "24px", // Tailwind text-2xl
    //color: "#06adee",
    fontFamily: "NeuePlakExtendedSemiBold",
  };

  const iphoneFrameContainerStyle: React.CSSProperties = {
    position: "relative",
    width: "25rem",
    height: "25rem",
    backgroundImage: `url(${iphoneFrame})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none", // Ensure the frame itself does not capture pointer events
  };

  return (
    <div style={containerStyle}>
      <div style={gridStyle}>
        <img
          src={cleanWheel}
          alt="Happy Customers"
          style={{ width: "100%", height: "auto" }}
        />
        <div style={textStyle}>
          <h2 style={headingStyle}>Happy Customers</h2>
          <p style={paragraphStyle}>
            Drip Auto Care transformed my dirty car into a sparkling beauty. I'm
            impressed!
          </p>
        </div>
      </div>
    </div>
  );
};

const WhoWeAreSection = ({ handleSignupClick }: any) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sectionStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: windowWidth >= 768 ? "row" : "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    color: "black",
    padding: windowWidth >= 768 ? "32px" : "16px",
    maxWidth: "1500px", // Setting maximum width for the grid
    margin: "0 auto", // Centering the grid
  };

  const imageStyle: React.CSSProperties = {
    width: windowWidth >= 768 ? "33.333%" : "100%",
    height: "auto",
    marginBottom: windowWidth >= 768 ? 0 : "32px",
  };

  const contentStyle: React.CSSProperties = {
    width: windowWidth >= 768 ? "66.666%" : "100%",
    paddingLeft: windowWidth >= 768 ? "32px" : "0",
  };

  const headingStyle: React.CSSProperties = {
    fontSize: "32px", // Tailwind text-4xl
    fontWeight: "bold",
    marginBottom: "16px", // Tailwind mb-4
    color: "black",
    fontFamily: "NeuePlakExtendedSemiBold",
  };

  const paragraphStyle: React.CSSProperties = {
    fontSize: "18px", // Tailwind text-lg
    marginBottom: "32px", // Tailwind mb-8
    fontFamily: "NeuePlakExtendedSemiBold",
  };

  const buttonStyle: React.CSSProperties = {
    backgroundColor: "#06adee", // Tailwind bg-blue-500
    color: "white",
    fontWeight: "bold",
    padding: "8px 16px", // Tailwind py-2 px-4
    border: "none",
    cursor: "pointer",
    fontFamily: "NeuePlakExtendedSemiBold",
  };

  return (
    <div style={sectionStyle}>
      <img style={imageStyle} src={Home5} alt="Our Work" />
      <div style={contentStyle}>
        <h2 style={headingStyle}>Who We Are</h2>
        <p style={paragraphStyle}>
          Your car's paint is vulnerable to everyday matter like gasoline, tree
          sap, and road oils. Our team provides the gentlest yet most effective
          car washing system to remove dirt safely while protecting the finish
          and enhancing the shine. Our crews are equipped with industry-leading
          fleet washing equipment and deliver exceptional results with an
          unparalleled level of care, excellence, and convenience.
        </p>
        <button style={buttonStyle} onClick={handleSignupClick}>
          Get Drippy!
        </button>
      </div>
    </div>
  );
};

const LandingPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const landingPageStyle = {
    fontFamily: "sans-serif", // Tailwind .font-sans
  };

  const headingStyle: React.CSSProperties = {
    textAlign: "center",
    fontFamily: "NeuePlakExtendedSemiBold",
    fontSize: "32px", // Tailwind text-4xl
    fontWeight: "bold",
    padding: "56px 0", // Tailwind py-14
    //color: "#06adee",
  };

  // Grid styling to mimic Tailwind's grid and responsive column setup
  const gridStyle = {
    display: "grid",
    fontFamily: "NeuePlakExtendedSemiBold",
    gridTemplateColumns: windowWidth >= 768 ? "repeat(3, 1fr)" : "1fr",
    gap: "16px", // Tailwind gap-4
    maxWidth: "1200px", // Setting maximum width for the grid
    margin: "0 auto", // Centering the grid
  };

  return (
    <div style={landingPageStyle}>
      <h1 style={headingStyle}>Professional Mobile Detailing Reimagined</h1>
      <div style={gridStyle}>
        <Section
          title="Embrace the Drip Membership"
          content="Join the Drip revolution where regular car cleanliness meets innovation. As a leading provider of subscription-based mobile car wash and detailing services, Drip Auto Care introduces a cost-effective and superior solution. Cherish the luster of a regular clean without stepping out, as we bring the pinnacle of car care to your doorstep or workplace."
          imageSrc={Home1}
          imageAlt="Car washing"
        />
        <Section
          title="Gift the Shine"
          content="Surprise someone special with the ultimate convenience - a Drip Auto Care gift certificate. Perfect for any occasion, recipients can book a mobile wash or detail at their leisure or plan up to a week ahead, delivering a sparkling experience directly to them."
          imageSrc={Home2}
          imageAlt="Car interior detailing"
        />
        <Section
          title="Drip Auto Care revolutionizes the way you connect with top-tier mobile detailers"
          content="Our proprietary digital model connects only the top-rated mobile detailers in your area with our best-in-class tools to track and monitor prices and services, accordingly, making sure convenience is always with the customer."
          imageSrc={Home3}
          imageAlt="Professional detailing a car"
        />
      </div>
    </div>
  );
};

const Section = ({ title, content, imageSrc, imageAlt }: any) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sectionStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "start",
    padding: "16px",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
  };

  const contentStyle = {
    width: "100%",
    height: "auto",
    overflow: "hidden",
    padding: "16px", // Tailwind p-4
    backgroundColor: "white",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)", // Tailwind shadow-md
    marginTop: "16px", // Tailwind mt-4
    marginBottom: "32px", // Tailwind mb-8
  };

  const titleStyle = {
    fontSize: windowWidth < 768 ? "20px" : "24px", // Tailwind text-2xl, with responsive adjustment
    fontWeight: "600", // Tailwind font-semibold
    marginBottom: "12px", // Tailwind mb-3
    //color: "#06adee",
  };

  const textStyle = {
    fontSize: windowWidth < 768 ? "14px" : "16px", // Tailwind text-sm, with responsive adjustment
  };

  return (
    <div style={sectionStyle}>
      <img style={imageStyle} src={imageSrc} alt={imageAlt} />
      <div style={contentStyle}>
        <h3 style={titleStyle}>{title}</h3>
        <p style={textStyle}>{content}</p>
      </div>
    </div>
  );
};


const HomeMobile = ({ title, content, imageSrc, imageAlt }: any) => {
  return (
    <div style={{ height: "100vh", position: "relative" }}>
      <HomeHeader isMobile={true} />
      <div style={{ height: "70%" }}>
          <HomePin scrollBool={true} />
      </div>
      <Footer isMobile={true} />
    </div>
  );
};

const DesktopView: React.FC = () => {
  const navigate = useNavigate();

  const handleSignupClick = () => {
    navigate("/register"); // Use the path for your ContactForm page
  };

  const firstRow = products.slice(0, 8);
  const secondRow = products.slice(8, 16);
  const thirdRow = products.slice(16, 25);
  const fourthRow = products.slice(15, 20);
  const fifthRow = products.slice(20, 25);
  const ref = React.useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    container: ref, // Use container instead of target
    offset: ["start start", "end end"], // Ensure correct offset for scroll events
  });

  const springConfig = { stiffness: 300, damping: 30, bounce: 0 };

  const translateX = useSpring(
    useTransform(scrollYProgress, [0, 1], [0, 1000]),
    springConfig
  );
  const translateXReverse = useSpring(
    useTransform(scrollYProgress, [0, 1], [0, -1000]),
    springConfig
  );
  const rotateX = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [15, 0]),
    springConfig
  );
  const opacity = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [1, 1]), // Ensure images are fully visible initially
    springConfig
  );
  const rotateZ = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [20, 0]),
    springConfig
  );
  const translateY = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [100, 500]), // Adjust initial value to bring images lower
    springConfig
  );

  const overlayOpacity = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [1, 0]),
    springConfig
  );

  const fixedTopInitial = 120; // This is the top position in pixels when fixed
  const windowHeight = window.innerHeight;

  // Variables to control behavior
  const startMovingAt = 0.3; // The scroll progress point where it starts moving (0 to 1 range)
  const movementSpeed = 1.8; // Multiplier for how fast it moves relative to the screen

  const springConfigPin = { stiffness: 2000, damping: 70, bounce: 0 };

  const top = useSpring(
    useTransform(
      scrollYProgress,
      [0, startMovingAt, 1], // The scroll progress points
      [
        `${fixedTopInitial}px`, // Fixed at initial top position until `startMovingAt`
        `${fixedTopInitial}px`, // Transition point
        `-${windowHeight * movementSpeed}px`, // Moves up and off screen, speed controlled by `movementSpeed`
      ]
    ),
    springConfigPin
  );

  const [isScrolledMoreThan30, setIsScrolledMoreThan30] = useState(false);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((latest) => {
      const scrolled = latest > 0.15;
      if (scrolled !== isScrolledMoreThan30) {
        setIsScrolledMoreThan30(scrolled);
        console.log(`isScrolledMoreThan30: ${scrolled}`);
      }
    });

    return () => unsubscribe();
  }, [scrollYProgress, isScrolledMoreThan30]);

  return (
    <div
      ref={ref}
      style={{ height: "100vh", position: "relative", overflowY: "scroll", overflowX:'hidden' }}
    >
      <motion.div
        style={{
          top,
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1000,
        }}
      >
        <HomePin scrollBool={isScrolledMoreThan30} />
      </motion.div>

      <div className="hero-parallax">
      <HomeHeader isMobile={false} />

        <motion.div
          style={{
            rotateX,
            rotateZ,
            translateY,
            opacity,
          }}
          className="parallax-container"
        >
          <motion.div className="product-row reverse-row">
            {firstRow.map((product) => (
              <ProductCard
                product={product}
                translate={translateX}
                overlayOpacity={overlayOpacity}
                key={product.title}
              />
            ))}
          </motion.div>
          <motion.div className="product-row">
            {secondRow.map((product) => (
              <ProductCard
                product={product}
                translate={translateXReverse}
                overlayOpacity={overlayOpacity}
                key={product.title}
              />
            ))}
          </motion.div>
          <motion.div className="product-row reverse-row">
            {thirdRow.map((product) => (
              <ProductCard
                product={product}
                translate={translateX}
                overlayOpacity={overlayOpacity}
                key={product.title}
              />
            ))}
          </motion.div>

          {/*
        <motion.div className="product-row">
          {fourthRow.map((product) => (
            <ProductCard product={product} translate={translateXReverse} key={product.title} />
          ))}
        </motion.div>
        <motion.div className="product-row reverse-row">
          {fifthRow.map((product) => (
            <ProductCard product={product} translate={translateX} key={product.title} />
          ))}
        </motion.div>
        */}
        </motion.div>

        <div style={{ padding: 250 }} />
        <LandingPage />

        <HappyCustomersSection />

        <WhoWeAreSection handleSignupClick={handleSignupClick} />

        <Footer />
      </div>
    </div>
  );
};



export default HomeNew;
