import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import SendNotificationModal from "./SendNotificationModal";
import TitleBar from ".././TitleBar";
import GradientContainer from "./GradientContainer";
import { SERVER_URL}  from ".././Functions"

const Marketing: React.FC = () => {
  const [notifications, setNotifications] = useState<[]>([]); // State for storing fetched users
  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [selectedNotification, setSelectedNotification] = useState<
    string | null
  >(null); // Track the selected UID

  const openModal = () => {
    setSelectedNotification(null); // Set the selected UID when the button is clicked
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
    fetchNotifications(); // Re-fetch notifications when the modal closes
  };

  const rowStyles: React.CSSProperties = {
    //borderRadius: '10px',
    color: "black",
    fontSize: "20px",
    padding: "40px",
    border: "2px solid #000000",
    margin: "10px",
    marginRight: "60px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const columnStyles: React.CSSProperties = {
    flex: 0.1, // Distributes space evenly
    paddingLeft: 40,
    //minWidth: '0', // Ensures flex shrinkage if necessary
  };
  const parentContainerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column", // Stack children vertically
    height: "100vh", // Set the height of this container to the full viewport height
    // Subtract the height of any other elements like the header and any margins they have
  };
  const scrollableListContainerStyles: React.CSSProperties = {
    flex: 0.9, // Takes up all available space in a flex container
    overflowY: "auto", // Enable vertical scrolling
  };
  function formatDateTime(dateStr: any) {
    const dateObj = new Date(dateStr);

    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = dateObj.getFullYear();

    return `${hours}:${minutes} ${day}/${month}/${year}`;
  }

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/api/notifications`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setNotifications(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch user data when the component mounts
    fetchNotifications();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 40px 0px 0px",
        }}
      >
        <TitleBar title="Notification" />

        <button
          style={{
            fontSize: 25,
            color: "white", // Text color
            backgroundColor: "black", // Background color
            borderRadius: "25px", // Make it round
            padding: "10px 20px", // Padding for sizing (adjust as needed)
            border: "none", // Remove default button border
            cursor: "pointer", // Change cursor to pointer on hover
            outline: "none", // Remove focus outline
          }}
          onClick={openModal}
        >
          + NEW
        </button>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px", marginTop: -500 }}>Loading...</p>
        </div>
      ) : notifications.length > 0 ? (
        <div style={scrollableListContainerStyles}>
          <ul>
            {notifications.map((notification: any) => (
              <li key={notification.title} style={rowStyles}>
                <div style={columnStyles}>
                  <span style={{ fontWeight: "bold" }}>Title:</span>
                  <br />
                  <span>{notification.title}</span>
                </div>
                <div style={columnStyles}>
                  <span style={{ fontWeight: "bold" }}>Body:</span>
                  <br />
                  <span>{notification.body}</span>
                </div>
                <div style={columnStyles}>
                  <span style={{ fontWeight: "bold" }}>Recipients:</span>
                  <br />
                  {notification.recipients && notification.recipients.length > 0
                    ? `Block ${notification.recipients.join(", ")}`
                    : "All"}
                </div>
                <div style={columnStyles}>
                  <span style={{ fontWeight: "bold" }}>Date:</span>
                  <br />
                  <span>{formatDateTime(notification.date)}</span>
                </div>
                <div>
                  <button style={{ fontSize: 0, opacity: 0 }}>Details</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px", marginTop: -500 }}>
            No Notification Data Available
          </p>
        </div>
      )}
      {isModalOpen && (
        <SendNotificationModal isOpen={isModalOpen} onClose={onCloseModal} />
      )}
    </div>
  );
};

export default Marketing;
