// src/components/store/actions/orderActions.tsx

import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ADD_ORDERS, UPDATE_ORDER, SET_LOADING_ORDERS } from './types';
import { db } from './../../../App';
import { collection, query, where, getDocs, onSnapshot, QuerySnapshot, DocumentData, getDoc, doc } from 'firebase/firestore';
import { RootState } from '../reducers';
import { Order } from './types'; // Import the Order type
import { fetchCustomers } from './customerActions';

export const fetchOrders = (uid: string, orderIds?: string[]): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch({ type: SET_LOADING_ORDERS, payload: true });

    const state = getState();
    const existingOrderIds = state.orders.orders.map((order: Order) => order.id);

    let newOrders: Order[] = [];
    const customerIds = new Set<string>();

    if (orderIds && orderIds.length > 0) {
      // Fetch orders by document IDs
      const fetchOrdersByIds = async (ids: string[]) => {
        const fetchedOrders: Order[] = [];
        for (const id of ids) {
          const orderDoc = await getDoc(doc(db, 'Orders', id));
          if (orderDoc.exists()) {
            const data = orderDoc.data();
            const order: Order = {
              id: orderDoc.id,
              orderID: data.orderID, // Ensure this field exists in Firestore
              dateOrderPlaced: data.dateOrderPlaced,
              dateOfService: data.dateOfService, // Ensure this field exists
              timeOfService: data.timeOfService, // Ensure this field exists
              address: data.address,
              orderPrice: data.orderPrice,
              service: data.service,
              orderStatus: data.orderStatus,
              orderedBy: data.orderedBy,
              dropOffPreference: data.dropOffPreference, // Ensure this field exists
              provider: data.provider,
              regionId: data.regionId,
              currentZoneId: data.currentZoneId,
              onRouteToOngoing: data.onRouteToOngoing,
              placedToAccepted: data.placedToAccepted,
              acceptedToOnRoute: data.acceptedToOnRoute,
              providerRate: data.providerRate,
              regionalFactorMultiplier: data.regionalFactorMultiplier,
              distanceOnRoute: data.distanceOnRoute,
              distanceOngoing: data.distanceOngoing,
              providerPriority: data.providerPriority,
              eta: data.eta,
              elevate: data.elevate,
              rating: data.rating,
              payment: data.payment,
              inspectImageUrls: data.inspectImageUrls,
              vehicle: data.vehicle, // Ensure this field exists if present
            };
            fetchedOrders.push(order);
            customerIds.add(data.orderedBy);
          }
        }
        return fetchedOrders;
      };

      newOrders = await fetchOrdersByIds(orderIds);
    } else {
      // Fetch orders for the provider
      const q = query(collection(db, 'Orders'), where('provider', '==', uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        const order: Order = {
          id: docSnapshot.id,
          orderID: data.orderID, // Ensure this field exists
          dateOrderPlaced: data.dateOrderPlaced,
          dateOfService: data.dateOfService, // Ensure this field exists
          timeOfService: data.timeOfService, // Ensure this field exists
          address: data.address,
          orderPrice: data.orderPrice,
          service: data.service,
          orderStatus: data.orderStatus,
          orderedBy: data.orderedBy,
          dropOffPreference: data.dropOffPreference, // Ensure this field exists
          provider: data.provider,
          regionId: data.regionId,
          currentZoneId: data.currentZoneId,
          onRouteToOngoing: data.onRouteToOngoing,
          placedToAccepted: data.placedToAccepted,
          acceptedToOnRoute: data.acceptedToOnRoute,
          providerRate: data.providerRate,
          regionalFactorMultiplier: data.regionalFactorMultiplier,
          distanceOnRoute: data.distanceOnRoute,
          distanceOngoing: data.distanceOngoing,
          providerPriority: data.providerPriority,
          eta: data.eta,
          elevate: data.elevate,
          rating: data.rating,
          payment: data.payment,
          inspectImageUrls: data.inspectImageUrls,
          vehicle: data.vehicle, // Ensure this field exists if present
        };

        if (!existingOrderIds.includes(order.id)) {
          newOrders.push(order);
          customerIds.add(data.orderedBy);
        }
      });
    }

    if (customerIds.size > 0) {
      await dispatch(fetchCustomers(Array.from(customerIds))); // Wait for customers to load
    }

    if (newOrders.length > 0) {
      dispatch({ type: ADD_ORDERS, payload: newOrders });
    }

    // Set up a single onSnapshot listener
    const q = query(collection(db, 'Orders'), where('provider', '==', uid));

    onSnapshot(q, (snapshot: QuerySnapshot<DocumentData>) => {
      const changes: Order[] = [];
      snapshot.docChanges().forEach(change => {
        const data = change.doc.data();
        const updatedOrder: Order = {
          id: change.doc.id,
          orderID: data.orderID, // Ensure this field exists
          dateOrderPlaced: data.dateOrderPlaced,
          dateOfService: data.dateOfService, // Ensure this field exists
          timeOfService: data.timeOfService, // Ensure this field exists
          address: data.address,
          orderPrice: data.orderPrice,
          service: data.service,
          orderStatus: data.orderStatus,
          orderedBy: data.orderedBy,
          dropOffPreference: data.dropOffPreference, // Ensure this field exists
          provider: data.provider,
          regionId: data.regionId,
          currentZoneId: data.currentZoneId,
          onRouteToOngoing: data.onRouteToOngoing,
          placedToAccepted: data.placedToAccepted,
          acceptedToOnRoute: data.acceptedToOnRoute,
          providerRate: data.providerRate,
          regionalFactorMultiplier: data.regionalFactorMultiplier,
          distanceOnRoute: data.distanceOnRoute,
          distanceOngoing: data.distanceOngoing,
          providerPriority: data.providerPriority,
          eta: data.eta,
          elevate: data.elevate,
          rating: data.rating,
          payment: data.payment,
          inspectImageUrls: data.inspectImageUrls,
          vehicle: data.vehicle, // Ensure this field exists if present
        };

        const existingOrders = getState().orders.orders;
        const existingOrderIndex = existingOrders.findIndex(order => order.id === updatedOrder.id);

        if (change.type === 'added' && existingOrderIndex === -1) {
          changes.push(updatedOrder);
        } else if (change.type === 'modified' && existingOrderIndex !== -1) {
          dispatch({ type: UPDATE_ORDER, payload: updatedOrder });
        }
      });

      if (changes.length > 0) {
        console.log('Adding new orders from snapshot:', changes.map(order => order.id)); // Debug log
        dispatch({ type: ADD_ORDERS, payload: changes });
        dispatch(fetchCustomers(Array.from(customerIds)));
      }
    });

    dispatch({ type: SET_LOADING_ORDERS, payload: false });
  };
