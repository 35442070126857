// src/store/pricingReducer.js
const initialState = {
  pricing: [],
};

function pricingReducer(state = initialState, action:any) {
  switch (action.type) {
    case 'SET_PRICING':
      return {
        ...state,
        pricing: action.payload,
      };
    default:
      return state;
  }
}

export default pricingReducer;

// Action creators
export const setPricing = (pricing:any) => ({
  type: 'SET_PRICING',
  payload: pricing,
});
