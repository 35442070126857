import React, { CSSProperties, useState } from "react";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
import {
  IoNotificationsOutline,
  IoPerson,
  IoSearchOutline,
} from "react-icons/io5";
import { MdEmail, MdPhone } from "react-icons/md";

const vehicles = [
  {
    name: "Ford Transit",
    status: "Inactive",
    phone: "+1 781-477-6278",
    email: "transit@ford.com",
    statusType: "Inactive",
  },
  {
    name: "Chevrolet Express",
    status: "On Route",
    phone: "+1 781-477-6278",
    email: "express@chevy.com",
    statusType: "On Route",
  },
  {
    name: "Mercedes-Benz Sprinter",
    status: "Ongoing",
    phone: "+1 781-477-6278",
    email: "sprinter@mercedes.com",
    statusType: "Ongoing",
  },
  {
    name: "Ram ProMaster",
    status: "Inactive",
    phone: "+1 781-477-6278",
    email: "promaster@ram.com",
    statusType: "Inactive",
  },
  {
    name: "Nissan NV Cargo",
    status: "On Route",
    phone: "+1 781-477-6278",
    email: "nvcargo@nissan.com",
    statusType: "On Route",
  },
];

const carImages = [
  "https://images.pexels.com/photos/810357/pexels-photo-810357.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/1335077/pexels-photo-1335077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/3354648/pexels-photo-3354648.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/977003/pexels-photo-977003.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/244206/pexels-photo-244206.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
];

const VehicleFleet = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div style={styles.vehicleList}>
      <header style={styles.header}>
        <h1 style={styles.headerTitle}>Vehicles</h1>
        <div style={styles.searchBarContainer}>
          <input
            type="text"
            placeholder="Search Vehicles"
            style={styles.searchInput}
          />
          <button style={styles.searchButton}>
            <IoSearchOutline />
          </button>
        </div>
        <button style={styles.filterButton}>
          <FiFilter />
        </button>
        <button style={styles.addButton} onClick={openModal}>Add Vehicle</button>
        <button style={styles.notificationButton}>
          <IoNotificationsOutline />
        </button>
        <button style={styles.companyLogoButton}>
          <IoPerson />
        </button>
      </header>

      <div style={styles.vehicleGrid}>
        {vehicles.map((vehicle, index) => (
          <div key={index} style={styles.vehicleCard}>
            <div style={styles.vehicleCardHeader}>
              <img
                style={styles.vehiclePhoto}
                src={carImages[index]}
                alt={vehicle.name}
              />
              <div style={styles.vehicleNameStatus}>
                <h2 style={styles.vehicleName}>{vehicle.name}</h2>
                <p style={styles.vehicleStatus}>
                  <span
                    style={
                      vehicle.statusType === "Inactive"
                        ? styles.inactiveContainer
                        : vehicle.statusType === "On Route"
                        ? styles.onRouteContainer
                        : styles.ongoingContainer
                    }
                  >
                    {vehicle.statusType}
                    {vehicle.statusType === "Inactive" ? (
                      <BsXCircleFill style={styles.inactiveIcon} />
                    ) : (
                      <BsCheckCircleFill style={styles.onRouteIcon} />
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div style={styles.vehicleDetails}>
              <div style={styles.detailItem}>
                <p>Type: Cargo Van</p>
              </div>
              <div style={styles.detailItem}>
                <MdPhone style={styles.detailIcon} />
                <p>{vehicle.phone}</p>
              </div>
              <div style={styles.detailItem}>
                <MdEmail style={styles.detailIcon} />
                <p>{vehicle.email}</p>
              </div>
            </div>
            <div style={styles.viewDetailsButtonContainer}>
              <button style={styles.detailsButton}>View Details</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  inactiveContainer: {
    display: "inline-flex",
    alignItems: "center",
    backgroundColor: "#f0f0f0", // Light grey background
    color: "#808080", // Grey text color
    borderRadius: "15px",
    padding: "5px 10px",
    fontSize: "14px",
    gap: "5px",
    border: "1.5px solid #808080", // Grey border
    fontFamily: "Roboto-Medium",
  },
  
  onRouteContainer: {
    display: "inline-flex",
    alignItems: "center",
    backgroundColor: "#f3e6ff", // Light purple background
    color: "#8a2be2", // Purple text color
    borderRadius: "15px",
    padding: "5px 10px",
    fontSize: "14px",
    gap: "5px",
    border: "1.5px solid #8a2be2", // Purple border
    fontFamily: "Roboto-Medium",
  },
  
  ongoingContainer: {
    display: "inline-flex",
    alignItems: "center",
    backgroundColor: "#e6f7ff", // Light blue background
    color: "#007bff", // Blue text color
    borderRadius: "15px",
    padding: "5px 10px",
    fontSize: "14px",
    gap: "5px",
    border: "1.5px solid #007bff", // Blue border
    fontFamily: "Roboto-Medium",
  },
  

  inactiveIcon: {
    marginRight: "5px",
  },
  onRouteIcon: {
    marginRight: "5px",
  },

  vehiclePhoto: {
    borderRadius: "50%", // Circular shape
    width: "80px", // Fixed width
    height: "80px", // Fixed height
    objectFit: "cover", // Ensures the image covers the area without distortion
  },
  
  
  vehicleNameStatus: {
    marginLeft: "10px",
    textAlign: "left",
  },
  vehicleStatus: {
    margin: "10px 0 0 0",
    fontSize: "14px",
  },
  vehicleDetails: {
    textAlign: "left",
    paddingLeft: "5px",
    paddingRight: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "80px",
    marginTop: "25px",
    marginBottom: "25px",
  },
  detailItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2px",
    flexWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  vehicleCard: {
    border: "1px solid #7fe0fe",
    borderRadius: "8px",
    padding: "20px 15px",
    position: "relative",
    textAlign: "center",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  detailIcon: {
    marginRight: "10px",
    color: "#007bff",
    flexShrink: 0,
  },
  vehicleCardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  vehicleName: {
    margin: 0,
    fontSize: "18px",
    fontWeight: "bold",
  },
  viewDetailsButtonContainer: {
    textAlign: "center",
  },
  detailsButton: {
    maxWidth: 250,
    backgroundColor: "white",
    color: "#007bff",
    fontFamily: "Roboto-Bold",
    border: "2px solid #007bff",
    borderRadius: "25px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "20px",
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    boxSizing: "border-box",
  },
  vehicleList: {
    padding: "20px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  headerTitle: {
    margin: 0,
    fontSize: "28px",
    fontWeight: "bold",
  },
  searchBarContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    marginLeft: "20px",
    marginRight: "20px",
  },
  searchInput: {
    flex: 1,
    padding: "10px",
    maxWidth: "500px",
    borderRadius: "15px",
    border: "1px solid #007bff",
    outline: "none",
    marginRight: "10px",
    fontSize: "16px",
  },
  searchButton: {
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "16px",
  },
  filterButton: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
  },
  addButton: {
    backgroundColor: "#007bff",
    fontFamily: "Roboto-Bold",
    color: "white",
    border: "none",
    borderRadius: "25px",
    padding: "10px 20px",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
    whiteSpace: "nowrap",
  },
  notificationButton: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
  },
  companyLogoButton: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "16px",
  },
  vehicleGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "20px",
    marginTop: "20px",
  },
};

export default VehicleFleet;
