import React, { ReactNode } from "react";

interface GradientContainerProps {
  children: ReactNode;
}

const GradientContainer: React.FC<GradientContainerProps> = ({ children }) => {
  const animatedGradientContainer: React.CSSProperties = {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 50,
    height: "100%",
    //background: "linear-gradient(-45deg, #98fbc1, #7fe0fe, #1c345d, #7fe0fe)",
    background: "linear-gradient(-45deg,  #7fe0fe, #1c345d, #1c345d)",
    backgroundSize: "400% 400%",
    animation: "gradientAnimation 15s ease infinite",
  };

  return (
    <div style={animatedGradientContainer}>
      <div
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: "white",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default GradientContainer;
