// reducers/zonesReducer.js

import { FETCH_REGIONS, FETCH_ZONES } from "../actions/types";

const initialState = {
  zones: [],
  regions: []
};

const zonesReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case FETCH_ZONES:
      return {
        ...state,
        zones: action.payload
      };
    case FETCH_REGIONS:
      return {
        ...state,
        regions: action.payload
      };
    default:
      return state;
  }
};

export default zonesReducer;
