// src/store/assignmentsReducer.js
const initialState = {
  assignments: [],
};

function assignmentsReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_ASSIGNMENTS':
      return {
        ...state,
        assignments: action.payload,
      };
    default:
      return state;
  }
}

export default assignmentsReducer;

// Action creators
export const setAssignments = (assignments: any) => ({
  type: 'SET_ASSIGNMENTS',
  payload: assignments,
});
