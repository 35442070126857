import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { FETCH_INVOICES, ADD_INVOICES, UPDATE_INVOICE, SET_LOADING_INVOICES} from './types';
import { db } from './../../../App';
import { collection, query, where, getDocs, doc, onSnapshot, QuerySnapshot, DocumentData } from 'firebase/firestore';
import { RootState } from '../reducers';
import { Invoice, Order } from './types';
import { fetchOrders } from './orderActions'; // Import fetchOrders action

export const fetchInvoices = (uid: string): ThunkAction<void, RootState, unknown, AnyAction> => 
  async (dispatch, getState) => {
    dispatch({ type: SET_LOADING_INVOICES, payload: true });

    const state = getState();
    const existingInvoiceIds = state.invoices.invoices.map((invoice: Invoice) => invoice.id);

    let newInvoices: Invoice[] = [];

    const invoiceQuery = query(collection(db, 'Invoices'), where('providerId', '==', uid));
    const querySnapshot = await getDocs(invoiceQuery);
    
    const orderIds = new Set<string>();
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const invoice: Invoice = {
        id: doc.id,
        orderId: data.orderId,
        providerId: data.providerId,
        customerId: data.customerId,
        orderPrice: data.orderPrice,
        finalInvoiceAmount: data.finalInvoiceAmount,
        providerAmount: data.providerAmount,
        orderZipCode: data.orderZipCode,
        orderDateTimePlaced: data.orderDateTimePlaced,
        orderDateTimeService: data.orderDateTimeService,
        orderDateTimeCompleted: data.orderDateTimeCompleted,
        orderService: data.orderService,
        timestamp: data.timestamp,
        timestampSent: data.timestampSent, // Include timestampSent
        placedToAccepted: data.placedToAccepted,
        acceptedToOnRoute: data.acceptedToOnRoute,
        onRouteToOngoing: data.onRouteToOngoing,
        distanceOnRoute: data.distanceOnRoute,
        distanceOngoing: data.distanceOngoing,
        couponValue: data.couponValue,
        subscriptionDiscount: data.subscriptionDiscount,
        onDemandSurcharge: data.onDemandSurcharge,
        regionalFactorMultiplier: data.regionalFactorMultiplier,
        providerRate: data.providerRate,
        providerPriority: data.providerPriority,
        invoiceStatus: data.invoiceStatus, // Fetch invoiceStatus field
        alerts: data.alerts, // Include alerts
        orderStatus: '', // Initialize as empty, will be fetched later
      };

      if (!existingInvoiceIds.includes(invoice.id)) {
        newInvoices.push(invoice);
        orderIds.add(data.orderId);
      }
    });

    let orderStatusMap = new Map<string, string>();
    
    // Fetch orders to get their statuses
    if (orderIds.size > 0) {
      await dispatch(fetchOrders(uid, Array.from(orderIds)));
      const ordersState = getState().orders.orders;
      orderStatusMap = new Map(ordersState.map(order => [order.id, order.orderStatus]));

      // Update invoices with order statuses
      newInvoices = newInvoices.map(invoice => ({
        ...invoice,
        orderStatus: orderStatusMap.get(invoice.orderId) || 'Unknown',
      }));
    }

    if (newInvoices.length > 0) {
      dispatch({ type: ADD_INVOICES, payload: newInvoices });
    }

    newInvoices.forEach((invoice: Invoice) => {
      const invoiceDoc = doc(db, 'Invoices', invoice.id);
      onSnapshot(invoiceDoc, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const updatedInvoice: Invoice = {
            id: doc.id,
            orderId: data.orderId,
            providerId: data.providerId,
            customerId: data.customerId,
            orderPrice: data.orderPrice,
            finalInvoiceAmount: data.finalInvoiceAmount,
            providerAmount: data.providerAmount,
            orderZipCode: data.orderZipCode,
            orderDateTimePlaced: data.orderDateTimePlaced,
            orderDateTimeService: data.orderDateTimeService,
            orderDateTimeCompleted: data.orderDateTimeCompleted,
            orderService: data.orderService,
            timestamp: data.timestamp,
            timestampSent: data.timestampSent, // Include timestampSent
            placedToAccepted: data.placedToAccepted,
            acceptedToOnRoute: data.acceptedToOnRoute,
            onRouteToOngoing: data.onRouteToOngoing,
            distanceOnRoute: data.distanceOnRoute,
            distanceOngoing: data.distanceOngoing,
            couponValue: data.couponValue,
            subscriptionDiscount: data.subscriptionDiscount,
            onDemandSurcharge: data.onDemandSurcharge,
            regionalFactorMultiplier: data.regionalFactorMultiplier,
            providerRate: data.providerRate,
            providerPriority: data.providerPriority,
            invoiceStatus: data.invoiceStatus, // Fetch invoiceStatus field
            alerts: data.alerts, // Include alerts
            orderStatus: orderStatusMap.get(data.orderId) || 'Unknown', // Set order status from map
          };

          dispatch({ type: UPDATE_INVOICE, payload: updatedInvoice });
        }
      });
    });

    const snapshotQuery = query(collection(db, 'Invoices'), where('providerId', '==', uid));
    onSnapshot(snapshotQuery, (snapshot: QuerySnapshot<DocumentData>) => {
      const addedInvoices: Invoice[] = [];
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const data = change.doc.data();
          if (data.providerId === uid) {
            const newInvoice: Invoice = {
              id: change.doc.id,
              orderId: data.orderId,
              providerId: data.providerId,
              customerId: data.customerId,
              orderPrice: data.orderPrice,
              finalInvoiceAmount: data.finalInvoiceAmount,
              providerAmount: data.providerAmount,
              orderZipCode: data.orderZipCode,
              orderDateTimePlaced: data.orderDateTimePlaced,
              orderDateTimeService: data.orderDateTimeService,
              orderDateTimeCompleted: data.orderDateTimeCompleted,
              orderService: data.orderService,
              timestamp: data.timestamp,
              timestampSent: data.timestampSent, // Include timestampSent
              placedToAccepted: data.placedToAccepted,
              acceptedToOnRoute: data.acceptedToOnRoute,
              onRouteToOngoing: data.onRouteToOngoing,
              distanceOnRoute: data.distanceOnRoute,
              distanceOngoing: data.distanceOngoing,
              couponValue: data.couponValue,
              subscriptionDiscount: data.subscriptionDiscount,
              onDemandSurcharge: data.onDemandSurcharge,
              regionalFactorMultiplier: data.regionalFactorMultiplier,
              providerRate: data.providerRate,
              providerPriority: data.providerPriority,
              invoiceStatus: data.invoiceStatus, // Fetch invoiceStatus field
              alerts: data.alerts, // Include alerts
              orderStatus: orderStatusMap.get(data.orderId) || 'Unknown', // Set order status from map
            };

            const existingInvoices = getState().invoices.invoices;
            if (!existingInvoices.find(invoice => invoice.id === newInvoice.id)) {
              addedInvoices.push(newInvoice);
            }
          }
        }
      });
      if (addedInvoices.length > 0) {
        dispatch({ type: ADD_INVOICES, payload: addedInvoices });
      }
    });

    dispatch({ type: SET_LOADING_INVOICES, payload: false });
  };
