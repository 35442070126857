import React, { useEffect, useState } from "react";
import "./ZipCodeManagementModal.css";
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  doc as firebaseDoc,
} from "firebase/firestore";
import { db } from "../../App";
import { formatBase64Image } from ".././Functions";
import { IoCloudDownload } from "react-icons/io5";
import check from "../check.png"; // Importing the image
import { SERVER_URL } from ".././Functions";

interface VerifyModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedUid: any;
}

interface ApplicationData {
  profileImage?: string;
  w9File?: string;
  insuranceFile?: string;
  [key: string]: any;
}

const VerifyProviderModal: React.FC<VerifyModalProps> = ({
  isOpen,
  onClose,
  selectedUid,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [applicationData, setApplicationData] = useState<
    ApplicationData | string | null
  >(null);

  const [isVerifying, setIsVerifying] = useState(false);
  const [isDenying, setIsDenying] = useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (selectedUid) {
      fetchApplicationData(selectedUid);
    }
  }, [selectedUid]);

  const fetchApplicationData = async (uid: string) => {
    try {
      setIsLoading(true);
      const applicationDocRef = doc(db, "ProviderApps", uid);
      const applicationDocSnapshot = await getDoc(applicationDocRef);

      if (applicationDocSnapshot.exists()) {
        const data = applicationDocSnapshot.data();
        setApplicationData(data);
      } else {
        setApplicationData("No application submitted");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching application data:", error);
      setIsLoading(false);

      throw error;
    }
  };

  const downloadFile = (fileUri: string, fileName: string) => {
    const anchor = document.createElement("a");
    anchor.href = fileUri;
    anchor.target = "_blank";
    anchor.style.display = "none"; // Hide the anchor element
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const verifyAccount = async () => {
    setIsVerifying(true); // Set loading state to true

    try {
      // Fetch the provider application data
      const providerAppDocRef = firebaseDoc(db, "ProviderApps", selectedUid);
      const providerAppDocSnapshot = await getDoc(providerAppDocRef);

      if (providerAppDocSnapshot.exists()) {
        const providerAppData = providerAppDocSnapshot.data();

        
        // Prepare date of birth in yyyy-mm-dd format
        //const birthYear = providerAppData.birthYear;
        //const birthMonth = providerAppData.birthMonth.padStart(2, "0"); // Ensure two digits
        //const birthDay = providerAppData.birthDay.padStart(2, "0"); // Ensure two digits
        //const dateOfBirth = `${birthYear}-${birthMonth}-${birthDay}`;

        // Prepare data for updating, omitting individual birthday fields
        const {
        //  birthYear: _,
        // birthMonth: __,
        // birthDay: ___,
          ...dataForUpdate
        } = providerAppData;
       // dataForUpdate.dateOfBirth = dateOfBirth; // Add dateOfBirth to the update data

        

        // Update the 'Providers' collection with the modified provider application data
        const providerDocRef = firebaseDoc(db, "Providers", selectedUid);
        await setDoc(providerDocRef, dataForUpdate, { merge: true });

        // Set the 'verified' field to true
        await updateDoc(providerDocRef, {
          verified: true,
        });

        await fetch(`${SERVER_URL}/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: selectedUid,
            email: providerAppData.email, // Assuming the email is stored in providerAppData
            status: "verified", // or "verified" depending on the context
          }),
        });

        console.log("Provider account verified successfully");
      } else {
        console.error("Provider application data not found");
      }

      //setAccepted(true); // Set accepted to true when verification is successful
    } catch (error) {
      console.error("Error verifying provider account:", error);
    } finally {
      setIsVerifying(false); // Reset loading state
    }
  };

  const denyApplication = async () => {
    setIsDenying(true); // Set loading state to true
  
    try {
      // Fetch the provider application data
      const providerAppDocRef = firebaseDoc(db, "ProviderApps", selectedUid);
      const providerAppDocSnapshot = await getDoc(providerAppDocRef);
  
      if (providerAppDocSnapshot.exists()) {
        const providerAppData = providerAppDocSnapshot.data();
  
        // Delete the application from the 'ProviderApps' collection
        await deleteDoc(providerAppDocRef);
  
        // Send denial email
        await fetch(`${SERVER_URL}/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: selectedUid,
            email: providerAppData.email, // Assuming the email is stored in providerAppData
            status: "denied", // Indicate that the application was denied
          }),
        });
  
        console.log("Provider application denied successfully");
        onClose(); // Close the modal after denying the application
      } else {
        console.error("Provider application data not found");
      }
    } catch (error) {
      console.error("Error denying provider application:", error);
    } finally {
      setIsDenying(false); // Reset loading state
    }
  };
  

  useEffect(() => {
    if (!isOpen) {
      setAccepted(false); // Reset accepted state when modal closes
    }
  }, [isOpen]);

  function getMonthName(month: string): string {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = parseInt(month, 10) - 1; // Parse the month as a base-10 integer and adjust for array index
    return monthNames[monthIndex];
  }

  function getDaySuffix(day: string): string {
    const dayNum = parseInt(day, 10); // Parse the day as a base-10 integer
    if (dayNum > 3 && dayNum < 21) return "th";
    switch (dayNum % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Calculate birthDate only when applicationData is available
  const getFormattedBirthDate = () => {
    if (!applicationData || typeof applicationData !== "object") return "";

    const { birthYear, birthMonth, birthDay } = applicationData;
    if (!birthYear || !birthMonth || !birthDay) return ""; // Ensure all date parts are available

    const monthName = getMonthName(birthMonth);
    const daySuffix = getDaySuffix(birthDay);
    return `${monthName}, ${birthDay}${daySuffix} ${birthYear}`;
  };

  // Use this function in your component's render method
  const birthDate = getFormattedBirthDate();

  return (
    <div className={`dispatch-modalM ${isOpen ? "open" : ""}`}>
      <div
        className={`modal-overlayM ${isOpen ? "open-overlay" : ""}`}
        onClick={onClose}
      ></div>

      <div
        style={{
          height: "500px",
          minWidth: "100px",
          maxWidth: "800px",
          margin: 20,
          overflow: "auto",
          backgroundColor: "white",
          fontFamily: "Poppins",
        }}
        className={`modal-contentM ${isOpen ? "open-content" : ""}`}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h2 style={{ fontSize: 30, color: "black", paddingLeft: 40 }}>
              Verification Form
            </h2>
            <button
              onClick={onClose}
              style={{
                position: "absolute",
                color: "black",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              X
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
                width: "800px",
                minWidth: "100px",
              }}
            >
              <div className="spinner"></div>
            </div>
          </div>
        ) : (
          <div>
            <h2 style={{ fontSize: 30, color: "black", paddingLeft: 40 }}>
              Verification Form
            </h2>
            <button
              onClick={onClose}
              style={{
                position: "absolute",
                color: "black",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              X
            </button>
            {typeof applicationData === "object" &&
              applicationData !== null && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 90,
                    paddingRight: 60,
                  }}
                >
                  {/* Left Side - Profile Image */}
                  <div style={{ marginRight: 100 }}>
                    <img
                      src={formatBase64Image(
                        applicationData?.profileImage ?? ""
                      )}
                      alt="Provider Profile Icon"
                      style={{
                        width: "120px", // Larger size for visibility
                        height: "120px",
                        borderRadius: "50%",
                        border: "3px solid #007ae4",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  {/* Right Side - Document Download Buttons */}
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {applicationData?.w9File && (
                      <div style={styles.container}>
                        <div style={{ ...styles.icon, color: "#007bff" }}>
                          <IoCloudDownload size={34} color="#007bff" />
                        </div>
                        <span style={styles.label}>W9 Document</span>
                        <button
                          style={{
                            ...styles.buttonDownload,
                            backgroundColor: "#007bff",
                          }}
                          onClick={() =>
                            downloadFile(
                              //@ts-ignore
                              applicationData.w9File,
                              "W9_Document.pdf"
                            )
                          }
                        >
                          Download
                        </button>
                      </div>
                    )}
                    {applicationData?.insuranceFile && (
                      <div style={styles.container}>
                        <div style={{ ...styles.icon, color: "#007bff" }}>
                          <IoCloudDownload size={34} color="#007bff" />
                        </div>
                        <span style={styles.label}>Insurance Document</span>
                        <button
                          style={{
                            ...styles.buttonDownload,
                            backgroundColor: "#007bff",
                          }}
                          onClick={() =>
                            downloadFile(
                              //@ts-ignore
                              applicationData.insuranceFile,
                              "Insurance_Document.pdf"
                            )
                          }
                        >
                          Download
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}

            <div
              style={{
                overflow: "auto",
                backgroundColor: "white",
              }}
            >
              <ul
                style={{
                  padding: 30,
                  borderRadius: "25px",
                  margin: 30,
                  backgroundColor: "#f5f5f5",
                }}
              >
                {/*pretty sure this is deprecated*/}
                {applicationData === "No application submitted" ? (
                  <li style={{ color: "black" }}>No application submitted</li>
                ) : (
                  <ul>
                    {applicationData && (
                      <li>
                        <strong
                          style={{
                            color: "black",
                            fontFamily: "PoppinsSemi",
                          }}
                        >
                          Date of Birth:{" "}
                        </strong>
                        <label style={{ color: "black" }}>{birthDate}</label>{" "}
                      </li>
                    )}

                    {Object.entries(applicationData || {}).map(
                      ([key, value]) => {
                        if (
                          key === "leftOn" ||
                          key === "selectedDays" ||
                          key === "profileImage" ||
                          key === "w9File" ||
                          key === "insuranceFile" ||
                          key === "birthDay" ||
                          key === "birthMonth" ||
                          key === "birthYear" ||
                          key === "submitted"
                        )
                          return null; // Skip rendering the "leftOn" and "selectedDays" fields
                        if (key === "workingHours") {
                          return (
                            <li key={key} style={{ color: "black" }}>
                              <strong>{key}:</strong>
                              <ul>
                                {Object.entries(value).map(([day, times]) => (
                                  <li key={day}>
                                    <strong>{day}:</strong> Start Time:{" "}
                                    {/*@ts-ignore*/}
                                    {times.startTime}, End Time:{" "}
                                    {/*@ts-ignore*/}
                                    {times.endTime}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          );
                        }
                        return (
                          <li key={key} style={{ color: "black" }}>
                            {/* Ensure the list item text is black */}
                            <label style={{ fontFamily: "PoppinsSemi" }}>
                              {key}:
                            </label>{" "}
                            {/* Key label will inherit the color from the li */}
                            {Array.isArray(value) ? (
                              // If the value is an array, render a list of items.
                              <ul>
                                {value.map((item, index) => (
                                  <li key={index}>
                                    {/* Check if the array item itself is an object and not null */}
                                    {typeof item === "object" &&
                                    item !== null ? (
                                      <ul>
                                        {/* Map through the entries of the item if it's an object */}
                                        {Object.entries(item).map(
                                          ([itemKey, itemValue]) => (
                                            <li key={itemKey}>
                                              <strong>{itemKey}:</strong>{" "}
                                              {String(itemValue)}{" "}
                                              {/* Convert non-string values to string */}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      // If the array item is not an object, just render its value
                                      String(item)
                                    )}
                                  </li>
                                ))}
                              </ul>
                            ) : typeof value === "object" && value !== null ? (
                              // If the value is an object, render a list of its key-value pairs.
                              <ul>
                                {Object.entries(
                                  value as Record<string, unknown>
                                ).map(([itemKey, itemValue]) => (
                                  <li key={itemKey}>
                                    <strong>{itemKey}:</strong>{" "}
                                    {String(itemValue)}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              // If the value is not an object or array, render it directly.
                              <span>{String(value)}</span>
                            )}
                          </li>
                        );
                      }
                    )}
                  </ul>
                )}
              </ul>
            </div>
            {applicationData === "No application submitted" ? (
              <button style={styles.buttonSmall} onClick={onClose}>
                Close
              </button>
            ) : (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={styles.button}
                  onClick={denyApplication}
                  disabled={isDenying}
                >
                  {isDenying ? (
                    <div className="spinner" style={styles.spinnerStyles}></div>
                  ) : (
                    "Deny Application"
                  )}
                </button>
                <button
                  style={styles.button}
                  onClick={verifyAccount}
                  disabled={isVerifying}
                >
                  {isVerifying ? (
                    <div
                      className="spinner-small"
                      style={styles.spinnerStyles}
                    ></div>
                  ) : (
                    "Verify Account"
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyProviderModal;

interface Styles {
  [key: string]: React.CSSProperties;
}
const styles: Styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "14px",
    backgroundColor: "#fff",
  },
  icon: {
    marginLeft: "8px",
    marginRight: "8px",
    display: "flex",
  },
  spinnerStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  label: {
    flexGrow: 1,
    margin: "0 15px",
    color: "#000",
    fontSize: 15,
    fontWeight: "600",
  },
  buttonDownload: {
    backgroundColor: "#007bff",
    color: "white",
    padding: "12px 22px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
  uploadText: {
    fontSize: "16px",
    fontWeight: "900",
  },

  button: {
    padding: "12px 25px", // Increases padding to make the button larger
    fontSize: "17px", // Increases the font size
    fontFamily: "PoppinsLight",
    margin: "10px 10px", // Adds some margin above the button
    cursor: "pointer", // Changes the cursor on hover
    color: "white",
    width: 200,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: "black",
  },

  buttonSmall: {
    padding: "10px 16px", // Increases padding to make the button larger
    fontSize: "12px", // Increases the font size
    cursor: "pointer", // Changes the cursor on hover
    marginTop: 10,
    marginLeft: 10,
    color: "white",
    //width:100,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: "black",
  },
};
