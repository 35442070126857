import { FETCH_CUSTOMERS, ADD_CUSTOMERS, UPDATE_CUSTOMER, SET_LOADING_CUSTOMERS } from '../actions/types';
import { Customer, Action } from '../actions/types'; // Import the Customer type

interface CustomerState {
  customers: Customer[];
  loading: boolean;
}


const initialState: CustomerState = {
  customers: [],
  loading: false,
};

const customerReducer = (state = initialState, action: Action): CustomerState => {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        loading: false, // Stop loading once customers are fetched
      };
    case ADD_CUSTOMERS:
      return {
        ...state,
        customers: [...state.customers, ...action.payload],
        loading: false, // Stop loading when new customers are added
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer.uid === action.payload.uid ? action.payload : customer
        ),
      };
    case SET_LOADING_CUSTOMERS:
      return {
        ...state,
        loading: action.payload, // Set loading state specifically for customers
      };
    default:
      return state;
  }
};

export default customerReducer;
