import { FETCH_INVOICES, ADD_INVOICES, UPDATE_INVOICE, SET_LOADING_INVOICES } from "../actions/types";
import { Invoice } from "../actions/types";

interface InvoiceState {
  invoices: Invoice[];
  loading: boolean;
}

interface Action {
  type: string;
  payload: any;
}

const initialState: InvoiceState = {
  invoices: [],
  loading: false,
};

const invoiceReducer = (state = initialState, action: Action): InvoiceState => {
  switch (action.type) {
    case FETCH_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        loading: false, // Stop loading once invoices are fetched
      };
    case ADD_INVOICES:
      return {
        ...state,
        invoices: [...state.invoices, ...action.payload],
        loading: false, // Stop loading when new invoices are added
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === action.payload.id ? action.payload : invoice
        ),
      };
    case SET_LOADING_INVOICES:
      return {
        ...state,
        loading: action.payload, // Set loading state specifically for invoices
      };
    default:
      return state;
  }
};

export default invoiceReducer;
