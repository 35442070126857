import React, { CSSProperties, useState } from "react";
import {
  formatBase64Image,
  formatDateTimeFromTimestamp,
  SERVER_URL,
} from ".././Functions";
import OrderRow from "./OrderRow";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { Customer } from "../store/actions/types";

interface CustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  customerInfo: Customer | null; // Include customerInfo in the props
}

const CustomerModal: React.FC<CustomerModalProps> = ({
  isOpen,
  onClose,
  customerInfo,
}) => {
  const orders = useSelector((state: RootState) => state.orders.orders);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deletingStatus, setDeletingStatus] = useState<{
    [uid: string]: boolean;
  }>({});

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const customerOrders = orders.filter(
    (order) => order.orderedBy === customerInfo?.uid
  );

  const deleteCustomer = async (uid: any) => {
    return; // Temporarily disable the function

    setDeletingStatus((prev) => ({ ...prev, [uid]: true })); // Set loading state for this UID

    try {
      // Send a DELETE request to your server API endpoint for customers
      const response = await fetch(`${SERVER_URL}/api/delete-customer/${uid}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response from the server
      const result = await response.json();

      console.log(result.message); // Handle the success message

      // Refresh the customer list after deletion
      //await fetchCustomers();
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
    setDeletingStatus((prev) => ({ ...prev, [uid]: false })); // Unset loading state for this UID
    onClose();
  };

  const renderDeleteButton = (uid: any) => {
    const isDeleting = deletingStatus[uid]; // Check if this UID is currently being deleted
    return (
      <button
        style={{
          ...modalStyles.baseButton,
          backgroundColor: "red", // Red color for delete button
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => deleteCustomer(uid)} // Call deleteProvider with the provider's UID
        disabled={isDeleting} // Disable the button while deleting
      >
        {isDeleting ? (
          <div className="spinner-small"></div> // Show spinner when deleting
        ) : (
          "Delete Account"
        )}
      </button>
    );
  };

  if (!isOpen) return null;
  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
      <h2>Customer Details</h2>

        <div style={modalStyles.detailsContainer}>
          <div style={modalStyles.profileColumn}>
            {customerInfo?.profileImage && (
              <img
                src={formatBase64Image(customerInfo.profileImage)}
                alt="Customer Profile Image"
                style={modalStyles.profileIcon} // Ensure you define this style
              />
            )}
          </div>
          <div style={modalStyles.column}>
            <div style={modalStyles.contentStyles}>
              <strong>Full Name:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {customerInfo?.firstName} {customerInfo?.lastName}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Phone Number:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {customerInfo?.phoneNumber}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Gender:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {customerInfo?.gender}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Date of Birth:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {customerInfo?.dateOfBirth}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Signed Up on:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {formatDateTimeFromTimestamp(customerInfo?.createdAt)}
                </span>
              </div>
            </div>
        
          </div>
          <div style={modalStyles.column}>

          <div style={{ overflow: "auto" }}>
              {customerInfo?.addresses && customerInfo.addresses.length > 0 ? (
                customerInfo.addresses.map((address, index) => (
                  <div key={index} style={modalStyles.contentStyles}>
                    <strong>
                      {index === 0 ? "Home Address: " : `Address ${index + 1}: `}
                    </strong>
                    <div style={modalStyles.floatingItem}>
                      <span style={modalStyles.floatingText}>
                        {address.streetAddress}, {address.zipCodeAddress},{" "}
                        {address.stateAddress}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div style={modalStyles.contentStyles}>
                  <strong>Addresses:</strong>
                  <div style={modalStyles.floatingItem}>
                    <span style={modalStyles.floatingText}>No addresses registered</span>
                  </div>
                </div>
              )}
            </div>
            <div style={{ overflow: "auto" }}>
              {customerInfo?.vehicles && customerInfo.vehicles.length > 0 ? (
                customerInfo.vehicles.map((vehicle, index) => (
                  <div style={modalStyles.vehicleWrapper}>
                    <div key={index} style={modalStyles.vehicleContainer}>
                      <img
                        src={formatBase64Image(vehicle.imageSource)}
                        alt={`Customer Vehicle ${index + 1}`}
                        style={modalStyles.vehicleIcon}
                      />
                      <span style={modalStyles.floatingText}>
                        {vehicle.make} {vehicle.model} {vehicle.color}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div style={modalStyles.contentStyles}>
                  <strong>Vehicles:</strong>
                  <div style={modalStyles.floatingItem}>
                    <span style={modalStyles.floatingText}>No vehicles registered</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <hr style={modalStyles.separator} />

        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 20 }}>
          {renderDeleteButton(customerInfo?.uid)}
          <button
            style={{
              ...modalStyles.baseButton,
              backgroundColor: "#Ee9c00", // Blue color
            }}
          >
            Disable Account
          </button>
          <button
            style={{
              ...modalStyles.baseButton,
              backgroundColor: "#0069A2", // Blue color
            }}
          >
            Direct Messaging Portal
          </button>
        </div>

        <div
          style={{
            ...modalStyles.barContainer,
            padding: "0", // Set padding to 0 for this specific div
          }}
        >
          {customerOrders.map((order: any, index: any) => (
            <OrderRow
              key={index}
              order={order}
              isModalOpen={isModalOpen} // Pass modal state down to OrderRow
              toggleModal={toggleModal} // Pass toggleModal function down
            />
          ))}
        </div>

        <button style={modalStyles.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const modalStyles = {
  flexContainer: {
    display: "flex",
    fontFamily: "Poppins",
    justifyContent: "space-between", // Adjust this as needed for your layout
    gap: "20px", // Add some gap between columns
  },

  detailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  } as CSSProperties,

  profileColumn: {
    display: "flex",
    padding: 30,
    justifyContent: "center", // Center horizontally
    //alignItems: "center", // Center vertically
    width: "auto", // Take only the space it needs
  } as CSSProperties,

  profileIcon: {
    width: "100px",
    height: "100px",
    borderRadius: "20%",
    border: "3px solid #007ae4",
    objectFit: "cover",
  } as CSSProperties,

  vehicleWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%", // Make sure the wrapper takes full width of the column
  } as CSSProperties,

  vehicleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    width: '50%',
    marginBottom: 20,
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    textAlign: "center", // Center the text
  } as CSSProperties,

  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  } as CSSProperties,

  contentStyles: {
    marginBottom: "10px",
  } as CSSProperties,

  profileContainer: {
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    width: "100%", // Ensure it takes up full width
    height: "100%", // Ensure it takes up full height of its parent
  } as CSSProperties,

  profileImageContainer: {
    display: "flex",
    marginLeft: 50,
    justifyContent: "flex-start",
    marginBottom: "20px",
    width: "100%",
  } as CSSProperties,

  vehiclesAddressesContainer: {
    display: "flex",
    flexDirection: "column", // Align items in a column
    width: "100%", // Ensure full width to center contents
    marginBottom: "20px",
  } as CSSProperties,

  floatingItem: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    textAlign: "left",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    margin: "0 15px 0px 0", // Add this line for horizontal and vertical gap

  } as CSSProperties,

  floatingText: {
    color: "#000",
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as CSSProperties,

  barContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    padding: 20,
    backgroundColor: "#f5f5f5",
    border: "0px solid #000000",
    borderRadius: 15,
  } as CSSProperties,

  separator: {
    border: "0",
    height: "1px",
    backgroundImage:
      "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))",
    margin: "20px 0", // Adjust margin as needed
  } as CSSProperties,

  baseButton: {
    fontSize: "15px",
    color: "white",
    margin: 5,
    fontFamily: "PoppinsLight",
    backgroundColor: "gray",
    fontWeight: "600",
    borderRadius: "5px",
    padding: "10px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
  } as CSSProperties,

  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "left", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,

  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,

  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "10%", // Circular shape
    objectFit: "cover",
    border: "3px solid #007ae4",
    marginBottom:10,
  } as CSSProperties,

  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,

  content: {
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    backgroundColor: "#f5f5f5",
    border: "0px solid #000000",
    borderRadius: 15,
  } as CSSProperties,

  column: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    gap: "10px", // Add some gap between fields in each column
  } as CSSProperties,

  closeButton: {
    fontSize: "18px",
    color: "white", // Text color
    fontFamily: "PoppinsLight",
    backgroundColor: "black", // Background color
    borderRadius: "10px", // Make it round
    padding: "5px 10px", // Padding for sizing (adjust as needed)
    border: "none", // Remove default button border
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
  } as CSSProperties,

  background: {
    position: "fixed" as const, // Use 'as const' to narrow down the string literal type
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties

  container: {
    backgroundColor: "#f5f5f5",
    padding: "20px",
    borderRadius: "10px",
    fontFamily: "Poppins",
    marginBottom: "20px",   
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxHeight: "85vh",
    overflowY: "auto" as const, // Use 'as const' for string literals
    zIndex: 1001,
  } as CSSProperties,
};

export default CustomerModal;
