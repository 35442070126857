import React, { useState, useEffect } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";


const Footer1 = ({ isMobile }: { isMobile: boolean }) => {
  const aboutUsStyle = {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingRight: "20px",
    maxWidth: "350px",
  };

  const headingStyle = {
    fontSize: isMobile ? "20px" : "24px", // Smaller on mobile, larger on desktop
    fontWeight: "bold",
    marginBottom: "12px", // Tailwind mb-3
  };

  const textStyle = {
    fontSize: isMobile ? "14px" : "16px", // Smaller on mobile
    marginBottom: "12px",
  };

  return (
    <div style={aboutUsStyle}>
      <h3 style={headingStyle}>About Us</h3>
      <p style={textStyle}>
        Drip Auto Care is a mobile carwash company that offers
        subscription-based service.
      </p>
    </div>
  );
};

const Footer2 = ({
  handleHomeClick,
  handleServicesClick,
  handleSignupClick,
  isMobile
}: any) => {
  const headingStyle = {
    fontSize: isMobile ? "20px" : "24px", // Smaller on mobile
    fontWeight: "bold",
    marginBottom: "12px", // mb-3
  };

  const listStyle = {
    listStyleType: "disc",
    //paddingLeft: "20px", // pl-5
    paddingLeft: "0", // Explicitly remove padding
    margin: "0", // Remove default margin
  };

  const listItemStyle = {
    fontSize: isMobile ? "14px" : "16px", // Adjust font size for mobile
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "8px", // Tailwind does not have a direct equivalent, assuming mb-2
  };

  const iconStyle = {
    marginRight: "8px", // mr-2
  };

  return (
    <div style={{ paddingTop: "16px" }}>
      <h3 style={headingStyle}>Quick Links</h3>
      <ul style={listStyle}>
        <li style={listItemStyle} onClick={handleHomeClick}>
          <MdKeyboardDoubleArrowRight style={iconStyle} color="#06adee" />
          Home
        </li>
        <li style={listItemStyle} onClick={handleServicesClick}>
          <MdKeyboardDoubleArrowRight style={iconStyle} color="#06adee" />
          Services
        </li>
        <li style={listItemStyle} onClick={handleSignupClick}>
          <MdKeyboardDoubleArrowRight style={iconStyle} color="#06adee" />
          Get Started
        </li>
      </ul>
    </div>
  );
};

const Footer3 = ({ isMobile }: { isMobile: boolean }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const breakpoint = 768; // Define your responsive design breakpoint

  const footer3Style: React.CSSProperties = {
    paddingTop: "16px",
    display: windowWidth >= breakpoint ? "flex" : "block",
    alignItems: windowWidth >= breakpoint ? "flex-start" : "initial",
  };

  const contactInfoStyle: React.CSSProperties = {
    paddingRight: windowWidth >= breakpoint ? "28px" : "0",
    marginBottom: windowWidth < breakpoint ? "16px" : "0",
  };

  const headingStyle: React.CSSProperties = {
    fontSize: isMobile ? "20px" : "24px", // Smaller on mobile
    fontWeight: "bold",
    marginBottom: "12px",
  };

  const iconStyle: React.CSSProperties = {
    marginRight: "8px", // Replacing Tailwind's mr-2
    fontSize: isMobile ? "14px" : "16px", // Adjust icon size for mobile
    color:'black'
  };

  const socialsStyle: React.CSSProperties = {
    display: "flex",
    marginTop: "8px", // Replacing Tailwind's mt-2
  };

  return (
    <div style={footer3Style}>
      <div style={{ flex: 1, ...contactInfoStyle }}>
        <div>
          <h3 style={headingStyle}>Contact Us</h3>
          <p>dripautocare@gmail.com</p>
          <p>(408) 416-8179</p>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <h3 style={headingStyle}>Socials</h3>
        <div style={socialsStyle}>
          <a
            href="https://web.facebook.com/profile.php?id=61557409962899"
            style={iconStyle}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/dripacinc/"
            style={iconStyle}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://twitter.com/Dripacinc"
            style={iconStyle}
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiTwitterXFill />
          </a>
        </div>
      </div>
    </div>
  );
};


const Footer = ({ handleHomeClick, handleServicesClick, handleSignupClick, isMobile }: any) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 const footerContainerStyle: React.CSSProperties = {
    backgroundColor: "#e2e8f0",
    width: "100%",
    display: "grid",
    gridTemplateColumns: isMobile ? "1fr" : "2fr 1fr 2fr",  // Use 1fr on mobile, 2fr 1fr 2fr on desktop
    gap: isMobile ? "0px" : "16px",  // Reduce gap for mobile, keep it larger for desktop
    padding: isMobile ? "0px 30px" : "40px",  // Different vertical and horizontal padding for mobile
    fontFamily: 'NeuePlakExtendedSemiBold',
    boxSizing: "border-box" as React.CSSProperties["boxSizing"],  // Correctly typed box-sizing
    overflowX: isMobile ? "hidden" : "visible",  // Ensure no horizontal scrolling on mobile
    paddingBottom: isMobile ? '40px' : '40px',
  };
  

  return (
    <div style={footerContainerStyle}>
      <Footer1 isMobile={isMobile} />
      <Footer2
        handleHomeClick={handleHomeClick}
        handleServicesClick={handleServicesClick}
        handleSignupClick={handleSignupClick}
        isMobile={isMobile}
      />
      <Footer3 isMobile={isMobile} />
    </div>
  );
};

export default Footer;
