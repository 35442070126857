import React, { useState, CSSProperties, useEffect } from "react";
import mapImage from "../../assets/mapImage.png"; // Import the image
import iphoneFrame from "../../assets/iphoneFrame.png"; // Import the iPhone frame image

interface HomePinProps {
  scrollBool: boolean;
}

const HomePin: React.FC<HomePinProps> = ({ scrollBool }) => {
  const [transform, setTransform] = useState(
    "translate(-50%,-50%) rotateX(0deg)"
  );
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    console.log("Inside HomePin useEffect - scrollYProgress:", scrollBool);
  
    if (scrollBool) {
      setTransform("translate(-50%,-50%) rotateX(40deg) scale(0.8)");
      setHovered(true);
    } else {
      setTransform("translate(-50%,-50%) rotateX(0deg) scale(1)");
      setHovered(false);
    }
  }, [scrollBool]);
  

  const onMouseEnter = () => {
    setTransform("translate(-50%,-50%) rotateX(40deg) scale(0.8)");
    setHovered(true);
  };

  const onMouseLeave = () => {
    setTransform("translate(-50%,-50%) rotateX(0deg) scale(1)");
    setHovered(false);
  };

  const containerStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40rem",
    width: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: 100,
    pointerEvents: "none",
  };

  const pinContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    perspective: "1000px",
    pointerEvents: "auto",
  };

  const pinStyle: CSSProperties = {
    position: "absolute",
    left: "50%", // Center horizontally
    top: "50%", // Center vertically
    transform: "translate(-50%, -50%)", // Adjust for centering
    padding: "1rem",
    borderRadius: "1rem",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)",
    backgroundColor: "black",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    transition: "transform 0.7s",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transformOrigin: "center",
    height: "32rem",
    width: "14rem",
    pointerEvents: "auto", // Ensure the pin itself can capture pointer events
  };

  const lineStyle: CSSProperties = {
    position: "absolute",
    width: "1px",
    height: "calc(20rem)",
    background: "linear-gradient(to top, cyan, cyan 1%, transparent)",
    margin: "0 auto",
    opacity: hovered ? 1 : 0,
    transition: "opacity 0.7s",
    left: "50%",
    transform: "translateX(-50%)",
    pointerEvents: "none", // Prevent the line from capturing pointer events
  };

  const linkStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    textDecoration: "none",
    color: "white",
    fontSize: "0.75rem",
    fontWeight: "bold",
    padding: "0.5rem 1rem",
    backgroundColor: "black",
    borderRadius: "9999px",
    boxShadow: "0 0 0 1px rgba(255, 255, 255, 0.1)",
    opacity: hovered ? 1 : 0,
    transition: "opacity 0.7s",
    position: "absolute",
    bottom: "calc(100% + 1rem)",
    left: "50%",
    transform: "translateX(-50%)",
    whiteSpace: "nowrap",
    zIndex: 1000,
    pointerEvents: "auto", // Ensure the link can capture pointer events
  };

  const titleStyle: CSSProperties = {
    maxWidth: "100%",
    paddingBottom: "0.5rem",
    margin: "0",
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: "white",
    textAlign: "left", // Align text to the start
  };

  const descriptionStyle: CSSProperties = {
    fontSize: "1.2rem",
    margin: "0",
    padding: "0",
    color: "rgba(128, 128, 128, 1)",
    textAlign: "left", // Align text to the start
  };

  const gradientStyle: CSSProperties = {
    flex: 1,
    width: "100%",
    height: "100%",
    borderRadius: "0.5rem",
    marginTop: "1rem",
    background: "linear-gradient(to bottom right, #007ae4, #98fbc1)",
    boxSizing: "border-box", // Ensure padding is included in the element's total width and height
    overflow: "hidden", // Prevent the image from overflowing
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  };

  const perspectiveStyle: CSSProperties = {
    width: "24rem",
    height: "20rem",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%) rotateX(70deg)",
    opacity: hovered ? 1 : 0,
    transition: "opacity 0.7s",
    transformOrigin: "center",
  };

  const contentStyle: CSSProperties = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align content to the start
    padding: "1rem",
    color: "rgba(255, 255, 255, 0.5)",
    height: "100%", // Ensure content fills the pin
    width: "100%", // Ensure content fills the pin
  };

  const circleStyle: CSSProperties = {
    position: "absolute",
    left: "50%",
    top: "calc(50% + 10rem)", // Adjust based on pin height to center it
    width: "11.25rem",
    height: "11.25rem",
    borderRadius: "50%",
    backgroundColor: "rgba(135, 206, 235, 0.08)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)",
    transform: "translate(-50%, -50%)",
    animation: "pulse 6s infinite",
  };

  const tinyCircleStyle: CSSProperties = {
    position: "absolute",
    left: "50%", // Center it horizontally
    bottom: "-2px", // Adjust to position at the bottom of the line
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: "cyan",
    zIndex: 1,
    transform: "translateX(-50%)", // Center it horizontally
  };

  const iphoneFrameContainerStyle: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${iphoneFrame})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    pointerEvents: "none", // Ensure the frame itself does not capture pointer events
    zIndex: 30, // Higher zIndex to render on top
  };

  const wrapperStyle: CSSProperties = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: transform,
    transition: "transform 0.7s",
    transformOrigin: "center",
    width: "25rem",
    height: "35rem",
    perspective: "1000px",
    pointerEvents: "auto", // Ensure the wrapper itself can capture pointer events
  };

  return (
    <div
      style={containerStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div style={pinContainerStyle}>
        <a
          href="https://twitter.com/mannupaaji"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Order Wash at Your Location!
        </a>

        <div style={wrapperStyle}>
   
          {/* New wrapper container */}
          <div style={pinStyle}>
            <div style={contentStyle}>
              <h3 style={titleStyle}>RINZ</h3>
              <div style={descriptionStyle}>
                Effortless Car Wash Services Delivered to Your Home.
              </div>
              <div style={gradientStyle}>
                <img
                  src={mapImage}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.5rem",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
          <div style={iphoneFrameContainerStyle}></div>
          {/* iPhone frame on top */}
        </div>

        <div style={perspectiveStyle}>
          <div style={circleStyle}></div>
          <div style={{ ...circleStyle, animationDelay: "2s" }}></div>
          <div style={{ ...circleStyle, animationDelay: "4s" }}></div>
          <div style={lineStyle}></div>
          <div style={tinyCircleStyle}></div>
        </div>
      </div>
      <style>
        {`
                @keyframes pulse {
                    0%, 100% {
                        opacity: 0;
                        transform: translate(-50%, -50%) scale(0);
                    }
                    50% {
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            `}
      </style>
    </div>
  );
};

export default HomePin;
