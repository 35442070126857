import React, { useState } from "react";

import exteriorHandImg from '../../assets/exteriorhand.png'; // Adjust the path as needed
import interiorWipeImg from '../../assets/interiorwipe.png'; // Adjust the path as needed
import interiorImg from '../../assets/interior.png'; // Adjust the path as needed
import handWaxingImg from '../../assets/handwaxing.png'; // Adjust the path as needed
import { FaSearch } from "react-icons/fa";


const services = [
  {
    name: "Exterior Wash",
    icon: (
      <div style={{ width: '120px', height: '90px', overflow: 'hidden', marginBottom:15,marginTop:5, borderRadius: 8}}>
        <img src={exteriorHandImg} alt="Exterior Wash" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
    ),
  },
  {
    name: "Full-Service Wash",
    icon: (
      <div style={{ width: '120px', height: '90px', overflow: 'hidden', marginBottom:15,marginTop:5, borderRadius: 8}}>
        <img src={interiorWipeImg} alt="Full-Service Wash" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
    ),
  },
  {
    name: "Hand Waxing",
    icon: (
      <div style={{ width: '120px', height: '90px', overflow: 'hidden', marginBottom:15,marginTop:5, borderRadius: 8}}>
        <img src={handWaxingImg} alt="Hand Waxing" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
    ),
  },
  {
    name: "Interior Detailing",
    icon: (
      <div style={{ width: '120px', height: '90px', overflow: 'hidden', marginBottom:15,marginTop:5, borderRadius: 8}}>
        <img src={interiorImg} alt="Interior Detailing" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
    ),
  },
];

const AddServicesPage = ({ selectedServices, setSelectedServices }:any) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredServices = services.filter((service) =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleServiceSelection = (serviceName: string) => {
    setSelectedServices((prevSelectedServices:any) => ({
      ...prevSelectedServices,
      [serviceName]: !prevSelectedServices[serviceName],
    }));
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        <div style={{ position: "relative", width: "60%", minWidth: 500,marginRight:56 }}>
          <FaSearch
            size={20}
            style={{
              position: "absolute",
              left: 20, // Increased left position
              top: "50%",
              transform: "translateY(-50%)",
              color: "#ccc",
            }}
          />
          <input
            type="text"
            placeholder="Search Service"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: "100%",
              padding: "12px 12px 12px 48px", // Increased left padding
              fontSize: "22px",
              fontFamily: 'Roboto-Bold',
              borderRadius: "15px",
              border: "1px solid #ccc",
              backgroundColor: '#fafcfe',
              textAlign: "left",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "grid",
          width: "100%", // Full width
          maxWidth: "900px", // Maximum width of the grid container
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))", // This creates as many 150px columns as will fit
          gap: "10px",
          justifyContent: "center", // Centers the grid items when fewer than the max in a row
          margin: "0 auto", // Centers the grid container
        }}
      >
        {filteredServices.map((service, index) => (
          <div
            key={index}
            onClick={() => toggleServiceSelection(service.name)} // 3. Add the onClick event
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              cursor: "pointer", // 4. Change the cursor to pointer to indicate the item is clickable
              //@ts-ignore
              backgroundColor: selectedServices[service.name] ? "#cce4fa" : "#dee1e3", // 2. Change background color if selected
            }}
          >
            <div style={{ fontSize: "24px" }}>{service.icon}</div>
            <span style={{fontFamily:'Roboto-Medium'}}>{service.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddServicesPage;
