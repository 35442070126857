import React, { useEffect, useState } from "react";
import {
  MdAccountCircle,
  MdLocationOn,
  MdOutlineCalendarMonth,
} from "react-icons/md";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TitleBar from "./TitleBar";
import { BiSolidCarWash } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { formatBase64Image } from "../Functions";
import BookingDetail from "./BookingDetail";
import NoRecords from "../NoRecords";
import LoadingSpinner from "../LoadingSpinner";
import { Customer, Order } from "../store/actions/types";
import { RootState } from "../store/reducers";

const defaultCustomer: Customer = {
  uid: "",
  firstName: "Unknown",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  profileImage: "",
  dateOfBirth: "",
  createdAt: "",
  gender: "",
  stripeCustomerId: "",
  verified: false,
  addresses: [],
  vehicles: [],
};

const Tabs = ({
  activeTab,
  onTabClick,
}: {
  activeTab: string;
  onTabClick: (tab: string) => void;
}) => {
  return (
    <div style={styles.tabs}>
      {["Ongoing", "Scheduled", "Complete", "Canceled"].map((tab) => (
        <div
          key={tab}
          style={activeTab === tab ? styles.activeTab : styles.tab}
          onClick={() => onTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

const OrderCard = ({
  order,
  customer,
  onClick,
}: {
  order: Order;
  customer: Customer;
  onClick: () => void;
}) => {
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
  };

  return (
    <div style={styles.orderCard} onClick={onClick}>
      <div style={styles.orderCardHeader}>
        {customer.profileImage ? (
          <img
            src={formatBase64Image(customer.profileImage)}
            alt="Customer Profile Icon"
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "50%",
              border: "3px solid #007ae4",
              marginRight: "15px",
              objectFit: "cover",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
            }}
          />
        ) : (
          <MdAccountCircle size={50} style={styles.avatarIcon} />
        )}
        <div style={styles.orderDetails}>
          <div style={styles.infoItem}>
            <h3 style={styles.userName}>
              {customer.firstName} {customer.lastName}
            </h3>
          </div>
          <div style={styles.infoItem}>
            <MdLocationOn size={20} style={styles.icon} />
            <span style={styles.ellipsis}>
              {`${order.address.streetAddress}, ${order.address.cityAddress}, ${order.address.stateAddress} ${order.address.zipCodeAddress}`}
            </span>
          </div>
          <div style={styles.infoItem}>
            <MdOutlineCalendarMonth size={20} style={styles.icon} />
            <span style={styles.ellipsis}>
              {formatDate(order.dateOrderPlaced)}
            </span>
          </div>
          <div style={styles.infoItem}>
            <span>Service: </span>
            <span style={styles.serviceLabel}>
              <BiSolidCarWash
                size={20}
                style={{ ...styles.icon, marginRight: 5, marginLeft: 0 }}
              />
              {order.service}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrdersGrid: React.FC<{
  orders: Order[];
  customers: Customer[];
  onOrderClick: (order: Order) => void;
}> = ({ orders, customers, onOrderClick }) => {
  if (orders.length === 0) {
    return <NoRecords />;
  }

  return (
    <div style={styles.orderGrid}>
      {orders.map((order, index) => {
        const customer =
          customers.find((c) => c.uid === order.orderedBy) || defaultCustomer;
        return (
          <OrderCard
            key={index}
            order={order}
            customer={customer}
            onClick={() => onOrderClick(order)}
          />
        );
      })}
    </div>
  );
};

const Bookings = () => {
  const [activeTab, setActiveTab] = useState<string>("Ongoing");
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  const orders = useSelector((state: RootState) => state.orders.orders);
  const loading = useSelector((state: RootState) => state.orders.loading);
  const customers = useSelector(
    (state: RootState) => state.customers.customers
  );
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const auth = getAuth();

  useEffect(() => {
    if (selectedOrderId) {
      const updatedOrder = orders.find((order) => order.id === selectedOrderId);
      if (updatedOrder) {
        setSelectedOrder(updatedOrder);
      }
    }
  }, [selectedOrderId]); // Remove orders from the dependency array

  /*
  useEffect(() => {
    const fetchAndListenOrders = async (uid: string) => {
      dispatch(fetchOrders(uid));
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchAndListenOrders(user.uid);
      }
    });

    return () => unsubscribe();
  }, [dispatch]);
 */

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const handleOrderClick = (order: Order) => {
    setSelectedOrder(order);
    setSelectedOrderId(order.id);
  };

  const handleBackClick = () => {
    setSelectedOrder(null);
  };

  const filteredOrders = orders.filter(
    (order) =>
      (activeTab === "Ongoing" &&
        ["Pending", "Assigned", "Ongoing", "On Route"].includes(
          order.orderStatus
        )) ||
      order.orderStatus === activeTab
  );

  return (
    <div style={stylesApp.app}>
      <TitleBar title="Bookings" />
      {selectedOrder ? (
        <BookingDetail
          order={selectedOrder}
          customer={
            customers.find((c) => c.uid === selectedOrder.orderedBy) ||
            defaultCustomer
          }
          onBack={handleBackClick}
        />
      ) : (
        <>
          <Tabs activeTab={activeTab} onTabClick={handleTabClick} />
          {loading ? (
            <div style={{marginTop:'-30%'}}>
              <LoadingSpinner /> 
            </div>
          ) : (
            <OrdersGrid
              orders={filteredOrders}
              customers={customers}
              onOrderClick={handleOrderClick}
            />
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  tabs: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    fontSize: "18px",
    justifyContent: "center",
    padding: "10px",
    marginLeft: "17px",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    marginBottom: "20px",
    maxWidth: 500,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  tab: {
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "10px",
    margin: "0 5px",
  },
  activeTab: {
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "10px",
    margin: "0 5px",
    backgroundColor: "#007bff",
    color: "white",
  },
  orderGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
    gap: "20px",
    padding: "20px",
  } as React.CSSProperties,

  orderCard: {
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column" as "column",
  },
  orderCardHeader: {
    display: "flex",
    alignItems: "flex-start",
  },
  avatarIcon: {
    color: "#007ae4",
    marginRight: "15px",
  },
  orderDetails: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  userName: {
    margin: 0,
    fontSize: "20px",
    fontFamily: "Poppins, Poppins, sans-serif",
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  icon: {
    marginRight: "5px",
    color: "#007ae4",
  },
  ellipsis: {
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis" as "ellipsis",
  },
  serviceLabel: {
    display: "inline-flex",
    alignItems: "center",
    padding: "5px 10px",
    borderRadius: "5px",
    backgroundColor: "#e0f7e9",
    color: "#007b5e",
    fontSize: "14px",
    marginLeft: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.07)",
  },
};

const stylesApp = {
  app: {
    textAlign: "center" as "center",
    padding: "20px",
  },
};

export default Bookings;
