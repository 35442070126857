import React, { useState, useEffect, useContext, useRef } from "react";
import { activeColor } from "../../styles";
import { IoIosArrowBack } from "react-icons/io";
import dripLogo from "../../assets/driplogo2.png"; // Importing the image
import check from "../../assets/check.png"; // Importing the image
import hourGlass from "../../assets/hourglass.png"; // Importing the image
import appstorepng from "../../assets/appstore2.png"; // Importing the image
import defaultProfilePic from "../../assets/profile.png"; // Adjust the path as necessary

import "../../App.css";

import {
  IoCamera,
  IoCloudDone,
  IoCloudUpload,
  IoPerson,
} from "react-icons/io5";
import { convertImageToBase64, formatBase64Image } from "../Functions";
import MapComponent from "../Map";
import AddServicesPage from "./AddServicesPage"; // Adjust the path if the file is in a subdirectory
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "../../App";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "../TopBar";
import { useNavigate } from "react-router-dom";

const pages = [
  {
    title: "Terms & Conditions",
    lastUpdated: "October 2023",
    sections: [
      {
        header: "What is Lorem Ipsum?",
        text: "Office ipsum you must be muted. Keywords engagement room follow welcome any dogpile unlock incentivize. Like crank bake like economy reference solutionize every new. Work baseline now plane be half note I. Conversation buy-in due we've churning solutionize prioritize fit. Territories principles nobody food innovation agile nail good replied existing. Request submit before finish intersection value-added high-level vendor but incentivize. Standup game break charts unit open then break player-coach. Diarize idea diarize without q1 another wheel. Business boil low long clean thought. Finish adoption fruit keep go timepoint language. Best ping container as work. Big before at air churning other. Also club policy got customer inclusion tentative.",
      },
      {
        header: "Why do we use it?",
        text: "Office ipsum you must be muted. Shark standup shoulder shark enable the high sexy a anomalies. Teams vec level tentative obviously. About savvy message alarming here spaces rehydrate management boil net. Could regroup lunch loop a got meeting charts follow blue. Meat initiative well forward standup window hill feed. Cc going dive status marketing only flesh. Brainstorming want wanted about more moving game optimal keep stronger. Crank deep involved alarming scraps game in on spaces. Skulls ballpark that stop intersection i'm one got backwards another. Client only hammer get commitment. Shower optimal team but ourselves meaningful eod. As panel pretend brainstorming red-flag invested info. A cc move own hurting would culture. Circle thought walk fastworks do walk picture market baseline. Invite paradigm well intersection message make shoot. Seat bake then keep points underlying needle sky. Hour cta ui working breakout. Walk solutionize revision culture ui hiring ground. Net guys here building cadence. But minimize already circle tentative also fit know.",
      },
      // Add more sections as needed
    ],
    buttonText: "Continue",
  },
  {
    title: "Privacy Policy",
    lastUpdated: "October 2023",
    sections: [
      {
        header: "What is Lorem Ipsum?",
        text: "Office ipsum you must be muted. Keywords engagement room follow welcome any dogpile unlock incentivize. Like crank bake like economy reference solutionize every new. Work baseline now plane be half note I. Conversation buy-in due we've churning solutionize prioritize fit. Territories principles nobody food innovation agile nail good replied existing. Request submit before finish intersection value-added high-level vendor but incentivize. Standup game break charts unit open then break player-coach. Diarize idea diarize without q1 another wheel. Business boil low long clean thought. Finish adoption fruit keep go timepoint language. Best ping container as work. Big before at air churning other. Also club policy got customer inclusion tentative.",
      },
      {
        header: "Why do we use it?",
        text: "Office ipsum you must be muted. Shark standup shoulder shark enable the high sexy a anomalies. Teams vec level tentative obviously. About savvy message alarming here spaces rehydrate management boil net. Could regroup lunch loop a got meeting charts follow blue. Meat initiative well forward standup window hill feed. Cc going dive status marketing only flesh. Brainstorming want wanted about more moving game optimal keep stronger. Crank deep involved alarming scraps game in on spaces. Skulls ballpark that stop intersection i'm one got backwards another. Client only hammer get commitment. Shower optimal team but ourselves meaningful eod. As panel pretend brainstorming red-flag invested info. A cc move own hurting would culture. Circle thought walk fastworks do walk picture market baseline. Invite paradigm well intersection message make shoot. Seat bake then keep points underlying needle sky. Hour cta ui working breakout. Walk solutionize revision culture ui hiring ground. Net guys here building cadence. But minimize already circle tentative also fit know.",
      },
      // Add more sections as needed
    ],
    buttonText: "Continue",
  },
  {
    title: "Welcome to Drip Auto Care",
    sections: [
      {
        header: "Dear Contractor",
        text: "Thank you for your interest in becoming a Drip Auto Care Affiliated Service Provider. Drip Auto Care is revolutionizing the car wash industry by offering quick and convenient services. As an affiliated service provider, you'll have the opportunity to grow your business and reach new customers.",
      },
      {
        header: "Instructions",
        text: "Do you love cars and want to make money by providing excellent mobile detailing services? If so, you should join Drip Auto Care, the technology company that connects you to customers who need car washes on demand. Drip Auto Care has a web-app, and mobile client apps for customers and employees, so you can easily manage your bookings, payments, and ratings. The platform strives to provide customers with very timely and quality service car washes and increase volume and revenue for service providers. You can work whenever you want, wherever you want, and earn more money than ever before.                     To become a member of Drip Auto Care, simply fill out the sections. You will need to provide some basic information. Once you submit your application, we will review it and get back to you within 24 hours. If you are approved, you will be able to start accepting jobs and enjoy the benefits of being part of the Drip Auto Care community.",
      },
      // Add more sections as needed
    ],
    buttonText: "Continue",
  },

  {
    title: "Enter Business Name",
    sections: [], // Empty sections array indicates this page will have input fields
    inputs: [
      // Define input fields for this page
      {
        name: "businessName",
        label: "Business Name",
        placeholder: "Enter Business Name",
        value: "",
        type: "text",
      },
      // ... Add other business information inputs
    ],
    buttonText: "Continue",
  },
  {
    title: "Facility Type",
    sections: [],
    selects: [
      {
        name: "facilityType",
        label: "Select Facility Type",
        options: [
          { value: "select", label: "Please Select Facility type" },
          { value: "type1", label: "Type 1" },
          { value: "type2", label: "Type 2" },
          { value: "type3", label: "Type 3" },
        ],
      },
    ],
    buttonText: "Continue",
  },
  {
    title: "Enter Your Phone Number",
    sections: [],
    inputs: [
      {
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone number",
        value: "",
        type: "text",
      },
    ],
    buttonText: "Continue",
  },

  {
    title: "Upload Profile Picture",
    sections: [],
    image: null,
    buttonText: "Continue",
  },

  {
    title: "Enter Business Address",
    sections: [],
    inputs: [
      {
        name: "address",
        label: "Address",
        placeholder: "Enter Address",
        value: "",
        type: "text",
      },
      {
        name: "city",
        label: "City",
        placeholder: "Enter City",
        value: "",
        type: "text",
      },
      {
        name: "province",
        label: "Province",
        placeholder: "Enter Province",
        value: "",
        type: "text",
      },
      {
        name: "postcode",
        label: "Postcode",
        placeholder: "Enter Postcode",
        value: "",
        type: "text",
      },
    ],
    buttonText: "Continue",
  },
  {
    title: "Confirm Location",
    buttonText: "Continue",
  },
  {
    title: "Working Time",
    sections: [], // No predefined sections, dynamic content will be rendered
    dayToggles: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    buttonText: "Continue",
  },

  {
    title: "Enter Tax Identification",
    sections: [],
    inputs: [
      {
        name: "taxIdentification",
        label: "Tax Identification",
        placeholder: "Enter Tax Identification",
        value: "",
        type: "text",
      },
    ],
    buttonText: "Continue",
  },
  {
    title: "Owners Details",
    sections: [],
    inputs: [
      {
        name: "ownerName",
        label: "Owner Name",
        placeholder: "Enter Owner Name",
        value: "",
        type: "text",
      },
      {
        name: "ownerPhoneNumber",
        label: "Owner Phone Number",
        placeholder: "Enter Owner Phone Number",
        value: "",
        type: "tel",
      },
    ],
    buttonText: "Continue",
  },
  {
    title: "Owner's Background Check",
    sections: [],
    inputs: [
      {
        name: "socialSecurityNumber",
        label: "Social Security Number",
        placeholder: "Enter SSN",
        value: "",
        type: "text",
      },
      {
        name: "homeAddress",
        label: "Home Address",
        placeholder: "Enter Home Address",
        value: "",
        type: "text",
      },
    ],
    selects: [
      {
        name: "birthMonth",
        label: "Month",
        options: Array.from({ length: 12 }, (_, i) => ({
          value: `${i + 1}`,
          label: new Date(0, i).toLocaleString("default", { month: "long" }),
        })),
      },
      {
        name: "birthDay",
        label: "Day",
        options: Array.from({ length: 31 }, (_, i) => ({
          value: `${i + 1}`,
          label: `${i + 1}`,
        })),
      },
      {
        name: "birthYear",
        label: "Year",
        options: Array.from({ length: 100 }, (_, i) => ({
          value: `${2023 - i}`,
          label: `${2023 - i}`,
        })),
      },
    ],
    buttonText: "Continue",
  },

  {
    title: "Enter Dispatcher Details",
    sections: [],
    inputs: [
      {
        name: "dispatcherPhoneNumber",
        label: "Dispatcher Phone Number",
        placeholder: "Enter Dispatcher Phone Number",
        value: "",
        type: "tel",
      },
      {
        name: "dispatcherEmail",
        label: "Dispatcher Email",
        placeholder: "Enter Dispatcher Email",
        value: "",
        type: "email",
      },
    ],
    buttonText: "Continue",
  },
  {
    title: "Requirements",
    buttonText: "Continue",
  },
  {
    title: "Enter Bank Accounts",
    sections: [],
    inputs: [
      {
        name: "bankName",
        label: "Bank Name",
        placeholder: "Enter Bank Name",
        value: "",
        type: "text",
      },
      {
        name: "accountHolderName",
        label: "Account Holder Name",
        placeholder: "Enter Account Holder Name",
        value: "",
        type: "text",
      },
      {
        name: "accountNumber",
        label: "Account Number",
        placeholder: "Enter Account Number",
        value: "",
        type: "text",
      },
    ],
    buttonText: "Continue",
  },

  {
    title: "Add Services",
    component: AddServicesPage,
    buttonText: "Continue",
  },
];
/*
{
  title: "Enter Social Media Links",
  sections: [],
  inputs: [
    {
      name: "facebookLink",
      label: "Facebook Link",
      placeholder: "Enter Facebook Link",
      value: "",
      type: "url",
    },
    {
      name: "twitterLink",
      label: "Twitter Link",
      placeholder: "Enter Twitter Link",
      value: "",
      type: "url",
    },
    {
      name: "instagramLink",
      label: "Instagram Link",
      placeholder: "Enter Instagram Link",
      value: "",
      type: "url",
    },
  ],
  buttonText: "Continue",
},*/

interface InputValues {
  [key: string]: any; // Adding index signature
  businessName?: string;
  facilityType?: string;
  phoneNumber?: string;
  taxIdentification?: string;
  ownerName?: string;
  ownerPhoneNumber?: string;
  socialSecurityNumber?: string;
  homeAddress?: string;
  birthMonth?: string;
  birthDay?: string;
  birthYear?: string;
  dispatcherPhoneNumber?: string;
  dispatcherEmail?: string;
  bankName?: string;
  accountHolderName?: string;
  accountNumber?: string;
  facebookLink?: string;
  twitterLink?: string;
  instagramLink?: string;
  address?: string;
  city?: string;
  province?: string;
  postcode?: string;
}

const ProviderRegistration = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const image = new Image();
    image.src = dripLogo;
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [inputValues, setInputValues] = useState<InputValues>({});

  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false); //button loading

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true); // account status loading
  const [user, setUser] = useState(null);

  const [workingHours, setWorkingHours] = useState<WorkingHours>({});
  const [profileImage, setProfileImage] = useState(null);
  const [w9File, setW9File] = useState(null);
  const [insuranceFile, setInsuranceFile] = useState(null);
  const [isW9FileUploaded, setIsW9FileUploaded] = useState(false);
  const [isInsuranceFileUploaded, setIsInsuranceFileUploaded] = useState(false);

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedDays, setSelectedDays] = useState<DayToggles>({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  const [inputValidationStatus, setInputValidationStatus] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [validationTrigger, setValidationTrigger] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      //@ts-ignore
      setUser(user);
      if (user) {
        setLoading(true);
        const uid = user.uid;

        const providerRef = doc(db, "Providers", uid);
        const providerAppRef = doc(db, "ProviderApps", uid);

        const checkProviderStatus = async () => {
          const unsubscribeProvider = onSnapshot(
            providerRef,
            (providerSnap) => {
              if (providerSnap.exists()) {
                const providerData = providerSnap.data();
                const verified = providerData.verified;

                //console.log("verified:", verified); // Log the value of verified

                if (verified) {
                  setIsVerified(true);
                  setIsSubmitted(false); // Reset submitted status
                  setLoading(false);

                  window.location.reload(); // Refresh the page
                } else {
                  setIsVerified(false); // Set isVerified to false when verified changes to false
                  // Handle case when verified changes to false
                  const unsubscribeProviderApp = onSnapshot(
                    providerAppRef,
                    (providerAppSnap) => {
                      if (providerAppSnap.exists()) {
                        const providerAppData = providerAppSnap.data();

                        const profilePictureFromDB =
                          providerAppData.profilePicture;
                        if (profilePictureFromDB) {
                          setProfileImage(profilePictureFromDB);
                        }

                        // Load the base64-encoded W9 file from the database
                        const w9FileFromDB = providerAppData.w9File;
                        if (w9FileFromDB) {
                          setW9File(w9FileFromDB);
                          setIsW9FileUploaded(true); // Set the boolean flag to true since the file is loaded
                        }

                        // Load the base64-encoded insurance file from the database
                        const insuranceFileFromDB =
                          providerAppData.insuranceFile;
                        if (insuranceFileFromDB) {
                          setInsuranceFile(insuranceFileFromDB);
                          setIsInsuranceFileUploaded(true); // Set the boolean flag to true since the file is loaded
                        }

                        const locationCoordinatesFromDB =
                          providerAppData.locationCoordinates;
                        if (locationCoordinatesFromDB) {
                          setLocationCoordinates(locationCoordinatesFromDB);
                        }

                        const selectedDaysFromDB = providerAppData.selectedDays;
                        if (selectedDaysFromDB) {
                          setSelectedDays(selectedDaysFromDB);
                        }

                        // Load the working hours from the database
                        const workingHoursFromDB = providerAppData.workingHours;
                        if (workingHoursFromDB) {
                          setWorkingHours(workingHoursFromDB);
                        }

                        if (providerAppData.submitted) {
                          setIsSubmitted(true);
                        } else {
                          setIsSubmitted(false); // Set isSubmitted to false when submitted changes to false
                        }

                        // Load the page based on leftOn and fill in the previous pages with data
                        const leftOn = providerAppData.leftOn;
                        const pageIndex = pages.findIndex(
                          (page) => page.title === leftOn
                        );

                        if (pageIndex !== -1) {
                          showContinueToastMessage(); // Call the function here

                          setCurrentPage(pageIndex);
                          setInputValues(providerAppData);
                        }

                        setLoading(false);
                      }
                      leftOffShownRef.current = true;
                      setLoading(false); //very important these two
                    }
                  );
                  return () => unsubscribeProviderApp();
                }
              }
            }
          );

          //setLoading(false);
          return () => unsubscribeProvider();
        };

        checkProviderStatus();
      } else {
        setLoading(false);
      }
    });
    return () => {
      unsubscribeAuth();
    };
  }, [auth]);

  const leftOffShownRef = useRef(false);

  const showContinueToastMessage = () => {
    if (!leftOffShownRef.current) {
      toast.info("Continuing where you left off...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      leftOffShownRef.current = true;
    }
  };

  const handleDayToggle = (day: Day) => {
    const newSelectedDays = { ...selectedDays, [day]: !selectedDays[day] };
    setSelectedDays(newSelectedDays);

    if (!newSelectedDays[day]) {
      const newWorkingHours = { ...workingHours };
      delete newWorkingHours[day];
      setWorkingHours(newWorkingHours);
    } else {
      // Initialize working hours for the selected day if not already set
      if (!workingHours[day]) {
        setWorkingHours((prevWorkingHours) => ({
          ...prevWorkingHours,
          [day]: { startTime: "", endTime: "" },
        }));
      }
    }
  };

  const handleTimeChange = (
    day: Day,
    timeType: "startTime" | "endTime",
    value: string
  ) => {
    setWorkingHours((prevWorkingHours) => ({
      ...prevWorkingHours,
      [day]: {
        ...prevWorkingHours[day],
        [timeType]: value,
      },
    }));
  };

  const handleInputChange = (name: any, value: any) => {
    if (name === "taxIdentification") {
      // Automatically add a dash after the second digit and limit to 9 digits
      value = value
        .replace(/[^\d]/g, "") // Remove non-digits
        .slice(0, 9) // Limit the length to 9 digits
        .replace(/(\d{2})(\d{0,7})/, "$1-$2"); // Insert dash after second digit
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };

  // Helper function to show toast messages
  const showToastError = (message: any) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Validation function for the "Enter Business Name" page
  const validateBusinessName = () => {
    if (!inputValues.businessName || inputValues.businessName.length < 3) {
      showToastError("Business name must be at least 3 characters.");
      return false;
    }
    return true;
  };

  // Validation function for the "Facility Type" page
  const validateFacilityType = () => {
    if (!inputValues.facilityType || inputValues.facilityType === "select") {
      showToastError("Please select a facility type.");
      return false;
    }
    return true;
  };

  // Validation function for the "Enter Your Phone Number" page
  const validatePhoneNumber = () => {
    if (
      !inputValues.phoneNumber ||
      !/^\d{10,}$/.test(inputValues.phoneNumber)
    ) {
      showToastError("Please enter a valid phone number (minimum 10 digits).");
      return false;
    }
    return true;
  };

  // Validation function for the "Upload Profile Picture" page
  const validateProfilePicture = () => {
    /*
    if (!profileImage) {
      const confirmSkip = window.confirm(
        "Are you sure you want to skip uploading a profile picture?"
      );
      if (confirmSkip) {
        //setProfileImage(defaultProfilePic);  // Set a default profile picture

        return true;
      }
      return false;
    }*/

    return true;
  };

  // Validation function for the "Enter Business Address" page
  const validateBusinessAddress = () => {
    let isValid = true;
    const addressFields = ["address", "city", "province", "postcode"];

    addressFields.forEach((field) => {
      if (!inputValues[field] || inputValues[field].trim().length < 3) {
        showToastError(`${field} must be at least 3 characters long.`);
        isValid = false;
      }
    });

    return isValid;
  };

  // Validation function for "Confirm Location" page
  const validateConfirmLocation = () => {
    if (!locationCoordinates) {
      showToastError("Please select a location on the map.");
      return false;
    }
    return true;
  };

  // Validation function for "Working Time" page
  const validateWorkingTime = () => {
    const selectedDaysArray = Object.keys(selectedDays).filter(
      (day) => selectedDays[day]
    );
    if (selectedDaysArray.length === 0) {
      showToastError("Please select at least one day.");
      return false;
    }

    let isValid = true;
    selectedDaysArray.forEach((day) => {
      const { startTime, endTime } = workingHours[day];
      if (!startTime || !endTime) {
        showToastError(`Please provide start and end times for ${day}.`);
        isValid = false;
      }
    });

    return isValid;
  };

  // Validation function for "Enter Tax Identification" page
  const validateTaxIdentification = () => {
    if (
      !inputValues.taxIdentification ||
      !/^(\d{2}-\d{4,})$/.test(inputValues.taxIdentification)
    ) {
      showToastError(
        "Tax identification must be a number with at least 6 digits starting with 2 digits followed by a dash."
      );
      return false;
    }
    return true;
  };

  // Validation function for "Owner's Details" page
  const validateOwnerDetails = () => {
    let isValid = true;

    if (!inputValues.ownerName || inputValues.ownerName.trim().length < 3) {
      showToastError("Owner's name must be at least 3 characters long.");
      isValid = false;
    }

    if (
      !inputValues.ownerPhoneNumber ||
      !/^\d{10,}$/.test(inputValues.ownerPhoneNumber)
    ) {
      showToastError(
        "Owner's phone number must be a number with at least 10 digits."
      );
      isValid = false;
    }

    return isValid;
  };

  const validateOwnerBackgroundCheck = () => {
    let isValid = true;

    if (
      !inputValues.socialSecurityNumber ||
      !/^\d{9,}$/.test(inputValues.socialSecurityNumber)
    ) {
      showToastError(
        "Social Security Number must be a number with at least 9 digits."
      );
      isValid = false;
    }

    if (!inputValues.homeAddress || inputValues.homeAddress.trim().length < 4) {
      showToastError("Home address must be at least 4 characters long.");
      isValid = false;
    }

    /*
    const birthMonth = inputValues.birthMonth;
    const birthDay = inputValues.birthDay;
    const birthYear = inputValues.birthYear;

    const isDateOfBirthValid =
      birthMonth !== "" &&
      birthDay !== "" &&
      birthYear !== "" &&
      !isNaN(parseInt(birthMonth ?? "0")) &&
      !isNaN(parseInt(birthDay ?? "0")) &&
      !isNaN(parseInt(birthYear ?? "0"));

    if (isDateOfBirthValid) {
      const selectedDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - selectedDate.getFullYear();

      if (age < 18) {
        showToastError("User must be at least 18 years old.");
        isValid = false;
      }
    } else {
      showToastError("Please select a valid date of birth.");
      isValid = false;
    }*/

    return isValid;
  };

  // Validation function for "Enter Dispatcher Details" page
  const validateDispatcherDetails = () => {
    let isValid = true;

    if (
      !inputValues.dispatcherPhoneNumber ||
      !/^\d{10,}$/.test(inputValues.dispatcherPhoneNumber)
    ) {
      showToastError(
        "Dispatcher's phone number must be a number with at least 10 digits."
      );
      isValid = false;
    }

    if (
      !inputValues.dispatcherEmail ||
      !/^\S+@\S+\.\S+$/.test(inputValues.dispatcherEmail)
    ) {
      showToastError("Please enter a valid email address.");
      isValid = false;
    }

    return isValid;
  };

  const validateBankAccounts = () => {
    let isValid = true;

    if (!inputValues.bankName || inputValues.bankName.length < 4) {
      showToastError("Bank name must be at least 4 characters long.");
      isValid = false;
    }

    if (
      !inputValues.accountHolderName ||
      inputValues.accountHolderName.length < 4
    ) {
      showToastError("Account holder name must be at least 4 characters long.");
      isValid = false;
    }

    if (
      !inputValues.accountNumber ||
      !/^\d{9,}$/.test(inputValues.accountNumber)
    ) {
      showToastError(
        "Account number must be all digits and at least 9 characters long."
      );
      isValid = false;
    }

    return isValid;
  };

  const validateSocialMediaLinks = () => {
    let isValid = true;

    /*
    if (inputValues.facebookLink && inputValues.facebookLink.length < 6) {
      showToastError("Facebook link must be at least 6 characters long.");
      isValid = false;
    }

    if (inputValues.twitterLink && inputValues.twitterLink.length < 6) {
      showToastError("Twitter link must be at least 6 characters long.");
      isValid = false;
    }

    if (inputValues.instagramLink && inputValues.instagramLink.length < 6) {
      showToastError("Instagram link must be at least 6 characters long.");
      isValid = false;
    }
    */

    return isValid;
  };

  const validateRequirements = () => {
    if (!w9File || !insuranceFile) {
      showToastError("Please upload both W9 and Insurance files.");
      return false;
    }
    return true;
  };

  const validateAddServices = () => {
    if (selectedServices.length === 0) {
      showToastError("Please select at least one service.");
      return false;
    }
    return true;
  };

  const validateInputs = () => {
    switch (currentPage) {
      case 3:
        return validateBusinessName();
      case 4:
        return validateFacilityType();
      case 5:
        return validatePhoneNumber();
      case 6:
        return validateProfilePicture();
      case 7:
        return validateBusinessAddress();
      case 8:
        return validateConfirmLocation();
      case 9:
        return validateWorkingTime();
      case 10:
        return validateTaxIdentification();
      case 11:
        return validateOwnerDetails();
      case 12:
        return validateOwnerBackgroundCheck();
      case 13:
        return validateDispatcherDetails();
      case 14:
        return validateRequirements(); // Assuming there's a validation function for page 14
      case 15:
        return validateBankAccounts();
      /*
      case 16:
        return validateSocialMediaLinks();*/
      case 16:
        return validateAddServices();
      // Include additional cases as needed for other pages
      default:
        return true;
    }
  };

  const nextPage = async () => {
    if (currentPage >= 2) {
      const isValid = validateInputs();
      if (!isValid) {
        return;
      }
    }

    // If it's one of the first three pages, increment the page without updating Firestore.
    if (currentPage < 3 && currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
      return;
    }

    // For pages beyond the third, update Firestore and then increment the page.
    if (currentPage >= 3 && currentPage < pages.length) {
      setIsLoading(true);
      try {
        const user = auth.currentUser;
        //console.log("here" + w9File);

        if (user) {
          const providerAppData = {
            ...inputValues,
            submitted: pages[currentPage].title === "Add Services",
            // Update leftOn with the title of the current page
            leftOn: pages[currentPage].title,
            profileImage: profileImage || null,
            // Upload the location coordinates if they exist
            locationCoordinates: locationCoordinates || null,
            // Upload the W9 file if it exists
            w9File: w9File || null,
            // Upload the insurance file if it exists
            insuranceFile: insuranceFile || null,

            workingHours: workingHours || null,
            selectedServices: selectedServices || null,
            selectedDays: selectedDays || null,
            emailAddress: user.email, // Add the user's email address
          };
          await setDoc(doc(db, "ProviderApps", user.uid), providerAppData, {
            merge: true,
          });
          // Increment the page after successful Firestore update, if not already at the last page
          if (currentPage < pages.length - 1) {
            setCurrentPage(currentPage + 1);
          }
        }
      } catch (error) {
        console.error("Error updating provider application: ", error);
        alert("An error occurred while updating the provider application.");
      } finally {
        setIsLoading(false);
      }
    } else if (currentPage >= pages.length - 1) {
      // Handle the submission or wrap around to the first page
      //setCurrentPage(0);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    } else {
      // Optionally, handle the wrap around to the last page or do nothing
      setCurrentPage(pages.length - 1);
    }
  };

  const content = pages[currentPage];

  useEffect(() => {
    const totalPages = pages.length - 3; // Exclude the first 3 pages
    const currentPageIndex = currentPage - 3; // Adjust current page index to exclude the first 3 pages
    const percentage = ((currentPageIndex + 1) / totalPages) * 100;
    setProgressPercentage(percentage);
  }, [currentPage, pages]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine card width based on window width
  const cardWidth = windowWidth > 1024 ? "85%" : "80%"; // More width on larger screens

  const titleStyles: React.CSSProperties = {
    fontFamily: "Roboto-Bold",

    fontWeight: "400",
    fontSize: "34px",
    color: "#007ae4",
    paddingBottom: "8px",
    textAlign: "center", // To center the text
  };

  const lastUpdatedStyles: React.CSSProperties = {
    fontFamily: "Roboto",
    fontSize: "12px",
    //color: "#666",
    //paddingBottom: "24px",
    textAlign: "center", // To center the text
  };

  const lastUpdatedLabelStyles: React.CSSProperties = {
    fontFamily: "Roboto",

    fontWeight: "800", // Makes text bold
    fontSize: "14px",
    color: "black",
    //paddingBottom: "24px",
    textAlign: "center",
  };

  const lastUpdatedDateStyles: React.CSSProperties = {
    fontSize: "14px",
    color: "#666",
    //paddingBottom: "24px",
    textAlign: "center",
  };

  const SectionHeaderStyles: React.CSSProperties = {
    fontFamily: "Roboto-Bold",

    fontWeight: "700",
    fontSize: "28px",
    color: "#333",
    paddingBottom: "8px",
    textAlign: "left", // Aligns the section headers to the left
  };

  // Adjust the body styles to have a white background and padding
  const bodyStyles: React.CSSProperties = {
    fontFamily: "Roboto",

    fontSize: "16px",
    lineHeight: "1.6",
    color: "grey",
    padding: "20px",
    backgroundColor: "white", // Assuming a white background for the content area
    textAlign: "left", // Aligns the text to the left
    //borderTop: '1px solid #ccc', // Adds a separator line above the content
    //borderBottom: '1px solid #ccc', // Adds a separator line below the content
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "92vh",
    backgroundColor: "#f0f2f5",
  };

  const cardStyles: React.CSSProperties = {
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    width: cardWidth, // Width is already reactive
    position: "relative",
    overflowY: "auto",
    height: "100%", // This will make the card's height reactive to the parent container
    maxHeight: "85vh", // This ensures the card does not exceed the viewport height
    //maxWidth: "1500px", // Or any other value that suits your layout
    //padding: "130px",
  };

  const contentContainerStyles: React.CSSProperties = {
    paddingTop: "60px", // Add your desired padding here
    paddingBottom: "2%",
    paddingLeft: "5%",
    paddingRight: "34px",

    // Fill up available height
  };

  // Adjust close button styles to match the design
  const closeButtonStyles: React.CSSProperties = {
    position: "absolute",
    top: "44px",
    right: "44px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    fontSize: "24px",
  };

  const buttonStyles = {
    fontFamily: "Roboto-Bold",

    padding: "22px 24px",
    backgroundColor: "#007bff", // You can adjust this color to match the design
    color: "white",
    border: "none",
    borderRadius: "100px",
    cursor: "pointer",
    fontSize: "20px",
    display: "block", // Make button a block to fit width
    width: "100%", // Button width full container width
    maxWidth: "500px",
    margin: "0px auto", // Auto margin on the left and right will center the button
    marginTop: 40,
  };

  const inputStyles: React.CSSProperties = {
    width: "100%",
    padding: "12px",
    fontSize: "18px",
    borderRadius: "8px",
    border: "2px solid #e8edf3",
    fontFamily: "Roboto-Medium",
    color: "#7B7B7B",
    backgroundColor: "#fafcfe",

    maxWidth: "500px", // Match the button's max width
    boxSizing: "border-box", // Ensure padding is included in the width
  };

  const backArrowStyles: React.CSSProperties = {
    position: "absolute",
    top: "44px", // Match the vertical position of your close button
    left: "44px", // Position it from the left edge of the card
    fontSize: "24px", // Match the size of your close button
    cursor: "pointer",
    display: currentPage >= 1 ? "block" : "none", // Only show if currentPage > 1

    // Add any additional styling as needed
  };

  const sideBySideSelectsContainerStyle: React.CSSProperties = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    //flexWrap: "wrap", // This will allow the selects to wrap to a new line if needed
    marginBottom: "20px",
  };

  const selectStyle = {
    width: "100%", // This will make each select take up 20% of the parent width
    marginRight: "10px", // Add some spacing between the selects
  };

  const renderProgressBar = () => {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
      >
        <div style={{ width: "40%", backgroundColor: "#cce4fa" }}>
          <div
            style={{
              //position: "absolute",
              top: 0,
              left: 0,
              width: `${progressPercentage}%`,
              height: "4px",
              backgroundColor: "#007bff",
              transition: "width 0.3s ease-in-out",
            }}
          />
        </div>
      </div>
    );
  };

  const renderLastUpdated = (content: any) => {
    if (content.lastUpdated) {
      return (
        <div style={lastUpdatedStyles}>
          <span style={lastUpdatedLabelStyles}>Last updated: </span>
          <span style={lastUpdatedDateStyles}>{content.lastUpdated}</span>
        </div>
      );
    }
    return null; // If no lastUpdated is present, render nothing
  };

  const renderHeader = (content: any, idx: any) => {
    return (
      <div style={{ paddingBottom: "40px", textAlign: "center" }}>
        {idx >= 3 && (
          <img
            src={dripLogo}
            alt="Drip Auto Care Logo"
            style={{
              width: "170px",
              margin: "0 auto",
              paddingTop: 40,
              paddingBottom: 20,
            }}
          />
        )}
        {idx > 2 && renderProgressBar()}

        <div
          style={{
            ...titleStyles,
            color: idx < 3 ? "#007ae4" : "#000000",
          }}
        >
          {content.title}
        </div>
        {content.lastUpdated && renderLastUpdated(content)}
      </div>
    );
  };

  const SelectField = ({ select, style }: any) => {
    return (
      <div
        style={{
          ...style,
          marginBottom: "1em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <label
          style={{
            ...inputStyles,
            border: "none",
            fontFamily: "Roboto-Medium",
            backgroundColor: "transparent",
          }}
        >
          {select.label}
        </label>
        <select
          style={inputStyles}
          //@ts-ignore
          value={inputValues[select.name] || ""}
          onChange={(e) => handleInputChange(select.name, e.target.value)}
        >
          {select.options.map((option: any, index: any) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };
  const [locationCoordinates, setLocationCoordinates] = useState(null);

  const SideBySideSelects = ({ handleInputChange, inputValues }: any) => {
    const currentYear = new Date().getFullYear();
    const minAdultYear = currentYear - 18; // Users must be at least 18 years old

    return (
      <div
        style={{
          width: "100%",
          maxWidth: 500,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          marginBottom: -15,
        }}
      >
        <label
          style={{
            ...inputStyles,
            border: "none",
            fontFamily: "Roboto-Medium",
            backgroundColor: "transparent",
            marginBottom: -15,
          }}
        >
          Date Of Birth
        </label>
        <div style={sideBySideSelectsContainerStyle}>
          <SelectField
            select={{
              name: "birthMonth",
              label: "",
              options: [
                { value: "", label: "Month" }, // Placeholder option
                ...Array.from({ length: 12 }, (_, i) => ({
                  value: `${i + 1}`,
                  label: new Date(0, i).toLocaleString("default", {
                    month: "long",
                  }),
                })),
              ],
              value: inputValues.birthMonth || "",
              onChange: (e: any) =>
                handleInputChange("birthMonth", e.target.value),
            }}
            style={selectStyle}
          />
          <SelectField
            select={{
              name: "birthDay",
              label: "",
              options: [
                { value: "", label: "Day" }, // Placeholder option
                ...Array.from({ length: 31 }, (_, i) => ({
                  value: `${i + 1}`,
                  label: `${i + 1}`,
                })),
              ],
              value: inputValues.birthDay || "",
              onChange: (e: any) =>
                handleInputChange("birthDay", e.target.value),
            }}
            style={selectStyle}
          />
          <SelectField
            select={{
              name: "birthYear",
              label: "",
              options: [
                { value: "", label: "Year" }, // Placeholder option
                ...Array.from({ length: 100 }, (_, i) => {
                  const year = minAdultYear - i;
                  return { value: `${year}`, label: `${year}` };
                }),
              ],
              value: inputValues.birthYear || "",
              onChange: (e: any) =>
                handleInputChange("birthYear", e.target.value),
            }}
            style={selectStyle}
          />
        </div>
      </div>
    );
  };

  const mapDefaultProps = {
    center: {
      lat: 33.7175, // Latitude of Orange County, California
      lng: -117.8311, // Longitude of Orange County, California
    },
    zoom: 10, // Adjust the zoom level as desired
  };

  // Define the renderMarkersAndPolygons function (you can customize this)
  const renderMarkersAndPolygons = (map: any, maps: any, geoJsonData: any) => {
    // Your logic to render markers and polygons goes here
  };

  const RenderVerified = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img
          src={dripLogo}
          alt="Drip Auto Care Logo"
          style={{
            width: "170px",
            margin: "0 auto",
            paddingTop: 40,
            paddingBottom: 70,
          }}
        />
        <img
          src={check}
          alt="Drip Auto Care Logo"
          style={{
            width: "120px",
            margin: "0 auto",
            //paddingTop: 10,
            paddingBottom: 20,
          }}
        />
        <div
          style={{
            fontSize: 30,
            fontFamily: "Roboto-Bold",
            textAlign: "center", // This ensures the text is centered inside the div
            width: "100%", // Ensure the div takes the full width
            display: "flex", // Using flex to center content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically (in case the div has a specific height)
          }}
        >
          Your verification has been approved!
        </div>
        <a
          href="https://testflight.apple.com/join/GiAOyK3Y"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "flex" }}
        >
          <img
            src={appstorepng}
            alt="Drip Auto Care Logo"
            style={{
              width: "70%",
              margin: "0 auto",
              paddingTop: 40,
              paddingBottom: 70,
            }}
          />
        </a>
      </div>
    );
  };

  const RenderSubmitted = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img
          src={dripLogo}
          alt="Drip Auto Care Logo"
          style={{
            width: "170px",
            margin: "0 auto",
            paddingTop: 40,
            paddingBottom: 70,
          }}
        />
        <img
          src={hourGlass}
          alt="Drip Auto Care Logo"
          style={{
            width: "170px",
            margin: "0 auto",
            paddingTop: 40,
            paddingBottom: 70,
          }}
        />
        <div
          style={{
            fontSize: 34,
            fontFamily: "Roboto-Bold",
            textAlign: "center", // This ensures the text is centered inside the div
            width: "100%", // Ensure the div takes the full width
            display: "flex", // Using flex to center content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically (in case the div has a specific height)
          }}
        >
          Your verification request is in Progress
        </div>
      </div>
    );
  };

  //console.log(isVerified, isSubmitted);

  const renderPageContent = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={dripLogo}
            alt="Drip Auto Care Logo"
            style={{
              width: "170px",
              margin: "0 auto",
              paddingTop: 40,
              paddingBottom: 200,
            }}
          />
          <div className="spinner"></div>
        </div>
      );
    }

    if (isVerified) {
      return <RenderVerified />;
    }

    if (isSubmitted) {
      return <RenderSubmitted />;
    }

    switch (pages[currentPage].title) {
      case "Upload Profile Picture":
        return renderUploadProfilePicture();
      case "Requirements":
        return renderRequirements();
      case "Confirm Location":
        return renderConfirmLocation();
      case "Working Time":
        return renderWorkingTime();
      case "Add Services":
        return renderAddServices();
      case "Owner's Background Check":
        return renderOwnersBackgroundCheck();
      default:
        return renderDefaultPageContent();
    }
  };

  const renderUploadProfilePicture = () => (
    <UploadProfilePicture
      profilePicture={profileImage}
      setProfilePicture={setProfileImage}
      nextPage={nextPage}
    />
  );

  const renderRequirements = () => (
    <div style={{ maxWidth: 700, margin: "auto" }}>
      <MyComponent
        setW9File={setW9File}
        setInsuranceFile={setInsuranceFile}
        isW9FileUploaded={isW9FileUploaded}
        isInsuranceFileUploaded={isInsuranceFileUploaded}
        setIsW9FileUploaded={setIsW9FileUploaded}
        setIsInsuranceFileUploaded={setIsInsuranceFileUploaded}
      />
    </div>
  );

  const renderConfirmLocation = () => (
    <div
      style={{
        position: "relative",
        maxWidth: 700,
        height: "30vh",
        margin: "auto",
      }}
    >
      {/* Map component with higher z-index */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 2,
          borderRadius: 20,
          overflow: "hidden",
        }}
      >
        <MapComponent
          renderMarkersAndPolygons={renderMarkersAndPolygons}
          defaultProps={{
            ...mapDefaultProps,
            center: locationCoordinates || mapDefaultProps.center,
          }}
          height="30vh" // Adjusted to match the parent's height
          setPinCoordinates={(coordinates) => {
            //@ts-ignore
            setLocationCoordinates(coordinates);
          }}
        />
      </div>
      {/* Loading spinner with lower z-index */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          position: "absolute",
          width: "100%",
          zIndex: 1,
        }}
      >
        <div className="spinner" />
      </div>
    </div>
  );

  const renderWorkingTime = () => (
    <div>
      {Object.keys(selectedDays).map((day) => (
        <DayToggle
          key={day}
          day={day as Day}
          isEnabled={selectedDays[day as Day]}
          onToggle={() => handleDayToggle(day as Day)}
          workingHours={
            workingHours[day as Day] || { startTime: "", endTime: "" }
          }
          handleTimeChange={(timeType, value) =>
            handleTimeChange(day as Day, timeType, value)
          }
        />
      ))}
    </div>
  );

  const renderAddServices = () => (
    <AddServicesPage
      selectedServices={selectedServices}
      setSelectedServices={setSelectedServices}
    />
  );

  const renderOwnersBackgroundCheck = () => (
    <div>
      {/*
      <SideBySideSelects
        handleInputChange={handleInputChange}
        inputValues={inputValues}
      />
      */}
      {/* Here we use the updated approach for inputs */}
      <div
        style={{
          marginBottom: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <label
          style={{
            ...inputStyles,
            border: "none",
            fontFamily: "Roboto-Medium",
            backgroundColor: "transparent",
          }}
        >
          Social Security Number
        </label>
        <input
          type="text"
          placeholder="Enter SSN"
          value={inputValues["socialSecurityNumber"] || ""}
          onChange={(e) =>
            handleInputChange("socialSecurityNumber", e.target.value)
          }
          style={inputStyles}
        />
        <label
          style={{
            ...inputStyles,
            border: "none",
            fontFamily: "Roboto-Medium",
            marginTop: "20px",
            backgroundColor: "transparent",
          }}
        >
          Home Address
        </label>
        <input
          type="text"
          placeholder="Enter Home Address"
          value={inputValues["homeAddress"] || ""}
          onChange={(e) => handleInputChange("homeAddress", e.target.value)}
          style={inputStyles}
        />
      </div>
    </div>
  );

  const renderDefaultPageContent = () => (
    <div>
      {/* Directly using input elements instead of InputField */}
      {pages[currentPage].inputs?.map((input) => (
        <div
          key={input.name}
          style={{
            marginBottom: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            style={{
              ...inputStyles,
              border: "none",
              fontFamily: "Roboto-Medium",
              backgroundColor: "transparent",
            }}
          >
            {input.label}
          </label>
          <input
            type={input.type || "text"}
            name={input.name}
            placeholder={input.placeholder}
            value={inputValues[input.name] || ""}
            onChange={(e) => handleInputChange(input.name, e.target.value)}
            style={inputStyles}
          />
        </div>
      ))}

      {/* Rendering select elements */}
      {pages[currentPage].selects?.map((select, index) => (
        <div
          key={index}
          style={{
            marginBottom: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            style={{
              ...inputStyles,
              border: "none",
              fontFamily: "Roboto-Medium",
              backgroundColor: "transparent",
            }}
          >
            {select.label}
          </label>
          <select
            style={inputStyles}
            value={inputValues[select.name] || ""}
            onChange={(e) => handleInputChange(select.name, e.target.value)}
          >
            {select.options.map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      ))}

      {pages[currentPage].sections?.map((section, index) => (
        <div key={index} style={bodyStyles}>
          <div style={SectionHeaderStyles}>{section.header}</div>
          <p>{section.text}</p>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <TopBar />
      <div style={containerStyles}>
        <div style={cardStyles}>
          <div style={backArrowStyles} onClick={prevPage}>
            <IoIosArrowBack />
          </div>
          <div style={closeButtonStyles} onClick={() => console.log("Close")}>
            ×
          </div>
          <div style={contentContainerStyles}>
            {/*move this header and button to renderpagecontent itll workout perfect*/}
            {!loading &&
              !(isVerified || isSubmitted) &&
              renderHeader(content, currentPage)}

            {renderPageContent()}

            {!loading && !isVerified && !isSubmitted && currentPage !== 6 && (
              <button
                onClick={nextPage}
                style={{
                  ...buttonStyles,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-small" style={{ margin: "0 auto" }} />
                ) : (
                  content.buttonText
                )}
              </button>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ProviderRegistration;

const UploadProfilePicture = ({
  profilePicture,
  setProfilePicture,
  nextPage,
}: any) => {
  const containerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    //padding: "20px",
    paddingLeft: 40,
    paddingRight: 40,
  };

  const circleStyles: React.CSSProperties = {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    background: "linear-gradient(to bottom right, #bff0fe, #cbfde0)",
    display: "flex",
    position: "relative", // Added for relative positioning

    justifyContent: "center",
    alignItems: "center",
    overflow: "visible",
  };

  const iconContainerStyles: React.CSSProperties = {
    position: "absolute",
    bottom: 0,
    right: 0,
    //transform: 'translate(50%, 50%)', // Center the icon at the bottom right corner
  };

  const smallIconCircleStyles: React.CSSProperties = {
    width: "40px", // Set the width of the small circle
    height: "40px", // Set the height of the small circle
    borderRadius: "50%", // Make it round
    background: "#007bff", // Match the gradient start or end color, or choose a new one
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const bodyStyles: React.CSSProperties = {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "grey",
    paddingBottom: "20px",
    backgroundColor: "white", // Assuming a white background for the content area
    textAlign: "center", // Aligns the text to the left
    //borderTop: '1px solid #ccc', // Adds a separator line above the content
    //borderBottom: '1px solid #ccc', // Adds a separator line below the content
  };

  const buttonStyles = {
    fontFamily: "Roboto-Bold",

    padding: "22px 24px",
    backgroundColor: "#007bff", // You can adjust this color to match the design
    color: "white",
    border: "none",
    borderRadius: "100px",
    cursor: "pointer",
    fontSize: "20px",
    display: "block", // Make button a block to fit width
    width: "100%", // Button width full container width
    maxWidth: "500px",
    margin: "0px auto", // Auto margin on the left and right will center the button
    marginTop: 40,
  };

  const input: React.CSSProperties = {
    //margin: "25px 0",
    boxSizing: "border-box", // Include padding and border in the element's width
    width: "90%", // Full width minus the left and right padding
    padding: "10px",
    border: "1px solid black",
    borderRadius: "0px",
    fontFamily: "PoppinsXlight",
    fontSize: "14px",
    marginBottom: 15,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block", // Ensure the input is a block-level element
  };

  const handleSkip = () => {
    const confirmSkip = window.confirm(
      "Are you sure you want to skip uploading a profile picture?"
    );
    if (confirmSkip) {
      const image = new Image();
      // Assuming `defaultProfilePic` is the path to your default image
      image.src = defaultProfilePic;
      image.onload = () => {
        // Get the loaded image's width and height
        const width = image.width;
        const height = image.height;

        // Calculate the scaling factor to resize the image to 500px max
        const maxDimension = 500;
        const scalingFactor = Math.min(
          maxDimension / width,
          maxDimension / height
        );

        // Calculate the new image dimensions
        const newWidth = scalingFactor * width;
        const newHeight = scalingFactor * height;

        // Create a canvas with the new dimensions
        const canvas = document.createElement("canvas");
        canvas.width = newWidth; // Set canvas width to newWidth
        canvas.height = newHeight; // Set canvas height to newHeight

        // Draw the image onto the canvas at the new dimensions
        const ctx = canvas.getContext("2d");
        //@ts-ignore
        ctx.drawImage(image, 0, 0, newWidth, newHeight);

        // Convert the canvas to a base64 string
        const base64 = canvas.toDataURL("image/png");

        // Set the base64 string to profilePicture state
        setProfilePicture(base64);
      };
      image.onerror = (error) => {
        console.error("Error loading default image:", error);
        setProfilePicture(null);
      };

      nextPage();
    }
  };

  const handleContinue = () => {
    nextPage();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      // Create an image object
      const image = new Image();
      // Create a URL for the file
      const src = URL.createObjectURL(file);
      image.onload = () => {
        // Get the loaded image's width and height
        const width = image.width;
        const height = image.height;

        // Calculate the scaling factor to resize the image to 500px max
        const maxDimension = 500;
        const scalingFactor = Math.min(
          maxDimension / width,
          maxDimension / height
        );

        // Calculate the new image dimensions
        const newWidth = scalingFactor * width;
        const newHeight = scalingFactor * height;

        // Create a canvas with the new dimensions
        const canvas = document.createElement("canvas");
        canvas.width = 500; // Set canvas width to 500 pixels
        canvas.height = 500; // Set canvas height to 500 pixels

        // Draw the image onto the canvas at the desired dimensions, this may distort the image
        const ctx = canvas.getContext("2d");
        //@ts-ignore
        ctx.drawImage(image, 0, 0, 500, 500);

        // Convert the canvas to a base64 string
        const base64 = canvas.toDataURL("image/png");

        // Set the base64 string to profilePicture state
        setProfilePicture(base64);

        // Clean up the URL object
        URL.revokeObjectURL(src);
      };
      image.onerror = (error) => {
        console.error("Error loading image:", error);
        setProfilePicture(null);
      };

      // Set the image's src to start loading process
      image.src = src;
    }
  };

  // Function to open the file dialog
  const handleContainerClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none"; // Hide the file input element
    //@ts-ignore
    fileInput.onchange = handleFileChange; // Set the event handler for the file input

    // Append to the body to ensure events are triggered properly
    document.body.appendChild(fileInput);

    // Click the file input
    fileInput.click();

    // Remove the file input from the DOM after the file dialog is closed
    fileInput.addEventListener("change", () => {
      document.body.removeChild(fileInput);
    });
  };

  return (
    <div style={containerStyles}>
      <div style={bodyStyles}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt
      </div>
      <div style={circleStyles} onClick={handleContainerClick}>
        {profilePicture ? (
          <>
            <div>
              <img
                src={formatBase64Image(profilePicture)}
                alt="Profile"
                style={{
                  width: "150px", // Match the circleStyles width
                  border: "3px solid #007bff",
                  height: "150px", // Match the circleStyles height
                  borderRadius: "50%",
                  objectFit: "cover", // This will cover the area without stretching the image
                }}
              />
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Stop the click event from bubbling up to the image container
                setProfilePicture(null);
              }}
              style={{
                position: "absolute",
                top: "5px", // Adjusted for positioning inside the circle
                right: "5px", // Adjusted for positioning inside the circle
                background: "red",
                color: "white",
                border: "none",
                borderRadius: "50%",
                cursor: "pointer",
                width: "25px",
                height: "25px",
                lineHeight: "25px",
                textAlign: "center",
                padding: 0,
              }}
            >
              X
            </button>
          </>
        ) : (
          <>
            <IoPerson size={80} color="#65bae3" />
            <div style={iconContainerStyles}>
              <div style={smallIconCircleStyles}>
                <IoCamera size={25} color="white" />
              </div>
            </div>
          </>
        )}
      </div>
      {profilePicture ? (
        <button onClick={handleContinue} style={buttonStyles}>
          Continue
        </button>
      ) : (
        <button onClick={handleSkip} style={buttonStyles}>
          Skip
        </button>
      )}
    </div>
  );
};

const UploadRequirements = ({
  label,
  onFileChange,
  isLoaded,
  onDelete,
  storagePath,
}: any) => {
  const fileInputRef = React.useRef(null);
  const [isFileUploaded, setIsFileUploaded] = React.useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const handleChange = async (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (file.type !== "application/pdf") {
        alert("Please upload a PDF file.");
        return;
      }
      const maxSizeInBytes = 25 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        alert("File size exceeds the limit of 25MB.");
        return;
      }

      const storage = getStorage();
      const storageReference = storageRef(
        storage,
        `${storagePath}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageReference, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          alert("Upload failed: " + error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setIsFileUploaded(true);
            onFileChange(downloadURL);
          });
        }
      );
    } else {
      setIsFileUploaded(false);
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleButtonClick = () => {
    if (isLoaded) {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this document?"
      );
      if (isConfirmed) {
        onDelete(); // Call the onDelete function passed from the parent component
        setUploadProgress(0); // Reset progress on delete
      }
    } else {
      //@ts-ignore
      fileInputRef.current.click();
    }
  };

  const dynamicIconStyle = {
    ...styles.icon,
    color: isLoaded ? "#28a745" : "#007bff",
  };

  const dynamicButtonStyle = {
    ...styles.button,
    backgroundColor: isLoaded ? "#28a745" : "#007bff",
  };

  return (
    <div style={styles.container}>
      <div style={dynamicIconStyle}>
        {isLoaded ? (
          <IoCloudDone size={34} color={dynamicIconStyle.color} />
        ) : (
          <IoCloudUpload size={34} color={dynamicIconStyle.color} />
        )}
      </div>
      <span style={styles.label}>{label}</span>
      <input
        ref={fileInputRef}
        type="file"
        accept="application/pdf" // Limit file type to PDF
        style={styles.input}
        onChange={handleChange}
        hidden
      />
      {uploadProgress > 0 && !isLoaded ? (
        <progress value={uploadProgress} max="100">
          {" "}
          {uploadProgress}%{" "}
        </progress>
      ) : (
        <button style={dynamicButtonStyle} onClick={handleButtonClick}>
          <div style={styles.uploadText}>
            {isLoaded ? "Uploaded" : "Upload"}
          </div>
        </button>
      )}
    </div>
  );
};

// Styles for your component
const styles = {
  uploadText: {
    fontSize: "16px", // Adjust as needed
    fontWeight: "900", // Adjust as needed
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "14px",
    backgroundColor: "#fff", // Ensure background color is white for visibility
  },
  icon: {
    marginLeft: "8px",
    marginRight: "8px",
    display: "flex", // Ensure icon aligns properly
  },
  label: {
    flexGrow: 1,
    margin: "0 8px", // Add some space around the label for better legibility
    color: "#000", // Label text color for visibility
    fontWeight: "600", // Adjust as needed
  },
  input: {
    display: "none", // Hide the actual file input
  },
  button: {
    backgroundColor: "#007bff",
    color: "white",
    padding: "12px 22px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
};

const MyComponent = ({
  setW9File,
  setInsuranceFile,
  isW9FileUploaded,
  isInsuranceFileUploaded,
  setIsW9FileUploaded,
  setIsInsuranceFileUploaded,
}: any) => {
  const handleFileChangeW9 = (downloadURL: string) => {
    setW9File(downloadURL); // Store the base64-encoded W9 file
    setIsW9FileUploaded(true);
  };

  const handleFileChangeInsurance = (downloadURL: string) => {
    setInsuranceFile(downloadURL); // Store the base64-encoded Insurance file
    setIsInsuranceFileUploaded(true);
  };

  const deleteW9File = () => {
    setW9File(null);
    setIsW9FileUploaded(false);
  };

  const deleteInsuranceFile = () => {
    setInsuranceFile(null);
    setIsInsuranceFileUploaded(false);
  };

  return (
    <div>
      <UploadRequirements
        isLoaded={isW9FileUploaded}
        label="Business Paperwork: W9"
        onFileChange={handleFileChangeW9}
        onDelete={deleteW9File}
        storagePath="drip-8d694.appspot.com/W9_files"
      />
      <UploadRequirements
        isLoaded={isInsuranceFileUploaded}
        label="Insurance: Liability insurance where applicable"
        onFileChange={handleFileChangeInsurance}
        onDelete={deleteInsuranceFile}
        storagePath="drip-8d694.appspot.com/Insurance_files"
      />
    </div>
  );
};

interface WorkingHours {
  [day: string]: { startTime: string; endTime: string };
}

interface DayToggles {
  [key: string]: boolean; // Adding index signature
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
}

type Day =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

type DayToggleProps = {
  day: Day;
  isEnabled: boolean;
  onToggle: () => void;
};

const DayToggle = ({
  day,
  isEnabled,
  onToggle,
  workingHours,
  handleTimeChange,
}: DayToggleProps & {
  workingHours: { startTime: string; endTime: string };
  handleTimeChange: (timeType: "startTime" | "endTime", value: string) => void;
}) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  };

  const borderContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ececec",
    borderRadius: 17,
    backgroundColor: "#f6f5f5",
    width: "70%",
    padding: 15,
  };

  const toggleContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const dayTextStyle: React.CSSProperties = {
    fontFamily: "Roboto-Medium",
    fontSize: 18,
    marginRight: 10,
  };

  const toggleInputStyle: React.CSSProperties = {
    display: "none",
  };

  const sliderStyle: React.CSSProperties = {
    position: "relative",
    cursor: "pointer",
    width: "40px",
    height: "20px",
    borderRadius: "20px",
    backgroundColor: isEnabled ? "#007ae4" : "#ebebeb",
  };

  const sliderRoundStyle: React.CSSProperties = {
    position: "absolute",
    top: "0",
    left: isEnabled ? "20px" : "0",
    right: isEnabled ? "0" : "20px",
    bottom: "0",
    transition: "0.4s",
    borderRadius: "50%",
    backgroundColor: isEnabled ? "#B0CDE6" : "#76b3e7",
  };

  return (
    <div style={containerStyle}>
      <div style={borderContainerStyle}>
        <div style={toggleContainerStyle}>
          <span style={dayTextStyle}>{day}</span>
          <label style={sliderStyle}>
            <input
              type="checkbox"
              style={toggleInputStyle}
              checked={isEnabled}
              onChange={onToggle}
            />
            <span style={sliderRoundStyle}></span>
          </label>
        </div>
        {isEnabled && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 30,
              marginRight: 10,
            }}
          >
            {["startTime", "endTime"].map((timeType) => (
              <div
                key={timeType}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "45%",
                }}
              >
                <input
                  type="time"
                  value={
                    workingHours[timeType as "startTime" | "endTime"] || ""
                  }
                  onChange={(e) =>
                    handleTimeChange(
                      timeType as "startTime" | "endTime",
                      e.target.value
                    )
                  }
                  style={{
                    //padding: 5,
                    fontSize: 20,
                    padding: 5,
                    borderRadius: 5,
                    border: "1px solid #ccc",
                    marginTop: 5,
                    cursor: "pointer",
                    width: "100%",
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
