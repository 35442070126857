import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/driplogo.png";

interface HeaderProps {
  isMobile: boolean;
}

const HomeHeader: React.FC<HeaderProps> = ({ isMobile }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };


  const handleServicesClick = () => {
    navigate("/services");
  };

  const handleSignupClick = () => {
    navigate("/register");
  };

  const navbarStyle: React.CSSProperties = {
    backgroundColor: "transparent",
    marginRight: isMobile ? "20px" : "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: isMobile ? "center" : "space-between",
  };

  const navMenuStyle: React.CSSProperties = {
    paddingTop: "10px",
    paddingBottom: "10px",
    listStyle: "none",
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: isMobile ? "wrap" : "nowrap", 
    paddingLeft: "0",
    gap: isMobile ? "10px" : "20px",
    overflowX: "hidden",
  };

  const linkStyle: React.CSSProperties = {
    fontWeight: "600",
    fontFamily: "NeuePlakExtendedRegular",
    textDecoration: "none",
    color: "inherit",
    fontSize: isMobile ? "13px" : "22px",
    display: "flex",
    alignItems: "center",
    marginRight: isMobile ? "10px" : "30px",
    transition: "color 0.3s",
  };

  const buttonStyle: React.CSSProperties = {
    backgroundColor: "black",
    fontFamily: "NeuePlakExtendedRegular",
    color: "white",
    fontSize: isMobile ? "10px" : "16px",
    padding: isMobile ? "0px 10px" : "10px 20px", 
    borderRadius: isMobile ? "10px" : "20px",
    textDecoration: "none",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
  };

  const navItemStyle: React.CSSProperties = {
    color: "rgb(0, 0, 0)",
    cursor: "pointer",
    fontSize: "26px", 
    lineHeight: "40px", 
    display: "flex",
    alignItems: "center",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%", 
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "20px", 
        }}
      >
        <img
          src={logo}
          style={{
            width: isMobile ? "80px" : "100px", 
            height: "auto",
          }}
        />
      </div>

      <nav style={navbarStyle}>
        <ul style={navMenuStyle}>
          <li style={navItemStyle}>
          <li style={linkStyle} onClick={handleHomeClick}>HOME</li>

            <a
              onClick={handleServicesClick}
              style={linkStyle}
              onMouseOver={(e) => (e.currentTarget.style.color = "#333")}
              onMouseOut={(e) => (e.currentTarget.style.color = "black")}
            >
              SERVICES
            </a>
            <a
              onClick={handleSignupClick}
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#333")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "black")
              }
            >
              GET STARTED
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HomeHeader;
