import React, { CSSProperties, useState } from "react";

interface DispatchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DispatchModal: React.FC<DispatchModalProps> = ({ isOpen, onClose }) => {
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [reason, setReason] = useState('');

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };
  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };
  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <div style={modalStyles.barContainer}>
        <label htmlFor="statusSelect">Status:</label>
        <select
          id="statusSelect"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          <option value="Pending">Pending</option>
          <option value="Assigned">Assigned</option>
          <option value="Scheduled">Scheduled</option>
          <option value="Canceled">Canceled</option>
          <option value="Complete">Complete</option>
        </select>

        <label htmlFor="reasonInput">Reason:</label>
        <input
          type="text"
          id="reasonInput"
          value={reason}
          onChange={handleReasonChange}
          style={modalStyles.inputStyles}
        />

        </div>

        <button style={modalStyles.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const modalStyles = {
  inputStyles: {
    borderRadius: '10px', // Rounded edges
    border: '1px solid black', // Black border
    padding: '20px', // Padding for input
    marginTop: '10px', // Margin below the input
    marginBottom: '10px', // Margin below the input
    //width: '100%', // Full width
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "30%", // Circular shape
    objectFit: "cover",
    paddingLeft: 20,
    paddingRight: 20,
  } as CSSProperties,
  profileIcon: {
    width: "100px", // Set both width and height to the same value for a square shape
    height: "100px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "20%", // Circular shape
    marginBottom: "10px",
    objectFit: "cover",
  } as CSSProperties,
  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  leftSide: {
    width: "50%",
    marginRight: 30,
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,

    border: "1px solid #000000",
  } as CSSProperties,
  rightSide: {
    width: "50%",
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    border: "1px solid #000000",
  } as CSSProperties,
  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
    // Add any additional styling you need for each detail row
  } as CSSProperties,

  barContainer: {
    //width: '60%',
    display: "flex",
    flexDirection:'column',
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: 40,
    border: "1px solid #000000",
    borderRadius:10,
    //backgroundColor:'pink'


  } as CSSProperties,
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "33.3333%", // Divide the bar container into three equal parts
  } as CSSProperties,

  closeButton: {
    fontSize: "15px",
    color: "white", // Text color
    fontWeight: "500",
    backgroundColor: "black", // Background color
    borderRadius: "10px", // Make it round
    padding: "10px 10px", // Padding for sizing (adjust as needed)
    border: "none", // Remove default button border
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
    //marginRight: 40,
  } as CSSProperties,

  background: {
    position: "fixed" as const, // Use 'as const' to narrow down the string literal type
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties
  container: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    //maxWidth: '500px',
    //width: "80%",
    maxHeight: "85vh",
    overflowY: "auto" as const, // Use 'as const' for string literals
    zIndex: 1001,
  } as CSSProperties,
  contentStyles: {
    // Define your styles here
    marginBottom: "10px",
  } as CSSProperties,
};

export default DispatchModal;
