import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

type UserRole = 'provider' | 'management' | 'customer' | 'out' | null;

interface AuthContextType {
  currentUser: any; // Consider using a more specific type here
  userRole: UserRole;
  isProviderVerified: boolean;
  setUserRole: (role: UserRole) => void;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userRole: null,
  isProviderVerified: false,
  setUserRole: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState<UserRole>(null);
  const [isProviderVerified, setIsProviderVerified] = useState(false);

  const [loading, setLoading] = useState(true);
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const storedUserRole = localStorage.getItem('userRole');
    if (storedUserRole) {
      //setUserRole(storedUserRole as UserRole);
    }

    const unsubscribe = onAuthStateChanged(auth, async (user: any) => {
      setCurrentUser(user);
      setLoading(true);

      if (user) {
        try {
          // Check for 'Providers'
          const providerDocRef = doc(db, 'Providers', user.uid);
          const providerDocSnap = await getDoc(providerDocRef);

          // Check for 'Management'
          const managementDocRef = doc(db, 'Management', user.uid);
          const managementDocSnap = await getDoc(managementDocRef);

          if (providerDocSnap.exists()) {
            setUserRole('provider');
            localStorage.setItem('userRole', 'provider');

            const providerData = providerDocSnap.data();
            setIsProviderVerified(providerData.verified || false);

          } else if (managementDocSnap.exists()) {
            setUserRole('management');
            localStorage.setItem('userRole', 'management');
          } else {
            setUserRole('customer');
            localStorage.setItem('userRole', 'customer');
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      } else {
        setUserRole('out'); // explicitly set to 'out' when no user is logged in
        localStorage.removeItem('userRole');
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, [db, auth]);

  const value = { currentUser, userRole, isProviderVerified, loading, setUserRole };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
