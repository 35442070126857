// NoRecords.tsx

import React from 'react';

const NoRecords: React.FC<{ message?: string }> = ({
  message = "No Records Found",
}) => {
  return (
    <div
      style={{
        padding: "50px",
        marginLeft: '10%',
        marginRight: '10%',
        borderRadius: 15,
        marginTop: 50,
        textAlign: "center",
        fontFamily: "Roboto-Light",
        backgroundColor: '#f5f5f5',
        fontSize: 35,
      }}
    >
      {message}
    </div>
  );
};

export default NoRecords;
