import React, { CSSProperties, useState } from "react";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
import { GrUserWorker } from "react-icons/gr";
import {
  IoNotificationsOutline,
  IoPerson,
  IoSearchOutline,
} from "react-icons/io5";
import { MdEmail, MdPhone } from "react-icons/md";
import AddEmployeeModal from "./AddEmployeeModal";

const employees = [
  {
    name: "Catherine Foster",
    status: "Checked In",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: true,
  },
  {
    name: "Christy Ross",
    status: "Checked Out",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: false,
  },
  {
    name: "Leo Smith",
    status: "Not Checked-In",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: false,
  },
  {
    name: "Tyler King",
    status: "Checked In",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: true,
  },
  {
    name: "Lester Berlin",
    status: "Checked In",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: true,
  },
  {
    name: "Charles Pollard",
    status: "Checked In",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: true,
  },
  {
    name: "Miary Vazquez",
    status: "Not Checked-In",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: false,
  },
  {
    name: "Linda Newell",
    status: "Not Checked-In",
    phone: "+1 781-477-6278",
    email: "catherinefoster@gmail.com",
    checkedIn: false,
  },
];

const EmployeeList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

const openModal = () => setIsModalOpen(true);
const closeModal = () => setIsModalOpen(false);


  return (
    <div style={styles.employeeList}>
      <header style={styles.header}>
        <h1 style={styles.headerTitle}>Employees</h1>
        <div style={styles.searchBarContainer}>
          <input
            type="text"
            placeholder="Search Employees"
            style={styles.searchInput}
          />
          <button style={styles.searchButton}>
            <IoSearchOutline />
          </button>
        </div>
        <button style={styles.filterButton}>
          <FiFilter />
        </button>
        <button style={styles.addButton} onClick={openModal}>Add Employee</button>
        <button style={styles.notificationButton}>
          <IoNotificationsOutline />
        </button>
        <button style={styles.companyLogoButton}>
          <IoPerson />
        </button>
      </header>

      <div style={styles.employeeGrid}>
        {employees.map((employee, index) => (
          <div key={index} style={styles.employeeCard}>
            <div style={styles.employeeCardHeader}>
              <img
                style={styles.employeePhoto}
                src={`https://i.pravatar.cc/150?img=${index + 1}`} 
                alt={employee.name}
              />
              <div style={styles.employeeNameStatus}>
                <h2 style={styles.employeeName}>{employee.name}</h2>
                <p style={styles.employeeStatus}>
                  <span
                    style={
                      employee.checkedIn
                        ? styles.checkedInContainer
                        : styles.notCheckedInContainer
                    }
                  >
                    {employee.checkedIn ? "Checked In" : "Not Checked-In"}
                    {employee.checkedIn ? (
                      <BsCheckCircleFill style={styles.checkedInIcon} />
                    ) : (
                      <BsXCircleFill style={styles.notCheckedInIcon} />
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div style={styles.employeeDetails}>
              <div style={styles.detailItem}>
                <GrUserWorker style={styles.detailIcon} />
                <p>Driver</p>
              </div>
              <div style={styles.detailItem}>
                <MdPhone style={styles.detailIcon} />
                <p>{employee.phone}</p>
              </div>
              <div style={styles.detailItem}>
                <MdEmail style={styles.detailIcon} />
                <p>{employee.email}</p>
              </div>
            </div>
            <div style={styles.viewDetailsButtonContainer}>
              <button style={styles.detailsButton}>View Details</button>
            </div>
          </div>
        ))}
      </div>
      <AddEmployeeModal isOpen={isModalOpen} onRequestClose={closeModal} />

    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  checkedInContainer: {
    display: "inline-flex", // Use inline-flex to size based on content
    alignItems: "center",
    backgroundColor: "#e6ffee",
    color: "green",
    borderRadius: "15px",
    padding: "5px 10px",
    fontSize: "14px",
    gap: "5px", // Add gap between text and icon
    border: "1.5px solid #27ae60",
    fontFamily:'Roboto-Medium'
  },
  notCheckedInContainer: {
    display: "inline-flex", // Use inline-flex to size based on content
    alignItems: "center",
    backgroundColor: "#ffeeee",
    color: "red",
    borderRadius: "15px",
    padding: "5px 10px",
    fontSize: "14px",
    gap: "5px", // Add gap between text and icon
    border: "1.5px solid #f60606",
    fontFamily:'Roboto-Medium'

  },

  checkedInIcon: {
    marginRight: "5px",
  },
  notCheckedInIcon: {
    marginRight: "5px",
  },

  employeePhoto: {
    borderRadius: "50%",
    width: "80px", // Increase the width
    height: "80px", // Increase the height
  },

  // Add a margin to the employee status
  employeeNameStatus: {
    marginLeft: "10px",
    textAlign: "left",
  },
  employeeStatus: {
    margin: "10px 0 0 0", // Add margin to the top to create a gap
    fontSize: "14px",
  },

  employeeDetails: {
    textAlign: "left",
    paddingLeft: "5px",
    paddingRight: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "80px", // Adjust height as needed
    marginTop: "25px",
    marginBottom: "25px",
  },
  detailItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2px", // Remove negative margin
    flexWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  employeeCard: {
    border: "1px solid #7fe0fe",
    borderRadius: "8px",
    padding: "20px 15px", // Adjust padding
    position: "relative",
    textAlign: "center",
    overflow: "hidden",
    boxSizing: "border-box",
  },

  detailIcon: {
    marginRight: "10px",
    color: "#007bff",
    flexShrink: 0,
  },

  employeeCardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },

  employeeName: {
    margin: 0,
    fontSize: "18px",
    fontWeight: "bold",
  },

  viewDetailsButtonContainer: {
    textAlign: "center",
  },

  detailsButton: {
    maxWidth: 250,
    backgroundColor: "white",
    color: "#007bff",
    fontFamily: "Roboto-Bold",
    border: "2px solid #007bff",
    borderRadius: "25px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "20px",
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    boxSizing: "border-box",
  },

  employeeList: {
    padding: "20px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  headerTitle: {
    margin: 0,
    fontSize: "28px",
    fontWeight: "bold",
  },
  searchBarContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    marginLeft: "20px",
    marginRight: "20px",
  },
  searchInput: {
    flex: 1,
    padding: "10px",
    maxWidth: "500px",
    borderRadius: "15px",
    border: "1px solid #007bff",
    outline: "none",
    marginRight: "10px",
    fontSize: "16px",
  },
  searchButton: {
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px", // Ensures button is round
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "16px",
  },
  filterButton: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px", // Ensures button is round
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
  },
  addButton: {
    backgroundColor: "#007bff",
    fontFamily: "Roboto-Bold",
    color: "white",
    border: "none",
    borderRadius: "25px",
    padding: "10px 20px",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
    whiteSpace: "nowrap",  // Prevent text wrapping

  },
  notificationButton: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px", // Ensures button is round
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
  },
  companyLogoButton: {
    backgroundColor: "#f1f1f1",
    color: "#333",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px", // Ensures button is round
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "16px",
  },
  employeeGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Increase minmax value
    gap: "20px",
    marginTop: "20px",
  },
};

export default EmployeeList;
