// providerProfileReducer.ts
import { AnyAction } from 'redux';
import { SET_PROVIDER_PROFILE_DETAILS, SET_LOADING_PROVIDER_PROFILE } from '../actions/types';

interface ProviderProfileState {
  details: any | null;
  loading: boolean;
}

const initialState: ProviderProfileState = {
  details: null,
  loading: false,
};

const providerProfileReducer = (state = initialState, action: AnyAction): ProviderProfileState => {
  switch (action.type) {
    case SET_LOADING_PROVIDER_PROFILE:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_PROVIDER_PROFILE_DETAILS:
      return {
        ...state,
        details: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};


export default providerProfileReducer;
