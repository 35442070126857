// OrdersFilter.tsx
import React, { useState } from "react";
import { activeColor, navy } from '../../styles'; // Adjust the path as necessary

interface OrdersFilterProps {
  onFilterChange: (filters: {
    area: string;
    orderId: string;
    status: string;
  }) => void;
}

const OrdersFilter: React.FC<OrdersFilterProps> = ({ onFilterChange }) => {
  const [areaInput, setAreaInput] = useState("");
  const [orderIdInput, setOrderIdInput] = useState("");
  const [statusInput, setStatusInput] = useState("");

  const handleFilterButtonClick = () => {
    onFilterChange({
      area: areaInput,
      orderId: orderIdInput,
      status: statusInput.toLowerCase(),
    });
  };
  const filterStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingLeft: "5%",
    paddingRight: "5%",
    //backgroundColor: "#f0f2f5", // A light background color
    marginBottom: "20px",
  };

  const inputStyle: React.CSSProperties = {
    paddingLeft:'10px',
    margin: "0 5px", // Adjust as needed
    // Setting fixed width for input fields; adjust as needed
    width: "130px", // Example fixed width, adjust according to your layout
  };

  const dateContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div style={filterStyles}>
      <input
        style={inputStyle}
        placeholder="Order ID"
        value={orderIdInput}
        onChange={(e) => setOrderIdInput(e.target.value)}
      />
      <input style={inputStyle} placeholder="Provider UID" />
      <input style={inputStyle} placeholder="Customer UID" />
      <input
        style={inputStyle}
        placeholder="Area"
        value={areaInput} // Set the input value to the state
        onChange={(e) => setAreaInput(e.target.value)} // Update the state on input change
      />
      <div style={dateContainerStyle}>
        <input type="date" style={inputStyle} placeholder="From Date" />
        <input type="date" style={inputStyle} placeholder="To Date" />
      </div>
      <input
        style={inputStyle}
        placeholder="Status"
        value={statusInput}
        onChange={(e) => setStatusInput(e.target.value)}
      />
      <button
        onClick={handleFilterButtonClick} // Call handleFilterButtonClick when the button is clicked
        style={{
          fontSize: "20px",
          color: "white", // Text color
          fontFamily: "PoppinsLight",
          backgroundColor: 'black', // Background color
          borderRadius: "5px", // Make it round
          padding: "5px 50px", // Padding for sizing (adjust as needed)
          border: "none", // Remove default button border
          cursor: "pointer", // Change cursor to pointer on hover
          outline: "none", // Remove focus outline
          //marginRight: 40,
        }}
      >
        Filter
      </button>
    </div>
  );
};

export default OrdersFilter;
